import React, { useState, useEffect } from 'react';
import {
	Input,
	InputGroup,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Row,
	Col,
} from 'reactstrap';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import {
	emailAuth,
	googleAuth,
	fbAuth,
	resetError,
} from '../../store/actions/authActions';
import AlertMsg from '../utils/Alert';
import CustomBtn from '../utils/CustomBtn';
import PrintItinerary from '../utils/PrintItinerary';
import SignUp from './SignUp';
import ShareLink from '../shareLink/ShareLink';

import mail from '../../assets/icons/mail.png';
import key from '../../assets/icons/key.png';
import google from '../../assets/icons/GoogleIcon.png';
import facebook from '../../assets/icons/FacebookIcon.png';

import './login.scss';

const Login = ({
	emailAuth,
	googleAuth,
	fbAuth,
	resetError,
	signInError,
	actions,
	itineraryParams,
	isAuthed,
	close,
}) => {
	const [requestLoading, setLoading] = useState(false);
	const [errorData, setError] = useState('');
	const [isAlertOpen, setAlert] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLogin, setIsLogin] = useState(true);
	const [activeTab, setActiveTab] = useState(!isAuthed ? '1' : '2');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		setError(signInError);
	}, [signInError]);

	useEffect(() => {
		setAlert(!!errorData);
		setLoading(!errorData && !areFieldsEmpty());
	}, [errorData]);

	const areFieldsEmpty = () => {
		return isEmpty(email) && isEmpty(password);
	};

	const handleEmail = (e) => {
		const { value } = e.target;
		setEmail(value);
	};
	const changeComponent = () => {
		setIsLogin(!isLogin);
	};

	const handlePassword = (e) => {
		const { value } = e.target;
		setPassword(value);
	};

	const onKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleEmailAuth(e);
		}
	};

	const dismissAlert = () => {
		resetError();
	};

	const handleEmailAuth = (event) => {
		event.preventDefault();

		if (email.length < 4) {
			setError('Please enter an email address.');
			return;
		}

		if (password.length < 4) {
			setError('Please enter a password.');
			return;
		}

		resetError();
		setLoading(true);
		emailAuth({ email, password });
	};

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<>
			<Nav tabs>
				{!isAuthed && (
					<NavItem>
						<NavLink
							className={activeTab === '1' ? 'active-tab' : null}
							onClick={() => {
								toggle('1');
							}}
						>
							Log In
						</NavLink>
					</NavItem>
				)}
				<NavItem>
					<NavLink
						className={activeTab === '2' ? 'active-tab' : null}
						onClick={() => {
							toggle('2');
						}}
					>
						Public Link
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				{!isAuthed && (
					<TabPane tabId="1">
						<Row>
							<div id="auth-container">
								<AlertMsg
									content={errorData}
									dismiss={dismissAlert}
									isOpen={isAlertOpen}
								/>

								{isLogin ? (
									<>
										<div>
											<div className="auth-title">
												Log In
											</div>
											<div className="social-media-sing-up">
												<CustomBtn
													text="Log In with Google"
													onClick={googleAuth}
													width={452}
													className="button-primary outline squared narrow google left-icon"
													disabled={isDisabled}
													imgIcon={google}
												/>
												<CustomBtn
													text="Log In with Facebook"
													onClick={fbAuth}
													width={452}
													className="button-primary outline squared narrow facebook left-icon"
													disabled={isDisabled}
													imgIcon={facebook}
												/>
											</div>
											<div className="auth-divider">
												<span className="auth-divider-line">
													<hr />
												</span>
												<span className="auth-divider-o">
													Or
												</span>
												<span className="auth-divider-line">
													<hr />
												</span>
											</div>
											<div className="auth-fields">
												<InputGroup>
													<img
														src={mail}
														alt="mail"
														className="text-icon"
													/>
													<Input
														id="email"
														className="bottom-line"
														placeholder="Email"
														onChange={handleEmail}
														onKeyPress={onKeyPress}
														disabled={isDisabled}
													/>
												</InputGroup>
												<InputGroup>
													<img
														src={key}
														alt="key"
														className="text-icon"
													/>
													<Input
														id="password"
														className="bottom-line"
														type="password"
														placeholder="Password"
														onChange={
															handlePassword
														}
														onKeyPress={onKeyPress}
														disabled={isDisabled}
													/>
												</InputGroup>
											</div>
										</div>
										{isDisabled ? (
											<>
												<div className="auth-divider">
													<span className="auth-divider-line">
														<hr />
													</span>
													<span className="auth-divider-o">
														Or
													</span>
													<span className="auth-divider-line">
														<hr />
													</span>
												</div>
												<PrintItinerary close={close} />
											</>
										) : (
											<div className="auth-actions-container">
												<CustomBtn
													loading={requestLoading}
													text="Log In"
													onClick={handleEmailAuth}
													width={300}
													disabled={isDisabled}
													className="button-primary gradient round large"
												/>
												<div className="other-actions-link">
													<span>
														{isLogin
															? 'You don’t have an account?'
															: 'Already have an account?'}
													</span>
													<strong>
														<u
															onClick={
																changeComponent
															}
														>
															{isLogin
																? 'Sign Up'
																: 'Sign In'}
														</u>
													</strong>
												</div>

												<div className="auth-divider">
													<span className="auth-divider-line">
														<hr />
													</span>
													<span className="auth-divider-o">
														Or
													</span>
													<span className="auth-divider-line">
														<hr />
													</span>
												</div>
												<CustomBtn
													loading={false}
													text="Print your Itinerary"
													onClick={() =>
														setIsDisabled(
															!isDisabled
														)
													}
													width={300}
													className="button-primary gradient round large"
												/>
											</div>
										)}
									</>
								) : (
									<>
										<SignUp actions={actions} />
										<div className="other-actions-link">
											<span>
												{isLogin
													? 'You don’t have an account?'
													: 'Already have an account?'}
											</span>
											<strong>
												<u onClick={changeComponent}>
													{isLogin
														? 'Sign Up'
														: 'Sign In'}
												</u>
											</strong>
										</div>
									</>
								)}
							</div>
						</Row>
					</TabPane>
				)}

				<TabPane tabId="2">
					<Row>
						<Col sm="12">
							<ShareLink itineraryParams={itineraryParams} />
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</>
	);
};

const mapStateToProps = (state) => {
	const { signInError } = state.auth;
	return { signInError };
};

const mapDispatchToProps = (dispatch) => {
	return {
		emailAuth: (data) => dispatch(emailAuth(data)),
		googleAuth: (data) => dispatch(googleAuth(data)),
		fbAuth: (data) => dispatch(fbAuth(data)),
		resetError: (data) => dispatch(resetError(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
