import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { isEmpty } from 'lodash';

import UserPhoto from '../utils/UserPhoto';
import UserField from './UserField';

import { FIELD_SCHEMA, getUserFields } from '../../schemas/User';
import { DOB_DISPLAY_FORMAT } from '../../consts/DateFormats';

import './profile.scss';

const Profile = () => {
	const { profile } = useSelector((state) => state.firebase);
	const { isAmbassador, firstName, bio, city, country, photo } = profile;

	const fieldKeys = getUserFields(isAmbassador);

	return (
		<div id="profile-container">
			<UserPhoto size={150} image={photo} position="top" />
			<div className="fields-container">
				<div className="main-fields">
					<UserField className="city-field" value={city} />
					<UserField className="country-field" value={country} />
					<UserField className="name-field" value={firstName} />
					<UserField className="bio-field" value={bio} />
				</div>

				{fieldKeys.map((key) => {
					// HACK: leave out above fields
					if (
						key === 'city' ||
						key === 'country' ||
						key === 'firstName' ||
						key === 'bio' ||
						key === 'photo'
					) {
						return null;
					}

					const field = FIELD_SCHEMA[key];
					const { label, type, caps, show } = field;
					let value = profile[key];

					if (key === 'dob' && !isEmpty(value)) {
						value = moment(value).format(DOB_DISPLAY_FORMAT);
					}

					return (
						<UserField
							key={key}
							slug={key}
							label={label}
							type={type}
							value={value}
							caps={caps}
							show={show}
						/>
					);
				})}
			</div>
			<div className="actions-container">
				<Link to="/profile/edit">
					<Button className="button-primary">
						<FontAwesomeIcon icon={faPen} />
						Edit Profile
					</Button>
				</Link>
				{!isAmbassador && firstName && (
					<>
						<Link to="/new">
							<Button className="button-primary outlined">
								New Trip
							</Button>
						</Link>
						<Link to="/trips">
							<Button className="button-primary outlined">
								My Trips
							</Button>
						</Link>
					</>
				)}
			</div>
		</div>
	);
};

export default Profile;
