import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import { useSelector, connect } from 'react-redux';
import { isEmpty, union } from 'lodash';

import { createBooking } from '../../store/actions/bookingActions';

import CustomBtn from '../utils/CustomBtn';
import AlertMsg from '../utils/Alert';
import TotalPrice from '../utils/TotalPrice';
import PricingBtn from './PricingBtn';
import HighOrderModal from '../utils/HighOrderModal';
import ItineraryUtility from '../itineraryUtility/ItineraryUtility';

import { getUrlParameters } from '../../utils/Urls';
import { getDateRange, toDate, toMoment, isValidDate } from '../../utils/Dates';
import { getAvailability } from '../../utils/User';
import { groupSizePrice } from '../../utils/Pricing';
import { areEqualBookings } from '../../schemas/Booking';

import { BOOKING_DB_FORMAT } from '../../consts/DateFormats';
import {
	EXCEEDED_TRAVELERS_2,
	BOOKING_INCLUDES,
	COVID_SUSPENSION,
} from '../../consts/Messages';
import {
	COVID_SUSPENSION_START,
	COVID_SUSPENSION_END,
} from '../../consts/Misc';

import './bookingBar.scss';

const BookingBar = ({ user, location, createBooking, history }) => {
	const { profile } = useSelector((state) => state.firebase);
	const { booking } = useSelector((state) => state.booking);
	const { testerActivities, utilityActivities } = useSelector(
		(state) => state.activities
	);

	const [isModalOpen, setModalOpen] = useState(false);
	const [isAlertOpen, setAlert] = useState(false);
	const [isTooltipOpen, setTooltip] = useState(false);
	const [isGroupValid, setValidGroup] = useState(true);
	const [areDatesValid, setValidDates] = useState(true);
	const [errorData, setError] = useState({});
	const [requestLoading, setLoading] = useState(false);

	const [adults, setAdults] = useState(3);
	const [kids, setKids] = useState(0);
	const [price, setPrice] = useState(0);
	const [dates, setDates] = useState(null);
	const [bookingData, setBooking] = useState({});

	const { firstName, availability, carType, city } = user;
	const { availableDates, unavailableDates } = getAvailability(availability);

	// COVID specific
	const covidDates = getDateRange(
		COVID_SUSPENSION_START,
		COVID_SUSPENSION_END
	);
	const disabledDates = union(unavailableDates, covidDates);

	useEffect(() => {
		const param = location.search;
		let datesArray = [];
		let priceIncrease = 0;

		if (!isEmpty(param)) {
			const urlParams = getUrlParameters();
			const { code, d } = urlParams;
			priceIncrease = code ? parseInt(window.atob(code)) : 0;
			datesArray = d ? d.split(',') : [];
		}

		let areDatesValid = true;
		datesArray.forEach((date) => {
			areDatesValid = areDatesValid && isValidDate(date);
		});

		datesArray = areDatesValid ? datesArray : [];
		const formattedDates = toDate(datesArray);
		setDates(formattedDates);

		let numTravelers;
		const nAdults = parseInt(localStorage.getItem('adults'));
		const nKids = parseInt(localStorage.getItem('kids'));

		if (nAdults || nKids) {
			numTravelers = parseInt(nAdults) + parseInt(nKids || 0);
			setAdults(parseInt(nAdults));
			setKids(parseInt(nKids));
		} else {
			numTravelers = adults + kids;
			localStorage.setItem('adults', adults);
			localStorage.setItem('kids', kids);
			localStorage.setItem('numTravelers', numTravelers);
		}

		const tripPrice = groupSizePrice(city, numTravelers, datesArray);
		setPrice(tripPrice + priceIncrease);
	}, []);

	useEffect(() => {
		setValidDates(!isEmpty(dates));
	}, [dates]);

	useEffect(() => {
		// this condition means a booking was created
		if (!isEmpty(booking) && booking.id && !isEmpty(bookingData)) {
			history.push({
				pathname: `/booking/${booking.id}`,
				state: { bookingId: booking.id },
			});
		}
	}, [booking]);

	const toggleModal = () => {
		setModalOpen(!isModalOpen);
	};

	const toggleToolTip = () => {
		setTooltip(!isTooltipOpen);
	};

	const handleTravelersInput = ({ adults, kids }) => {
		const numTravelers = adults + kids;
		const tripPrice = groupSizePrice(city, numTravelers, dates);
		setAdults(adults);
		setKids(kids);
		setPrice(tripPrice);
		localStorage.setItem('adults', adults);
		localStorage.setItem('kids', kids);
		localStorage.setItem('numTravelers', numTravelers);
	};

	const handleGroupSizeValidation = (validGroup) => {
		setValidGroup(validGroup);
		setError({ message: EXCEEDED_TRAVELERS_2 });
		setAlert(!validGroup);
	};

	const handleDatesInput = (dates) => {
		const numTravelers = parseInt(adults) + parseInt(kids);
		const formattedDates = toMoment(dates, BOOKING_DB_FORMAT);
		const tripPrice = groupSizePrice(city, numTravelers, dates);
		setDates(formattedDates);
		setPrice(tripPrice);
	};

	const requestBooking = () => {
		if (!areDatesValid) {
			return;
		}

		setLoading(true);

		// TODO: concierge
		// if (profile.isAgent) {
		// 	data['agentId'] = profile.uid;
		// } else {
		// data['travelerId'] = profile.uid;
		// }

		// HACK: handle tester and utility incoming activities
		const activityIds =
			utilityActivities.map((act) => act.id) || testerActivities;

		const bookingData = {
			ambassadorId: user.uid,
			price,
			dates,
			numAdults: adults,
			numChildren: kids,
			city,
			travelerId: profile.uid,
			testerActivities: activityIds,
		};

		setBooking(bookingData);

		// avoid creating identical bookings
		if (areEqualBookings(booking, bookingData)) {
			history.push({
				pathname: `/booking/${booking.id}`,
				state: { bookingId: booking.id },
			});
		} else {
			createBooking(bookingData);
		}
	};

	return (
		<div id="booking-bar-container">
			<div id="pricing">
				<TotalPrice price={price} numTravelers={adults + kids} />
				<Tooltip
					placement="top"
					isOpen={isTooltipOpen}
					target="pricing"
					toggle={toggleToolTip}
				>
					{BOOKING_INCLUDES}
				</Tooltip>
			</div>
			<div className="btn-container">
				<PricingBtn
					adults={adults}
					kids={kids}
					carType={carType}
					handleChange={handleTravelersInput}
					handleValidation={handleGroupSizeValidation}
					disabled={!isGroupValid}
				/>
				<CustomBtn
					loading={false}
					text={'Dates'}
					width={152}
					datesMode={true}
					handleDatesInput={handleDatesInput}
					availableDates={availableDates}
					selectedDates={dates}
					disabledDates={disabledDates}
					className="button-primary gradient round large"
				/>
				<CustomBtn
					loading={false}
					text={'My Itinerary'}
					width={152}
					className="button-primary gradient round large"
					onClick={toggleModal}
				/>
				<CustomBtn
					loading={requestLoading}
					text={`Book time with ${firstName}`}
					onClick={requestBooking}
					disabled={!areDatesValid || adults < 1}
					width={270}
					className="button-primary gradient round large"
				/>
			</div>

			<div className="alerts-container">
				<AlertMsg
					content={errorData.message}
					dismiss={() => setAlert(false)}
					isOpen={isAlertOpen}
				/>
				<AlertMsg
					content={COVID_SUSPENSION}
					color="warning"
					isOpen={true}
				></AlertMsg>
				<HighOrderModal
					isOpen={isModalOpen}
					component={ItineraryUtility}
					toggle={toggleModal}
					fullScreen={true}
					ambassadorId={user.uid}
					dates={dates}
					history={history}
				/>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		createBooking: (data) => dispatch(createBooking(data)),
	};
};

export default connect(null, mapDispatchToProps)(BookingBar);
