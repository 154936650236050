export const updatedActivitySchema = (idActivity, idItinerary, data) => {
	return {
		itineraryId: idItinerary,
		activityId: idActivity,
		active: data.active,
		addedBy: data.addedBy,
		categoryIds: data.categoryIds || [],
		createdBy: data.createdBy,
		day: data.day,
		description: data.description,
		duration: data.duration,
		name: data.name,
		pictures: data.pictures || [],
		order: data.order,
		equipment: data.equipment || [],
		extraFees: data.extraFees || [],
		location: data.location,
		longDescription: data.longDescription || '',
		kidFriendly: data.kidFriendly || false,
		location: data.location,
	};
};

export const activityStructure = (data) => {
	return {
		active: data.active,
		addedBy: data.addedBy,
		approved: data.approved,
		categoryIds: data.categoryIds,
		createdBy: data.createdBy,
		day: data.day,
		description: data.description,
		duration: data.duration,
		id: data.activityId,
		name: data.name,
		pictures: data.pictures || {},
		equipment: data.equipment || [],
		extraFees: data.extraFees || [],
		longDescription: data.longDescription || '',
		kidFriendly: data.kidFriendly || false,
		location: data.location,
	};
};

export const cloneActivitySchema = (data) => {
	return {
		id: data.id,
		originalId: data.originalId,
		active: data.active || true,
		addedBy: data.addedBy,
		categoryIds: data.activityId || [],
		createdBy: data.createdBy,
		day: data.day,
		description: data.description,
		duration: data.duration,
		name: data.name,
		pictures: data.pictures || [],
		order: data.order,
		equipment: data.equipment || [],
		extraFees: data.extraFees || [],
		longDescription: data.longDescription || '',
		kidFriendly: data.kidFriendly || false,
		location: data.location,
		mapEmbed: data.mapEmbed || '',
	};
};

export const dragAndDropOrderSchema = (data, id, oldState) => {
	let activities = { itineraryActivities: {} };

	let newActivities = data.map((value, index) => {
		activities.itineraryActivities[value.id] = {
			order: index,
		};
		return { ...value, order: index };
	});
	let temp = oldState.filter((value) => value.day != data[0].day);
	newActivities = [...newActivities, ...temp];
	return { ...activities, itineraryId: id, newActivities };
};

export const deleteSchema = (
	activities,
	activityId,
	currentDay,
	itineraryId
) => {
	let data = activities.filter((value) => value.id != activityId);
	let activityToDelete = activities.filter(
		(value) => value.id === activityId
	);
	data = data.map((value) => {
		if (
			value.day === currentDay &&
			value.order > activityToDelete[0].order
		) {
			return { ...value, order: parseInt(value.order) - 1 };
		} else {
			return { ...value };
		}
	});

	return { data, activityId, itineraryId };
};
