import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { faDownload, faCheck } from '@fortawesome/free-solid-svg-icons';

import Loader from '../utils/Loader';
import BtnLoader from '../utils/BtnLoader';
import AlertMsg from '../utils/Alert';
import UserEditField from './UserEditField';

import { isOfAge, isExtValid, getInvalidField } from '../../utils/Validation';
import mailer from '../../utils/Mailer';
import { profileChangesToHtml } from '../../utils/Formatting';
import {
	FIELD_SCHEMA,
	getUserFields,
	buildUserObject,
} from '../../schemas/User';

import {
	DOB_DISPLAY_EDIT_FORMAT,
	AVAILABILITY_DB_FORMAT,
} from '../../consts/DateFormats';

import './profileEdit.scss';

const ProfileEdit = ({ history }) => {
	const firebase = useFirebase();
	const { profile } = useSelector((state) => state.firebase);

	const [errorData, setError] = useState('');
	const [alertOpen, setAlert] = useState(false);
	const [userProfile, setProfile] = useState(null);
	const [photoValid, setPhotoValid] = useState(false);
	const [requestLoading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(true);

	const updateUser = (profile) => {
		setProfile(profile);
		setDisabled(false);
	};

	useEffect(() => {
		if (!profile || profile === userProfile) {
			return;
		}

		updateUser(profile);
		setDisabled(true);
		setLoading(false);
	}, [profile]);

	if (isEmpty(userProfile)) {
		return <Loader />;
	}

	const { uid, firstName, isAmbassador } = profile;
	const fieldKeys = getUserFields(isAmbassador);

	const handleChange = (e) => {
		const { id, value } = e.target;

		if (id === 'dob' && !isOfAge(value)) {
			return;
		}

		const profile = {
			...userProfile,
			[id]: value,
		};
		updateUser(profile);
	};

	const handlePhoto = (url) => {
		const profile = {
			...userProfile,
			photo: url,
		};
		updateUser(profile);
	};

	const handlePictures = (pictures) => {
		const profile = {
			...userProfile,
			lifestylePics: pictures,
		};
		updateUser(profile);
	};

	const handleAvailability = (dates) => {
		let availability = {};

		let formattedDate;
		dates.forEach((date) => {
			formattedDate = moment(date).format(AVAILABILITY_DB_FORMAT);
			availability[formattedDate] = true;
		});

		const profile = {
			...userProfile,
			availability,
		};
		updateUser(profile);
	};

	const handleExampleDay = (hours, activities) => {
		let exampleDay = {};
		hours.forEach((time, idx) => {
			exampleDay = {
				...exampleDay,
				[time]: activities[idx],
			};
		});

		const profile = {
			...userProfile,
			exampleDay,
		};
		updateUser(profile);
	};

	const isProfileValid = (profile) => {
		const invalidField = getInvalidField(profile);
		if (!isEmpty(invalidField)) {
			setError(`Please fill ${FIELD_SCHEMA[invalidField].label}`);
			setAlert(true);
			return false;
		}

		setError('');
		setAlert(false);
		return true;
	};

	const handleSubmit = () => {
		const user = buildUserObject(userProfile);

		if (!isProfileValid(user)) {
			return;
		}

		setLoading(true);
		user['updatedAt'] = new Date();
		firebase.updateProfile(user);

		if (isAmbassador) {
			const changesHtml = profileChangesToHtml(user, profile);
			mailer.sendProfileUpdateNotification(firstName, uid, changesHtml);
		}

		history.push('/profile');
	};

	return (
		<div id="profile-edit-container">
			<AlertMsg content={errorData} isOpen={alertOpen} />
			{fieldKeys.map((key) => {
				const field = FIELD_SCHEMA[key];
				const { label, type, options } = field;
				let value = userProfile[key];

				if (key === 'dob' && !isEmpty(value)) {
					value = moment(value).format(DOB_DISPLAY_EDIT_FORMAT);
				}

				return (
					<UserEditField
						key={key}
						uid={uid}
						slug={key}
						label={label}
						type={type}
						value={value}
						options={options}
						onChange={handleChange}
						handleAvailability={handleAvailability}
						handleExampleDay={handleExampleDay}
						handlePictures={handlePictures}
						handlePhoto={handlePhoto}
					/>
				);
			})}

			<BtnLoader
				loading={requestLoading}
				text="Save Changes"
				loadingText=""
				disabledText="Saved"
				icon={faDownload}
				disabledIcon={faCheck}
				onClick={handleSubmit}
				disabled={disabled}
			></BtnLoader>
		</div>
	);
};

export default ProfileEdit;
