import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './dataSpan.scss';

const DataSpan = ({ data, icon, label, tooltipText, size = 'lg' }) => {
	const [tooltipOpen, setTooltip] = useState(false);

	const toggleTooltip = () => {
		setTooltip(!tooltipOpen);
	};

	if (isEmpty(data)) {
		return null;
	}

	return (
		<div id={label} className="data-span">
			<FontAwesomeIcon icon={icon} size={size} />
			<span>{data}</span>

			<Tooltip
				placement="top"
				isOpen={tooltipOpen}
				target={label}
				toggle={toggleTooltip}
			>
				{tooltipText}
			</Tooltip>
		</div>
	);
};

export default DataSpan;
