import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Profile from './components/profile/Profile';
import ProfileEdit from './components/profile/ProfileEdit';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import NewTrip from './components/newTrip/NewTrip';
import ItineraryUtility from './components/itineraryUtility/ItineraryUtility';
import SearchResultList from './components/resultsList/SearchResultList';
import User from './components/users/User';
import Booking from './components/bookings/Booking';
import Trip from './components/trips/Trip';
import Checkout from './components/bookings/Checkout';
import Trips from './components/trips/Trips';
import Chat from './components/chats/Chat';
import NotFound from './components/utils/NotFound';

export default [
	{
		path: '/login',
		type: PublicRoute,
		component: SignIn,
		title: 'Log In',
	},
	{
		path: '/signup',
		type: PublicRoute,
		component: SignUp,
		title: 'Sign Up',
	},
	{
		path: '/user/:uid',
		type: PublicRoute,
		component: User,
		title: '',
	},
	{
		path: '/itinerary-builder',
		type: PublicRoute,
		component: ItineraryUtility,
		title: '',
	},
	{
		path: '/new',
		exact: true,
		type: PublicRoute,
		component: NewTrip,
		title: 'New Trip',
	},
	{
		path: '/new/results',
		type: PublicRoute,
		component: SearchResultList,
		title: 'These are the RedKnot Ambassadors picked for you:',
	},
	{
		path: '/profile',
		exact: true,
		type: PrivateRoute,
		component: Profile,
		title: 'Your Profile',
	},
	{
		path: '/profile/edit',
		type: PrivateRoute,
		component: ProfileEdit,
		title: 'Edit Profile',
	},
	{
		path: '/booking/:id',
		type: PrivateRoute,
		component: Booking,
		title: 'Trip Summary',
	},
	{
		path: '/trips',
		type: PrivateRoute,
		exact: true,
		component: Trips,
	},
	{
		path: '/trips/:id',
		type: PrivateRoute,
		component: Trip,
	},
	{
		path: '/checkout/:id',
		type: PrivateRoute,
		component: Checkout,
	},
	{
		path: '/chats/:id',
		type: PrivateRoute,
		component: Chat,
	},
	{
		path: '/',
		exact: true,
		type: PublicRoute,
		component: () => {
			window.location.href = 'https://goredknot.com';
			return null;
		},
	},
	{
		type: PublicRoute,
		component: NotFound,
	},
];
