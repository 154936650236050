import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, InputGroupAddon, InputGroup, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';

import {
	emailSignup,
	googleAuth,
	fbAuth,
	resetError,
} from '../../store/actions/authActions';

import AlertMsg from '../utils/Alert';
import BtnLoader from '../utils/BtnLoader';

import google from '../../assets/auth/google-01-min.png';
import facebook from '../../assets/auth/facebook.png';

import './auth.scss';

const SignUp = ({
	emailSignup,
	fbAuth,
	googleAuth,
	signUpError,
	resetError,
	actions,
}) => {
	const [requestLoading, setLoading] = useState(false);
	const [errorData, setError] = useState('');
	const [isAlertOpen, setAlert] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		setError(signUpError);
	}, [signUpError]);

	useEffect(() => {
		setAlert(!!errorData);
		setLoading(!errorData && !areFieldsEmpty());
	}, [errorData]);

	const areFieldsEmpty = () => {
		return isEmpty(email) && isEmpty(password);
	};

	const handleName = (e) => {
		const { value } = e.target;
		setName(value);
	};

	const handleEmail = (e) => {
		const { value } = e.target;
		setEmail(value);
	};

	const handlePassword = (e) => {
		const { value } = e.target;
		setPassword(value);
	};

	const onKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleEmailSignup(e);
		}
	};

	const dismissAlert = () => {
		resetError();
	};

	const handleEmailSignup = (event) => {
		event.preventDefault();

		if (isEmpty(name)) {
			setError('Please enter your name.');
			setAlert(true);
			return;
		}

		if (email.length < 4) {
			setError('Please enter an email address.');
			setAlert(true);
			return;
		}

		if (password.length < 4) {
			setError('Please enter a password.');
			setAlert(true);
			return;
		}

		resetError();
		setLoading(true);
		emailSignup({ name, email, password });
	};

	return (
		<div id="auth-container">
			<AlertMsg
				content={errorData}
				dismiss={dismissAlert}
				isOpen={!isEmpty(errorData) && isAlertOpen}
			/>
			<div>
				<Button className="auth-provider-button" onClick={googleAuth}>
					<img alt="google-icon" src={google} />
					Sign Up with Google
				</Button>
				<Button className="auth-provider-button" onClick={fbAuth}>
					<img alt="facebook-icon" src={facebook} />
					Sign Up with Facebook
				</Button>
				<div className="auth-divider">
					<span className="auth-divider-line">
						<hr />
					</span>
					<span className="auth-divider-o">O</span>
					<span className="auth-divider-line">
						<hr />
					</span>
				</div>
				<div className="auth-fields">
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<FontAwesomeIcon icon={faUser} />
						</InputGroupAddon>
						<Input
							className="form-input"
							placeholder="First Name"
							onChange={handleName}
							onKeyPress={onKeyPress}
						/>
					</InputGroup>
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<FontAwesomeIcon icon={faEnvelope} />
						</InputGroupAddon>
						<Input
							className="form-input"
							placeholder="Email"
							onChange={handleEmail}
							onKeyPress={onKeyPress}
						/>
					</InputGroup>
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<FontAwesomeIcon icon={faLock} />
						</InputGroupAddon>
						<Input
							className="form-input"
							type="password"
							placeholder="Password"
							onChange={handlePassword}
							onKeyPress={onKeyPress}
						/>
					</InputGroup>
				</div>
			</div>
			<div className="auth-actions">
				<BtnLoader
					loading={requestLoading}
					text="Sign Up"
					onClick={handleEmailSignup}
				/>
				{actions && (
					<div className="other-actions">
						<div>Already have an account?</div>
						<strong>
							<Link to="/login">
								<u>Sign In</u>
							</Link>
						</strong>
						<span role="img" aria-label="point-left-emoji">
							{' '}
							👈
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { signUpError } = state.auth;
	return { signUpError };
};

const mapDispatchToProps = (dispatch) => {
	return {
		emailSignup: (data) => dispatch(emailSignup(data)),
		googleAuth: (data) => dispatch(googleAuth(data)),
		fbAuth: (data) => dispatch(fbAuth(data)),
		resetError: (data) => dispatch(resetError(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

SignUp.defaultProps = {
	actions: true,
};
