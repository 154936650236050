import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { Button, Input } from 'reactstrap';
import { isEmpty, findKey } from 'lodash';

import {
	fetchBooking,
	updateBookingDetails,
	addTraveler,
	createCheckoutSession,
} from '../../store/actions/bookingActions';

import BookingDetail from './BookingDetail';
import Dates from '../utils/Dates';
import TotalPrice from '../utils/TotalPrice';
import UserPhoto from '../utils/UserPhoto';
import BtnLoader from '../utils/BtnLoader';
import AlertMsg from '../utils/Alert';
import Loader from '../utils/Loader';
import GroupSizeHandler from '../utils/GroupSizeHandler';

import { getUserProfileName } from '../../utils/User';
import { groupSizePrice } from '../../utils/Pricing';
import { PROMO_CODES } from '../../consts/PromoCodes';
import {
	EXCEEDED_TRAVELERS,
	INVALID_PROMO_CODE,
	SINGLE_PROMO_CODE,
	EMAIL_INPUT_REQUIRED,
} from '../../consts/Messages';
import {
	BOOKING_DB_FORMAT,
	BOOKING_DISPLAY_FORMAT,
} from '../../consts/DateFormats';

import './booking.scss';

const Booking = ({
	match,
	fetchBooking,
	updateBookingDetails,
	createCheckoutSession,
}) => {
	const { profile } = useSelector((state) => state.firebase);
	const { booking } = useSelector((state) => state.booking);

	const [isAlertOpen, setAlert] = useState(false);
	const [isValid, setValid] = useState(true);
	const [errorData, setError] = useState({});
	const [requestLoading, setLoading] = useState(false);

	const [code, setCode] = useState('');
	const [email, setEmail] = useState('');
	const [adults, setAdults] = useState(1);
	const [kids, setKids] = useState(0);
	const [price, setPrice] = useState(0);
	const [travelerId, setTravelerId] = useState('');

	const requestedId = match.params.id;

	useEffect(() => {
		if (isEmpty(booking) || booking.id !== requestedId) {
			fetchBooking(requestedId);
		}
	}, []);

	useEffect(() => {
		if (isEmpty(booking) || booking.id !== requestedId) {
			return;
		}

		const { id, numAdults, numChildren, price, travelerId } = booking;

		// login was made after booking creation
		if (!travelerId) {
			const updateData = {
				id,
				props: {
					travelerId: profile.uid,
				},
			};
			updateBookingDetails(updateData);
		}

		setAdults(numAdults);
		setKids(numChildren);
		setPrice(price);
		setTravelerId(travelerId);
	}, [booking]);

	if (isEmpty(booking) || booking.id !== requestedId) {
		return <Loader />;
	}

	const { isAgent } = profile;
	const { id, ambassador, city, dates, status, itineraryId } = booking;
	const { uid, photo, firstName, carType } = ambassador;

	const handleTravelers = ({ adults, kids }) => {
		const numTravelers = adults + kids;
		const tripPrice = groupSizePrice(city, numTravelers, dates);

		setAdults(adults);
		setKids(kids);
		setPrice(tripPrice);

		const updateData = {
			id,
			props: {
				numAdults: adults,
				numChildren: kids,
				price: tripPrice,
			},
		};

		updateBookingDetails(updateData);
	};

	const handleBookingValidation = (isValid) => {
		setValid(isValid);
		setError({ message: EXCEEDED_TRAVELERS });
		setAlert(!isValid);
	};

	const redeemCode = () => {
		const { couponId } = booking;

		if (couponId) {
			setError({ message: SINGLE_PROMO_CODE });
			setAlert(true);
			return;
		}

		const promoCode = findKey(PROMO_CODES, { code: code });
		if (!promoCode) {
			setError({ message: INVALID_PROMO_CODE });
			setAlert(true);
			return;
		}

		const coupon = PROMO_CODES[promoCode];
		const { ammountOff } = coupon;
		let newPrice = price - ammountOff;
		if (newPrice < 0) newPrice = 0;
		setPrice(newPrice);

		const updateData = {
			id,
			props: {
				price: newPrice,
				couponId: promoCode,
			},
		};

		updateBookingDetails(updateData);
	};

	const onConfirm = () => {
		if (isAgent && !email) {
			setError({ message: EMAIL_INPUT_REQUIRED });
			setAlert(true);
			return;
		}

		setLoading(true);

		// TODO: concierge
		if (isAgent) {
			const firstName = getUserProfileName(email);
			const data = {
				email,
				firstName,
				bookingId: id,
			};
			addTraveler(data);
		} else {
			createCheckoutSession({ booking, ambassador, bookingId: id });
		}
	};

	return (
		<div id="booking-container">
			<AlertMsg
				content={errorData.message}
				dismiss={() => setAlert(false)}
				isOpen={isAlertOpen}
			/>
			<div className="ambassador-container">
				<span className="heading">Ambassador:</span>
				<div className="ambassador-info">
					<UserPhoto size={53} image={photo} />
					<div className="ambassador-profile">
						<Link
							to={{
								pathname: `/user/${uid}`,
								state: {
									dates,
									adults,
									kids,
								},
							}}
						>
							<div>{firstName}</div>
							<div>View profile</div>
						</Link>
					</div>
				</div>
			</div>
			<div className="details-container">
				<BookingDetail label="City:" value={city} />
				<BookingDetail
					label="Dates:"
					value={
						<Dates
							dates={dates}
							formatFrom={BOOKING_DB_FORMAT}
							formatTo={BOOKING_DISPLAY_FORMAT}
						/>
					}
				/>
				<BookingDetail
					label="Number of Travelers:"
					value={
						<GroupSizeHandler
							adults={adults}
							kids={kids}
							carType={carType}
							handleChange={handleTravelers}
							handleValidation={handleBookingValidation}
							disabled={!isValid}
						></GroupSizeHandler>
					}
				/>
				<div className="pricing-container">
					{status !== 'completed' && (
						<div className="booking-actions">
							<BookingDetail
								label="PROMO CODE:"
								value={
									<div className="promo-code">
										<Input
											value={code}
											onChange={(e) =>
												setCode(e.target.value)
											}
										></Input>
										<Button onClick={redeemCode}>
											Redeem
										</Button>
									</div>
								}
								divider={false}
							/>
							{isAgent && (
								<BookingDetail
									label="Traveler email:"
									value={
										<Input
											type="email"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
										></Input>
									}
									divider={false}
								/>
							)}
						</div>
					)}
					<BookingDetail
						label="TOTAL:"
						value={
							<TotalPrice
								price={price}
								numTravelers={adults + kids}
							/>
						}
						divider={false}
					/>
				</div>
			</div>
			{status === 'completed' ? (
				<Link to={`/trips/${itineraryId}`}>
					<Button className="button-primary">View Trip</Button>
				</Link>
			) : (
				<div className="valid-booking-actions">
					<BtnLoader
						loading={requestLoading}
						disabled={!travelerId || adults < 1}
						text={'Confirm and Pay'}
						onClick={onConfirm}
					/>
					<p className="side-note">
						Pickup time and location can be selected after booking.
					</p>
					<a
						href="https://global-uploads.webflow.com/5caf6429713e9d6b020cb773/5e19ba0071ed44125909af21_RedKnot%20T%26Cs%2011%20Jan.pdf"
						className="terms-conditions-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div>Terms & Conditions</div>
					</a>
				</div>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchBooking: (data) => dispatch(fetchBooking(data)),
		updateBookingDetails: (data) => dispatch(updateBookingDetails(data)),
		addTraveler: (data) => dispatch(addTraveler(data)),
		createCheckoutSession: (data) => dispatch(createCheckoutSession(data)),
	};
};

export default connect(null, mapDispatchToProps)(Booking);
