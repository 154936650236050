import React from 'react';
import './noResultsActions.scss';

const NoResultsActions = () => {
	return (
		<p className="explanatory-text">
			We are sorry, there are no available RedKnot Ambassadors for your
			selected search parameters. <br></br>Click on the button below to
			modify them and search again or{' '}
			<a href="javascript:void(Tawk_API.toggle())">Click Here</a> and chat
			with us to help you customize your trip.
		</p>
	);
};

export default NoResultsActions;
