import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { isEmpty } from 'lodash';

import Mailer from '../../utils/Mailer';
import { fetchChat, createMessage } from '../../store/actions/chatActions';

import ChatMessage from './ChatMessage';
import ChatNavbar from './ChatNavbar';
import Loader from '../utils/Loader';

import { CHAT_DISPLAY_FORMAT } from '../../consts/DateFormats';

import './chat.scss';

const Chat = ({ match, fetchChat, createMessage }) => {
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);
	const [flag, setFlag] = useState(true);
	const [canParticipate, setParticipate] = useState(false);

	const { profile } = useSelector((state) => state.firebase);
	const { chat } = useSelector((state) => state.chat);
	// live-updated chat instance
	const fsChat = useSelector((state) => state.firestore.data.chat);

	const requestedId = match.params.id;

	// use firestore connect to listen for new messages created on the db
	useFirestoreConnect([
		{
			collection: 'chats',
			doc: requestedId,
			storeAs: 'chat',
		},
	]);

	useEffect(() => {
		fetchChat({ id: requestedId });
	}, []);

	// TODO: use isloaded
	useEffect(() => {
		if (isEmpty(chat) || chat.id !== requestedId) {
			return;
		}

		const isParticipant = !!participants[uid];
		if (isAdmin && !isParticipant) {
			const adminData = {
				uid: uid,
				firstName: 'Redknot Team',
				photo: '',
				email: 'bridges@goredknot.com',
			};
			participants[uid] = adminData;
		}

		setParticipate(isAdmin || isParticipant);
	}, [chat]);

	// messages are handled with firestore connect to immediatly display incoming messages
	useEffect(() => {
		if (!fsChat) {
			return;
		}

		const { messages } = fsChat;
		setMessages(messages);
	}, [fsChat]);

	useEffect(() => {
		window.scrollTo(0, document.body.scrollHeight);
	});

	if (!chat || chat.id !== requestedId) {
		return <Loader />;
	}

	const { uid, isAdmin } = profile;
	const { participants } = chat;

	const handleMessage = (e) => {
		setMessage(e.target.value);
	};

	const onKeyPress = (e) => {
		if (e.key === 'Enter') {
			onSend();
		}
	};

	const addMessage = (message) => {
		const chatId = chat.id;
		createMessage({ chatId, message });
		setMessage('');
	};

	const sendNotification = () => {
		const chatId = chat.id;

		let userName = '';
		let email = '';
		Object.values(participants).forEach((participant) => {
			if (participant.uid === uid) {
				userName = participant.firstName;
			} else {
				email = participant.email;
			}
		});
		Mailer.sendChatNotification(email, userName, message, flag, chatId);
		setFlag(!flag);
	};

	const onSend = () => {
		if (!canParticipate) {
			return;
		}

		const now = new Date();
		const newMessage = {
			senderId: uid,
			text: message,
			timestamp: moment(now).format(CHAT_DISPLAY_FORMAT),
		};

		addMessage(newMessage);
		sendNotification();
	};

	return (
		<div id="chat-container">
			{/* <ChatNavbar participants={participants}></ChatNavbar> */}
			<div className="messages-container">
				{messages.map((message) => {
					const { senderId } = message;
					const participant = participants[senderId];
					// first message always comes from HQ user (not a participant)
					const isResponse = participant
						? participant.uid !== uid
						: true;
					return (
						<ChatMessage
							message={message}
							participant={participant}
							isResponse={isResponse}
						></ChatMessage>
					);
				})}
			</div>
			<div className="input-fixer">
				<div className="input-container">
					<Input
						className="chat-input"
						placeholder="Write message"
						value={message}
						onChange={handleMessage}
						onKeyPress={onKeyPress}
						disabled={!canParticipate}
					></Input>
					<Button
						className="button-chat"
						onClick={onSend}
						disabled={!canParticipate}
					>
						Send
					</Button>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchChat: (data) => dispatch(fetchChat(data)),
		createMessage: (data) => dispatch(createMessage(data)),
	};
};

export default connect(null, mapDispatchToProps)(Chat);
