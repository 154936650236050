// Group size add on prices
// Normal corresponds to MEX, SAL and GUA countries
// Special corresponds to Costa Rica
export const NORMAL_PRICE_ADD_ON = [0, 10, 10, 7, 33, 10, 10];
export const SPECIAL_PRICE_ADD_ON = [0, 15, 15, 20, 30, 15, 14];

export const GROUP_SIZE_LIMIT = NORMAL_PRICE_ADD_ON.length;

export const NORMAL_MAX_ADD_ON = NORMAL_PRICE_ADD_ON[GROUP_SIZE_LIMIT - 1];
export const SPECIAL_MAX_ADD_ON = SPECIAL_PRICE_ADD_ON[GROUP_SIZE_LIMIT - 1];

export const NORMAL_BASE_PRICE = 89;
export const SPECIAL_BASE_PRICE = 99;

export const NORMAL_PRICE_COUNTRIES = ['El Salvador', 'Guatemala', 'México'];
