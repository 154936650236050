import React from 'react';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPen,
	faBookmark,
	faShareAlt,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import ActivityTile from '../itineraryBuilder/ActivityTile';
import { toRemainingTime } from '../utils/itinerary';

import {
	HOURS_REMAINING,
	NO_ACTIVITIES_ADDED_1,
	NO_ACTIVITIES_ADDED_2,
	SAVE_ITINERARY,
	SHARE_ITINERARY,
	TIME_FILL_INDICATION,
	ITINERARY_BUILDER,
} from '../../consts/Messages';

import './selectedActivitiesList.scss';

const SelectedActivitiesList = ({
	activities,
	openActivity,
	setActivity,
	deleteActivity,
	addedActivities,
	handleActivities,
	remainingTime,
	editMode,
	toggleEditMode,
	toggleActionsModal,
}) => {
	const getSortableList = (activityList) => {
		const SortableList = SortableContainer(() => {
			return (
				<ul>
					{activityList.map((activity, idx) => {
						const key = `sortable-item-${idx}`;
						return getSortableItem(key, idx, activity);
					})}
				</ul>
			);
		});

		return (
			<SortableList
				items={activities}
				onSortEnd={onSortEnd}
				axis={'xy'}
				pressDelay={220}
			/>
		);
	};

	const getSortableItem = (key, idx, activity) => {
		const SortableItem = SortableElement(() => (
			<ActivityTile
				utility={true}
				activity={activity}
				// deleteId={deleteId}
				// deleteAnimated={deleteAnimated}
				showActivity={openActivity}
				showEdit={openActivity}
				editMode={editMode}
				editable={true}
				selectActivity={setActivity}
				deleteActivity={deleteActivity}
			/>
		));

		return <SortableItem index={idx} key={key} />;
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		let ids = [];
		const sorted = arrayMove(addedActivities, oldIndex, newIndex).map(
			(act, idx) => {
				ids.push(act.id);
				return setIndices(act, idx);
			}
		);
		handleActivities(sorted, ids);
	};

	const setIndices = (act, idx) => {
		act['order'] = idx;
		return act;
	};

	return (
		<div id="selected-activities-list-container">
			<div className="builder-heading">
				<div className="builder-title">
					<h3 className="bold">{ITINERARY_BUILDER}</h3>
					<FontAwesomeIcon
						icon={faPen}
						size={'lg'}
						className={`${editMode ? 'red' : ''}`}
						onClick={() => toggleEditMode(true)}
					/>
				</div>
				<div className="builder-actions">
					<div>
						<FontAwesomeIcon icon={faBookmark} />
						<span>{SAVE_ITINERARY}</span>
					</div>
					<div onClick={toggleActionsModal}>
						<FontAwesomeIcon icon={faShareAlt} />
						<span>{SHARE_ITINERARY}</span>
					</div>
				</div>
				<div className="builder-info">
					{isEmpty(addedActivities) ? (
						<div className="builder-empty">
							{NO_ACTIVITIES_ADDED_1}
							<FontAwesomeIcon
								icon={faPlus}
								size="xs"
								className="add-activity"
							/>
							{NO_ACTIVITIES_ADDED_2}
						</div>
					) : (
						<div className="builder-time">
							<div>
								{`${toRemainingTime(
									remainingTime
								)} ${HOURS_REMAINING}`}
							</div>
							<p>{TIME_FILL_INDICATION}</p>
						</div>
					)}
				</div>
			</div>
			<div className="activities-container">
				{getSortableList(addedActivities)}
			</div>
		</div>
	);
};

export default SelectedActivitiesList;
