import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'reactstrap';
import { isEmpty } from 'lodash';

import { createActivity } from '../../store/actions/activitiesActions';

import Loader from '../utils/Loader';
import Drawer from './drawer/Drawer';
import ActivityDescription from './activityDescription/ActivityDescription';
import SuggestionCard from './SuggestionCard';

import { cloneActivitySchema } from '../../schemas/Activity';
import { GET_ACTIVITY_SUGGESTIONS } from '../../consts/Queries';
import {
	APP_ERROR,
	EXCEEDED_TIME,
	NO_SUGGESTED_ACTIVITIES_FOUND,
	SUGGESTED_ACTIVITIES,
} from '../../consts/Messages';

import './activitySuggestions.scss';

const PER_DAY_TIME_LIMIT = 8 * 60;

function ActivitySuggestions({ currentDay }) {
	const userId = useSelector((state) => state.firebase.auth.uid);
	const { itinerary } = useSelector((state) => state.itineraries);
	const {
		itineraryActivities,
		timePerDay,
		calculateSuggestions,
		cloneActivityLoading,
	} = useSelector((state) => state.activities);

	const [suggestionOpen, setSuggestionOpen] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState({});
	const [suggestions, setSuggestions] = useState([]);
	const [timeLimitAlert, setTimeLimitAlert] = useState(false);

	const [runQuery, setRunQuery] = useState(false);
	const [queryLoading, setQueryLoading] = useState(false);
	const [queryError, setQueryError] = useState(false);

	const suggestionsQuery = useQuery(GET_ACTIVITY_SUGGESTIONS, {
		variables: {
			city: itinerary ? itinerary.city : '',
			activities: itineraryActivities,
		},
		skip: !runQuery || !itinerary,
		notifyOnNetworkStatusChange: true,
	});

	const dispatch = useDispatch();

	useEffect(() => {
		if (!calculateSuggestions) return;
		setRunQuery(true);
	}, [itineraryActivities, calculateSuggestions]);

	useEffect(() => {
		const { data, loading, error } = suggestionsQuery;
		setQueryLoading(loading);
		setQueryError(error);

		if (data) {
			setSuggestions(data.activitySuggestions);
			setRunQuery(false);
			dismissAlert();
		}
	}, [suggestionsQuery]);

	const showSuggestion = () => {
		setSuggestionOpen(!suggestionOpen);
	};

	const closeSuggestion = () => {
		setSuggestionOpen(false);
	};

	const canAddActivity = (activity) => {
		const duration = activity.duration.total;
		const currentTime = timePerDay[currentDay] || 0;
		return duration + currentTime <= PER_DAY_TIME_LIMIT;
	};

	const addSuggestion = (activity) => {
		if (!canAddActivity(activity)) {
			setTimeLimitAlert(true);
			return;
		}

		const { id, ...rest } = activity;
		const activityClon = {
			...rest,
			originalId: id,
			day: currentDay,
			addedBy: userId,
			id: itinerary.id,
		};
		const activityObj = cloneActivitySchema(activityClon);

		setSelectedActivity(activity);
		dispatch(createActivity(activityObj));
	};

	const addSelectedSuggestion = () => {
		addSuggestion(selectedActivity);
	};

	const dismissAlert = () => setTimeLimitAlert(false);

	const loadingContainer = () => {
		return (
			<div
				className="suggestions-info-container"
				data-testid="loading-spinner"
			>
				<Loader height={250} />
			</div>
		);
	};

	const noSuggestionsContainer = () => {
		return (
			<p className="suggestions-info-container">
				{NO_SUGGESTED_ACTIVITIES_FOUND}
			</p>
		);
	};

	const errorContainer = () => {
		return <p className="suggestions-info-container">{APP_ERROR}</p>;
	};

	return (
		<div id="activity-suggestions-container">
			<h1 className="suggestion-title">{SUGGESTED_ACTIVITIES}</h1>
			<Alert
				color="danger"
				isOpen={timeLimitAlert}
				toggle={dismissAlert}
				style={{ zIndex: 1 }}
			>
				{EXCEEDED_TIME}
			</Alert>
			{queryLoading ? (
				loadingContainer()
			) : queryError ? (
				errorContainer()
			) : isEmpty(suggestions) ? (
				noSuggestionsContainer()
			) : (
				<div className="suggestion-cards-container">
					{suggestions.map((suggestion, idx) => (
						<SuggestionCard
							suggestion={suggestion}
							key={idx}
							showSuggestion={showSuggestion}
							addSuggestion={addSuggestion}
							selectActivity={setSelectedActivity}
							isLoading={
								cloneActivityLoading &&
								suggestion === selectedActivity
							}
						/>
					))}
					<div className="suggestions-right-arrow">
						<FontAwesomeIcon icon={faChevronRight} size="lg" />
					</div>
				</div>
			)}
			{suggestionOpen && (
				<Drawer
					component={ActivityDescription}
					isVisible={suggestionOpen}
					onClose={closeSuggestion}
					addOption={true}
					activityInfo={selectedActivity}
					cloneActivity={addSelectedSuggestion}
				/>
			)}
		</div>
	);
}

export default ActivitySuggestions;
