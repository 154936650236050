import React from 'react';
import {
	Page,
	Text,
	View,
	Document,
	Image,
	StyleSheet,
} from '@react-pdf/renderer';
import { isEmpty } from 'lodash';

import {
	getReceiptDates,
	formatCurrency,
	convertToHours,
	getTimeFormatForActivities,
	getTimeFormatPdf,
	getValidatePdfData,
	getValidateitineraryPdfData,
} from '../../utils/itinerary';
import { groupSizePrice } from '../../../utils/Pricing';

import RkHeader from '../../../assets/pdf-images/Rk-Header.PNG';
import Email from '../../../assets/pdf-images/email.PNG';
import Phone from '../../../assets/pdf-images/phone.PNG';

const ItineraryInvoice = ({ itinerary, activities, booking }) => {
	// Create styles
	const styles = StyleSheet.create({
		page: {
			backgroundColor: '#FFFFFF',
			paddingTop: 35,
			paddingBottom: 65,
			paddingHorizontal: 35,
		},
		section: {
			padding: 10,
			height: 'auto',
			backgroundColor: '#FFFFFF',
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			borderRightColor: '#808080',
			borderRightWidth: 5,
			borderLeftColor: '#808080',
			borderLeftWidth: 5,
		},
		sectionLeft: {
			padding: 10,
			height: 'auto',
			backgroundColor: '#FFFFFF',
			display: 'flex',
			justifyContent: 'flex-start',
			borderRightColor: '#808080',
			borderRightWidth: 5,
			borderLeftColor: '#808080',
			borderLeftWidth: 5,
		},
		lastSection: {
			padding: '20,0 50,0',
			height: 'auto',
			backgroundColor: '#FFFFFF',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			borderBottomRightRadius: 20,
			borderBottomLeftRadius: 20,
			borderRightColor: '#808080',
			borderRightWidth: 5,
			borderLeftColor: '#808080',
			borderLeftWidth: 5,
			borderBottomColor: '#808080',
			borderBottomWidth: 5,
		},
		sectionGrey: {
			backgroundColor: '#CDCDCD',
			padding: 10,
			paddingLeft: 30,
		},
		sectionWhite: {
			backgroundColor: '#FFFFFF',
			padding: 10,
			paddingLeft: 30,
		},
		activitiesSection: {
			height: 'auto',
			backgroundColor: '#FFFFFF',
			display: 'flex',
			width: '100%',
			borderRightColor: '#808080',
			borderRightWidth: 5,
			borderLeftColor: '#808080',
			borderLeftWidth: 5,
		},

		inputsContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			flexDirection: 'row',
		},
		itineraryBox: {
			height: 'auto',
			display: 'flex',
			width: '100%',
		},
		itineraryBoxContent: {
			width: '80%',
			backgroundColor: '#CDCDCD',
		},
		text: {
			fontSize: 30,
		},
		divider: {
			textAlign: 'center',
			marginLeft: 10,
			color: '#CDCDCD',
		},
		textBold: {
			fontSize: 14,
			fontWeight: 500,
			margin: '12,12,0,12',
		},
		textFields: {
			fontSize: 14,
			margin: '12,12,0,12',
		},
		lastSectionField: {
			fontSize: 14,
			margin: '12,12,0,12',
			textAlign: 'center',
		},

		textHeading: {
			fontSize: 24,
			fontWeight: 500,
			textAlign: 'center',
		},
		activityTitle: {
			fontSize: 24,
			fontWeight: 500,
		},
		sectionTitle: {
			fontSize: 20,
			fontWeight: 500,
			textAlign: 'left',
			margin: '12,12,0,12',
		},
		sectionActivitiesTitle: {
			padding: 10,
			fontSize: 20,
			fontWeight: 500,
			textAlign: 'left',
			margin: '12,12,0,12',
		},
		subTitleText: {
			fontSize: 14,
			margin: '12,12,0,12',
			textAlign: 'center',
		},

		subTitleTextLeftBold: {
			fontSize: 14,
			fontWeight: 400,
			margin: 12,
			textAlign: 'left',
		},

		imageHeader: {
			width: 80,
			height: 50,
			display: 'flex',
			marginLeft: '33%',
			padding: 10,
			marginTop: 10,
		},
		imgContainer: {
			width: '100%',
			height: 70,
			display: 'flex',
			justifyContent: 'flex-start',
			backgroundColor: '#808080',
			borderTopRightRadius: 20,
			borderTopLeftRadius: 20,
		},
		imgIcon: {
			width: 20,
			height: 20,
		},
		activityInfoContainer: {
			display: 'flex',
			width: '100%',
			height: 50,
		},
	});

	const getTotalPeoplePrice = () => {
		if (!itinerary || !booking) return;

		const numTravelers =
			booking.bookingCore.numAdults + booking.bookingCore.numChildren;
		const dates = Object.keys(itinerary.days);

		const totalPeoplePrice = groupSizePrice(
			itinerary.city,
			numTravelers,
			dates
		);

		return formatCurrency(totalPeoplePrice);
	};

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View Style={styles.section}>
					<View style={styles.imgContainer}>
						<Image style={styles.imageHeader} src={RkHeader} />
					</View>
				</View>
				<View style={styles.section}>
					<Text style={styles.textHeading}>
						Copy thanking for booking
					</Text>
					<Text style={styles.textHeading}>with Redknot!</Text>
					<Text style={styles.subTitleText}>
						More copys about the booking being succesfull lorem
						ipsum dolos lorem ipsum dolos lorem ipsum dolos lorem
						ipsum dolos
					</Text>
					<Text style={styles.divider}>
						________________________________________________
					</Text>
				</View>
				<View style={styles.sectionLeft}>
					<View>
						<Text style={styles.sectionTitle}>
							Receipt from Redknot
						</Text>

						<View style={styles.inputsContainer}>
							<Text style={styles.textFields}>
								Date of the purchase:
							</Text>
							<Text style={styles.textFields}>
								{getTimeFormatPdf(booking)}
							</Text>
						</View>
						<View style={styles.inputsContainer}>
							<Text style={styles.textFields}>
								ID or confirmation code:
							</Text>
							<Text style={styles.textFields}>
								{getValidatePdfData(booking, 'id')}
							</Text>
						</View>
					</View>
					<Text style={styles.divider}>
						________________________________________________
					</Text>
				</View>
				<View style={styles.sectionLeft}>
					<Text style={styles.sectionTitle}>
						{`Ready for your Adventure in ${getValidateitineraryPdfData(
							itinerary,
							'city'
						)}?`}
					</Text>
					<View style={styles.inputsContainer}>
						<Text style={styles.textFields}>Booking dates</Text>
						<Text style={styles.textFields}>
							{getReceiptDates(itinerary.days)}
						</Text>
					</View>
					<View style={styles.inputsContainer}>
						<Text style={styles.textFields}>Total of People</Text>
						<Text style={styles.textFields}>
							{booking
								? booking.bookingCore.numAdults +
								  booking.bookingCore.numChildren
								: ''}
						</Text>
					</View>
					<View style={styles.inputsContainer}>
						<Text style={styles.textBold}>
							{`With ${getValidateitineraryPdfData(
								itinerary.ambassador,
								'firstName'
							)}`}
						</Text>
					</View>
					<Text style={styles.divider}>
						________________________________________________
					</Text>
				</View>
				<View style={styles.activitiesSection}>
					<Text style={styles.sectionActivitiesTitle}>Itinerary</Text>
					<View style={styles.itineraryBox}>
						{isEmpty(activities) ? (
							<Text style={styles.textFields}>
								ups! It looks like you didnt create an itinerary
								for this trip. Enjoy your trip and remember that
								you can always schedule your activities with
								Redknot
							</Text>
						) : (
							<View>
								{activities.map((data, index) => (
									<View
										key={index}
										style={
											(index + 1) % 2 != 0
												? styles.sectionGrey
												: styles.sectionWhite
										}
									>
										<Text style={styles.textFields}>
											{data.name}
										</Text>
										<Text style={styles.textFields}>
											{data.day}
										</Text>
										<Text style={styles.textFields}>
											{`${getTimeFormatForActivities(
												convertToHours(
													data.duration.total
												)
											)}`}
										</Text>
									</View>
								))}
							</View>
						)}
					</View>
				</View>
				<View style={styles.sectionLeft}>
					<Text style={styles.divider}>
						________________________________________________
					</Text>
					<Text style={styles.sectionTitle}>Price Breakdown</Text>
					{/*<View style={styles.inputsContainer}>
						<Text style={styles.textFields}>Total of Days</Text>
						<Text style={styles.textFields}>
							{totalOfDays(itinerary.days)}
						</Text>
									</View>*/}
					<View style={styles.inputsContainer}>
						<Text style={styles.textFields}>Total of People</Text>
						<Text style={styles.textFields}>
							{getTotalPeoplePrice()}
						</Text>
					</View>
					<View style={styles.inputsContainer}>
						<Text style={styles.textFields}>Another Fee</Text>
						<Text style={styles.textFields}>
							{formatCurrency(0)}
						</Text>
					</View>
					<View style={styles.inputsContainer}>
						<Text style={styles.textFields}>Total USD</Text>
						<Text style={styles.textFields}>
							{booking
								? formatCurrency(booking.bookingCore.price)
								: ''}
						</Text>
					</View>
					<Text style={styles.divider}>
						________________________________________________
					</Text>
				</View>
				<View style={styles.lastSection}>
					<Text style={styles.textHeading}>
						Any doubt? Contact Us!
					</Text>
					<Text style={styles.lastSectionField}>+1 877 959 7544</Text>
					<Text style={styles.lastSectionField}>
						bridges@goredknot.com
					</Text>
				</View>
			</Page>
		</Document>
	);
};

export default ItineraryInvoice;
