import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import './gallery.scss';

const Gallery = ({ images }) => {
	const [galleryOpen, setGallery] = useState(false);
	const [clickedPhoto, setPhoto] = useState(0);

	const openGallery = (photo) => {
		setPhoto(photo);
		setGallery(true);
	};

	const toggleGallery = () => {
		setGallery(!galleryOpen);
	};

	return (
		<>
			<div className="gallery-grid">
				{images.map((pic, idx) => {
					return (
						<div
							className="gallery-image"
							key={idx}
							onClick={(idx) => openGallery(idx)}
							style={{
								backgroundImage: "url('" + pic.url + " ')",
							}}
						></div>
					);
				})}
			</div>

			<ModalGateway>
				{galleryOpen && (
					<Modal onClose={toggleGallery}>
						<Carousel
							views={images.map((photo) => {
								return {
									src: photo.url,
									caption: '',
								};
							})}
							components={{ Footer: null }}
							currentIndex={clickedPhoto}
						/>
					</Modal>
				)}
			</ModalGateway>
		</>
	);
};

export default Gallery;
