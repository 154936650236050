import React from 'react';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';

import { readMore } from '../../utils/Text';
import './aboutBox.scss';

const AboutBox = ({ data, title, icon, maxLength = 280, openModal }) => {
	if (isEmpty(data)) {
		return null;
	}

	return (
		<div className="about-box-container">
			<div className="about-icon-container">
				<img alt={title} src={icon} />
			</div>
			<div className="about-data-container">
				<h5>{title}</h5>
				{readMore(data, maxLength)}
				{data.length > maxLength && (
					<Button
						onClick={() => {
							openModal(title, data);
						}}
						className="read-more-button"
					>
						Read more
					</Button>
				)}
			</div>
		</div>
	);
};

export default AboutBox;
