import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

const SignedOutLinks = ({ toggle, isOpen }) => {
	return (
		<div className="nav-footer" onClick={() => toggle(!isOpen)}>
			<NavItem>
				<Link to="/login">
					<NavLink>Log In</NavLink>
				</Link>
			</NavItem>
			<NavItem>
				<Link to="/signup">
					<NavLink>Sign Up</NavLink>
				</Link>
			</NavItem>
		</div>
	);
};

export default SignedOutLinks;
