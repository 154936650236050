import config from './config';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const { apiKey, projectId, authDomain, storageBucket, backendUrl } = config;

const firebaseConfig = {
	apiKey,
	projectId,
	authDomain,
	storageBucket,
	backendUrl,
	databaseURL: 'https://redknot-staging.firebaseio.com',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
