import React from 'react';
import { Label, Input } from 'reactstrap';
import MultipleDatePicker from 'react-multiple-datepicker';

import ExampleDayEdit from './ExampleDayEdit';
import PicturesEdit from './PicturesEdit';
import PhotoEdit from './PhotoEdit';

import { getEligibleDates } from '../../utils/User';
import './userEditField.scss';

const UserEditField = ({
	uid,
	label,
	type = 'text',
	value,
	onChange,
	handleAvailability,
	handleExampleDay,
	handlePictures,
	handlePhoto,
	required = true,
	options = [],
	placeholder,
	slug,
}) => {
	if (slug === 'exampleDay') {
		return <ExampleDayEdit day={value} onChange={handleExampleDay} />;
	}

	switch (type) {
		case 'textarea':
			return (
				<div className="user-edit-field-input">
					{label && <Label>{label}</Label>}
					<textarea
						id={slug}
						value={value}
						onChange={onChange}
						required={required}
						placeholder={placeholder}
					/>
				</div>
			);

		case 'select':
			return (
				<div className="user-edit-field-input">
					{label && <Label>{label}</Label>}
					<Input
						id={slug}
						type={type}
						value={value}
						onChange={onChange}
						required={required}
						placeholder={placeholder}
					>
						<option />
						{options.map(({ value, label }) => {
							return (
								<option key={label} value={value}>
									{label}
								</option>
							);
						})}
					</Input>
				</div>
			);

		case 'datepicker':
			const { availableDates, unavailableDates } = getEligibleDates(
				value
			);

			return (
				<div className="user-edit-field-input">
					{label && <Label>{label}</Label>}
					<MultipleDatePicker
						selectedDates={availableDates}
						disabledDates={unavailableDates}
						onSubmit={handleAvailability}
					/>
				</div>
			);

		case 'photo':
			return (
				<div className="user-edit-field-input photo-input">
					<PhotoEdit image={value} handleChange={handlePhoto} />
				</div>
			);

		case 'gallery':
			return (
				<div className="user-edit-field-input">
					{label && <Label>{label}</Label>}
					<PicturesEdit
						images={value}
						uid={uid}
						handleChange={handlePictures}
					/>
				</div>
			);

		default:
			return (
				<div className="user-edit-field-input">
					{label && <Label>{label}</Label>}
					<Input
						id={slug}
						type={type}
						value={value}
						onChange={onChange}
						required={required}
						placeholder={placeholder}
					/>
				</div>
			);
	}
};

export default UserEditField;
