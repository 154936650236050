import React from 'react';
import './totalPrice.scss';

const TotalPrice = ({ price, numTravelers = 1 }) => {
	const perPerson = Math.trunc(price / numTravelers) || 0;

	return (
		<div className="total-price">
			<span>
				USD{' $'}
				{price}
			</span>
			<div className="per-person-price">
				(${perPerson} per person) per day
			</div>
		</div>
	);
};

export default TotalPrice;
