import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import ItineraryBuilder from '../itineraryBuilder/ItineraryBuilder';
import ItineraryField from './ItineraryField';

import {
	ITINERARY_DB_FORMAT,
	ITINERARY_DISPLAY_FORMAT,
} from '../../consts/DateFormats';

import './itineraryDay.scss';

const ItineraryDay = ({
	date,
	onClick,
	selected,
	day,
	activities,
	isAmbassador,
	handleChange,
	onBlur,
}) => {
	return (
		<div className={selected ? 'itinerary-day selected' : 'itinerary-day'}>
			<Button
				className="itinerary-day-button"
				active={selected}
				onClick={onClick}
			>
				<div className="day-number">Day {day}</div>
				<div className="day-date">
					{moment(date, ITINERARY_DB_FORMAT).format(
						ITINERARY_DISPLAY_FORMAT
					)}
				</div>
			</Button>
			<div className="day-activities">
				<ItineraryBuilder currentDay={date} />
			</div>
		</div>
	);
};

export default ItineraryDay;
