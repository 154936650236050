import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Input, Row, Col, Alert } from 'reactstrap';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomSwitch from './CustomSwitch';
import Map from '../map/leaflet/Map';

import {
	createActivity,
	updateActivity,
} from '../../../store/actions/activitiesActions';
import RedknotIcon from '../../../assets/icons/Redknot-icon.png';
import UploadPictureIcon from '../../../assets/icons/upload-picture-icon.png';
import { updatedActivitySchema } from '../../../schemas/Activity';
import GalleryHandler from './GalleryHandler';
import BtnLoader from '../../utils/BtnLoader';
import { getDefaultLocation } from '../../../consts/Countries';

import { EXCEEDED_TIME } from '../../../consts/Messages';

import './createActivity.scss';

function CreateActivity({
	currentDay,
	activityInfo,
	close,
	currentAmountOfTime,
}) {
	const [pictures, setPictures] = useState(activityInfo.pictures || []);
	const [initialTime, setInitialTime] = useState([]);
	const [error, setError] = useState('');
	const dispatch = useDispatch();
	const { control, handleSubmit, watch, errors } = useForm({
		defaultValues: {
			equipment: activityInfo.equipment || [],
			extraFees: activityInfo.extraFees || [],
		},
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'equipment',
	});
	const {
		fields: extraFields,
		append: appendExtra,
		remove: removeExtra,
	} = useFieldArray({
		control,
		name: 'extraFees',
	});

	const itineraryId = useSelector((state) => state.itineraries.itinerary.id);
	const { city } = useSelector((state) => state.itineraries.itinerary);
	const { createActivityLoading } = useSelector((state) => state.activities);
	const user = useSelector((state) => state.firebase.profile);
	const driveTimes = useSelector((state) => state.activities.activitiesTime);
	//state for customSwitch

	const [kidFriendly, setKidFriendly] = useState(
		activityInfo.kidFriendly || false
	);

	//state for location

	const { lat = null, lng = null } = activityInfo.location || {};
	const [destination, setDestination] = useState({ lat, lng });
	const [defaultLocation, setDefaultLocation] = useState(
		getDefaultLocation(city)
	);

	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) {
			close();
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	useEffect(() => {
		const watchFields = watch(['activityDuration', 'driveDuration']);
		setInitialTime(watchFields); //get the original values
	}, []);

	const calculateTimeForUpdate = (durationActivity, durationDrive) => {
		let { activityDuration, driveDuration } = initialTime;
		activityDuration = moment.duration(activityDuration).asMinutes();
		driveDuration = moment.duration(driveDuration).asMinutes();
		let tempActivity = durationActivity - activityDuration;
		let tempDrive = durationDrive - driveDuration;
		let tempTotal = tempActivity + tempDrive;
		return tempTotal;
	};

	const onSubmit = (data) => {
		const { uid, firstName } = user;
		let result = {};
		const { activityDuration, driveDuration, ...restData } = data;
		let durationActivity = moment.duration(activityDuration).asMinutes();
		let durationDrive = driveDuration
			? moment.duration(driveDuration).asMinutes()
			: 0;

		const duration = {
			activity: durationActivity,
			drive: durationDrive,
			total: durationActivity + durationDrive,
		};
		let totalTime = isEmpty(activityInfo)
			? currentAmountOfTime + duration.total
			: currentAmountOfTime +
			  calculateTimeForUpdate(durationActivity, durationDrive);

		if (totalTime > 480) {
			setError({ EXCEEDED_TIME });
			return;
		}
		const { mapEmbed = '', address = '' } = activityInfo;

		result = {
			...restData,
			duration,
			day: currentDay,
			categoryIds: [],
			active: true,
			createdBy: {
				uid,
				name: firstName,
			},
			addedBy: uid,
			pictures: pictures || {},
			id: itineraryId,
			equipment: restData.equipment || [],
			extraFees: restData.extraFees || [],
			longDescription: restData.longDescription || '',
			kidFriendly,
			location: {
				...destination,
				mapEmbed,
				address,
			},
		};

		const updateObj = updatedActivitySchema(
			activityInfo.id,
			itineraryId,
			result
		);

		if (isEmpty(activityInfo)) {
			dispatch(createActivity(result));
		} else {
			dispatch(updateActivity(updateObj));
			close();
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	const InputField = (placeholder) => (
		<Input placeholder={placeholder} onKeyDown={handleKeyDown} />
	);
	const InputFieldTime = (placeholder, status) => (
		<TimePicker
			placeholder={placeholder}
			disableClock={true}
			format="H:mm"
			className="custom-time-picker"
			disabled={status}
		/>
	);
	const InputFieldTextArea = (placeholder) => (
		<textarea placeholder={placeholder} rows="4" />
	);

	return (
		<div id="create-activity-container">
			<div className="create-activity-header">
				<img src={RedknotIcon} alt="redknot-logo" />
				<h1 className="create-activity-title">
					{isEmpty(activityInfo)
						? 'Create Activity'
						: 'Edit Activity'}
				</h1>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col xs="12" sm="6">
						<label>Activity Name</label>
						<Controller
							as={InputField('Ex: Exploring San Salvador')}
							name="name"
							control={control}
							defaultValue={activityInfo.name || ''}
							rules={{ required: true }}
						/>
						{errors.name && (
							<p className="form-error">This field is required</p>
						)}
						<label>Description</label>
						<p className="optional">(optional)</p>
						<Controller
							as={InputFieldTextArea('Activity description')}
							name="description"
							control={control}
							defaultValue={activityInfo.description || ''}
						/>
						<label>Extra Fees</label>
						<p className="optional">(optional)</p>
						{extraFields.map((item, index) => (
							<div className="field-array-container">
								<Controller
									as={InputField('Ex: $5 entry fee')}
									name={`extraFees[${index}]`}
									control={control}
									defaultValue={''}
									rules={{ required: true }}
								/>
								<div
									className="container-icon"
									onClick={() => removeExtra(index)}
								>
									<FontAwesomeIcon icon={faTimes} />
								</div>
								{errors.extraFees &&
									errors.extraFees[index] && (
										<p className="form-error">
											This field is required
										</p>
									)}
							</div>
						))}
						<div
							className="field-array-controls"
							onClick={() => appendExtra({ name: 'extraFees' })}
						>
							Add extra fee
							<div className="container-icon">
								<FontAwesomeIcon icon={faPlus} size="sm" />
							</div>
						</div>
					</Col>
					<Col xs="12" sm="6">
						<div className="half-container">
							<div className="half-input">
								<label>Activity Duration</label>
								<Controller
									as={InputFieldTime('Ex: 2 hr', false)}
									name="activityDuration"
									control={control}
									defaultValue={
										activityInfo.duration
											? moment
													.utc(
														moment
															.duration(
																activityInfo
																	.duration
																	.activity,
																'minutes'
															)
															.asMilliseconds()
													)
													.format('HH:mm')
											: ''
									}
									rules={{ required: true }}
								/>
								{errors.activityDuration && (
									<p className="form-error">
										This field is required
									</p>
								)}
							</div>

							<div className="half-input">
								<label>Drive Duration</label>
								<Controller
									as={InputFieldTime('Ex: 1 hr', true)}
									name="driveDuration"
									control={control}
									defaultValue={
										activityInfo.duration
											? moment
													.utc(
														driveTimes[
															activityInfo.id
														] * 1000 || 0
													)
													.format('HH:mm')
											: ''
									}
								/>
							</div>
						</div>
						<label>Location</label>
						<Map
							getCoordinates={setDestination}
							defaultLocation={defaultLocation}
							marker={destination}
						/>
						<label>Equipment</label>
						<p className="optional">(optional)</p>
						{fields.map((item, index) => (
							<div className="field-array-container">
								<Controller
									as={InputField('Ex: Hiking poles')}
									name={`equipment[${index}]`}
									control={control}
									defaultValue={''}
									rules={{ required: true }}
								/>
								<div
									className="container-icon"
									onClick={() => remove(index)}
								>
									<FontAwesomeIcon icon={faTimes} />
								</div>
								{errors.equipment &&
									errors.equipment[index] && (
										<p className="form-error">
											This field is required
										</p>
									)}
							</div>
						))}
						<div
							className="field-array-controls"
							onClick={() => append({ name: 'equipment' })}
						>
							Add equipment
							<div className="container-icon">
								<FontAwesomeIcon icon={faPlus} size="sm" />
							</div>
						</div>
						<GalleryHandler
							id={user.uid}
							path={'/activities-pictures'}
							pictures={pictures || []}
							setPictures={setPictures}
							icon={UploadPictureIcon}
						/>
						<label>Kid Friendly</label>
						<div className="switch-container">
							<div className="input-container">
								<CustomSwitch
									name={'kidFriendly'}
									isOn={kidFriendly}
									handleToggle={() =>
										setKidFriendly(!kidFriendly)
									}
								/>
							</div>
							<div className="switch-description">
								Making this activity Kid Friendly means that
								this experience can be done by children.
							</div>
						</div>
					</Col>
				</Row>
				<Alert color="danger" isOpen={!isEmpty(error)}>
					{EXCEEDED_TIME}
				</Alert>
				<BtnLoader
					loading={createActivityLoading}
					type={'submit'}
					loadingText={''}
					text={
						isEmpty(activityInfo)
							? 'Add to Itinerary'
							: 'Update Activity'
					}
				/>
			</form>
		</div>
	);
}

export default connect()(CreateActivity);
