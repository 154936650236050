import React from 'react';
import { isEmpty } from 'lodash';
import { CITIES } from '../../consts/Countries';
import './step2.scss';

const Step2 = ({ handleChange, country, city }) => {
	const cities = CITIES[country];

	const selectCity = (city) => {
		handleChange({
			name: 'city',
			value: city,
		});
	};

	if (isEmpty(country)) {
		handleChange({
			name: 'step',
			value: 1,
		});
		return null;
	}

	return (
		<div id="step-2" className="step-container">
			<div className="step-title-container">
				<div className="step-number">
					<span>1</span>
				</div>
				<div className="step-title">
					<h2>Tell us where you are traveling</h2>
				</div>
			</div>
			<div className="options-container">
				<div className="city-container">
					{cities.map((item) => {
						return (
							<div
								key={item.value}
								className={`city-box ${
									item.value === city ? 'active' : ''
								}`}
								onClick={() => selectCity(item.value)}
								style={{
									backgroundImage: `url('${item.img}')`,
									height: `calc(90% / ${cities.length}')`,
								}}
							>
								<span>{item.value}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Step2;
