import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import moment from 'moment';
import { isEmpty } from 'lodash';

import {
	fetchBooking,
	updateAvailability,
	updateBookingDetails,
} from '../../store/actions/bookingActions';
import { createItinerary } from '../../store/actions/itinerariesActions';
import { addItineraryActivities } from '../../store/actions/activitiesActions';

import Mailer from '../../utils/Mailer';
import ReactPixel from '../../utils/FbPixel';

import Loader from '../utils/Loader';

import {
	BOOKING_DB_FORMAT,
	ITINERARY_DB_FORMAT,
	ITINERARY_EMAIL_FORMAT,
} from '../../consts/DateFormats';

const Checkout = ({
	match,
	fetchBooking,
	createItinerary,
	updateAvailability,
	updateBookingDetails,
	addItineraryActivities,
}) => {
	useEffect(() => {
		const { id } = match.params;
		fetchBooking(id);
	}, []);

	const { profile } = useSelector((state) => state.firebase);
	const { booking } = useSelector((state) => state.booking);
	const { itinerary } = useSelector((state) => state.itineraries);

	useEffect(() => {
		if (isEmpty(booking)) {
			return;
		}

		const { itineraryId } = booking;
		if (status === 'completed' && !itineraryId) {
			ReactPixel.pageView();
			ReactPixel.fbq('track', 'Purchase');

			const { days, datesString } = getItineraryDays();
			onAmbassadorBooked();
			sendConfirmationEmails(datesString);
			buildItinerary(days);
		}
	}, [booking]);

	useEffect(() => {
		if (isEmpty(itinerary) || !itinerary.id) {
			return;
		}

		const { id, testerActivities } = booking;
		const { bookingId, city, days } = itinerary;

		if (bookingId !== id) {
			return;
		}

		const updateData = {
			id,
			props: {
				itineraryId: itinerary.id,
			},
		};
		updateBookingDetails(updateData);

		// add activities selected in the tester
		if (testerActivities) {
			const dates = Object.keys(days);
			const firstDay = dates[0];

			const activitiesData = {
				itineraryId: itinerary.id,
				activityIds: testerActivities,
				city,
				firstDay,
			};

			// TODO: add a create itinerary w. activities function to make only one query
			addItineraryActivities(activitiesData);
		}
	}, [itinerary]);

	if (isEmpty(booking)) {
		return <Loader />;
	}

	const {
		status,
		dates,
		itineraryId,
		ambassador,
		ambassadorId,
		travelerId,
		agentId,
		city,
	} = booking;
	// const { uid, isAgent } = profile;

	const getItineraryDays = () => {
		let days = {};
		let datesString = '';

		dates.forEach((date, idx) => {
			if (idx === dates.length - 1) {
				datesString += ' and ';
			} else if (idx !== 0) {
				datesString += ', ';
			}

			days[
				moment(date, BOOKING_DB_FORMAT).format(ITINERARY_DB_FORMAT)
			] = {};
			datesString += moment(date, BOOKING_DB_FORMAT)
				.format(ITINERARY_EMAIL_FORMAT)
				.toString();
		});

		return { days, datesString };
	};

	const onAmbassadorBooked = () => {
		const updateData = {
			uid: ambassador.uid,
			dates,
		};

		updateAvailability(updateData);
	};

	const sendConfirmationEmails = (datesString) => {
		Mailer.sendTravelerPurchaseConfirmation(
			profile.firstName,
			profile.email,
			ambassador.firstName,
			city,
			datesString
		);

		Mailer.sendAmbassadorPurchaseConfirmation(
			ambassador.firstName,
			ambassador.email,
			profile.firstName,
			city,
			itineraryId,
			datesString
		);
	};

	const buildItinerary = (days) => {
		// if booking has email, means it was brought by a concierce user
		// if (isAgent) {
		// 	travelerId = travelerId;
		// 	agentId = agentId;
		// } else {
		// 	travelerId = uid;
		// }

		const { id, numAdults, numChildren } = booking;
		const itineraryData = {
			ambassadorId,
			travelerId,
			agentId,
			bookingId: id,
			city,
			days,
			numAdults,
			numChildren,
			ambassador,
		};

		createItinerary(itineraryData);
		// TODO: maybe clearBooking() from store here?;
	};

	if (status !== 'completed') {
		return (
			<div>
				<h1>There was an error processing your payment</h1>
				<h4>
					Please contact us at bridges@goredknot.com to help you with
					this issue
				</h4>
			</div>
		);
	}

	if (!itineraryId) {
		return <Loader />;
	}

	return (
		<div>
			<h1>Purchase Complete</h1>
			<Redirect to={`/trips/${itineraryId}`} />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchBooking: (data) => dispatch(fetchBooking(data)),
		createItinerary: (data) => dispatch(createItinerary(data)),
		updateAvailability: (data) => dispatch(updateAvailability(data)),
		updateBookingDetails: (data) => dispatch(updateBookingDetails(data)),
		addItineraryActivities: (data) =>
			dispatch(addItineraryActivities(data)),
	};
};

export default compose(
	injectStripe,
	connect(null, mapDispatchToProps)
)(Checkout);
