import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { convertToHours, getTimeFormatForActivities } from '../utils/itinerary';

import defaultPicture from '../../assets/WizardForm/wizard-background.jpg';

import './activityTile.scss';

const ActivityTile = ({
	activity,
	deleteId,
	deleteAnimated,
	showActivity,
	showEdit,
	editMode,
	selectActivity,
	deleteActivity,
	utility,
	display = true,
}) => {
	if (!activity || !display) return null;

	const { id, name, duration, pictures } = activity;

	let className =
		id === deleteId && deleteAnimated === false
			? 'activity animate__animated animate__flipOutX'
			: id === deleteId && deleteAnimated === true
			? 'activity hidden'
			: 'activity';
	className += ` ${utility ? 'utility-layout' : ''}`;

	return (
		<div
			id="activity-tile-container"
			className={className}
			onClick={(e) => {
				// TODO: refactor contidion
				if (e.target.tagName === 'DIV') {
					editMode ? showEdit() : showActivity();
					selectActivity(activity);
				}
			}}
		>
			{utility && (
				<img
					alt={'activity'}
					className="activity-img"
					src={pictures.pic1 ? pictures.pic1.url : defaultPicture}
				/>
			)}
			<div className="activity-details-preview">
				<div className="activity-name">{name}</div>
				<div className="activity-time">
					{`${getTimeFormatForActivities(
						convertToHours(duration.total)
					)} `}
				</div>
			</div>
			<div className="activity-details-icon">
				{editMode ? (
					<FontAwesomeIcon
						data-testid="delete-icon"
						icon={faTimes}
						size="sm"
						onClick={() => deleteActivity(id)}
					/>
				) : (
					<FontAwesomeIcon icon={faBars} size="sm" />
				)}
			</div>
		</div>
	);
};

export default ActivityTile;
