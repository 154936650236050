import React from 'react';
import {
	FacebookShareButton,
	FacebookIcon,
	WhatsappShareButton,
	WhatsappIcon,
	TwitterShareButton,
	TwitterIcon,
} from 'react-share';

import './shareButtons.scss';

const ShareButtons = ({ url, text }) => {
	return (
		<div id="share-buttons">
			{text && <div className="share-text">{text}</div>}
			<FacebookShareButton url={url}>
				<FacebookIcon size={32} round={true} />
			</FacebookShareButton>
			<WhatsappShareButton url={url}>
				<WhatsappIcon size={32} round={true} />
			</WhatsappShareButton>
			<TwitterShareButton url={url}>
				<TwitterIcon size={32} round={true} />
			</TwitterShareButton>
		</div>
	);
};

export default ShareButtons;
