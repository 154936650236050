import React from 'react';
import './bookingDetail.scss';

const BookingDetail = ({ label, value, divider = true }) => {
	return (
		<div className={`detail-item ${divider === true && 'bordered'}`}>
			<label>{label}</label>
			<div>{value}</div>
		</div>
	);
};

export default BookingDetail;
