import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import StripeScriptLoader from 'react-stripe-script-loader';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useSelector } from 'react-redux';

import config from './config';
import GoogleTranslate from './components/utils/GoogleTranslate';
import Navigation from './components/layout/Navigation';
import Loader from './components/utils/Loader';

const PrivateRoute = ({ component: Component, title, path }) => {
	const { auth, profile } = useSelector((state) => state.firebase);
	const { isLoaded, isEmpty } = auth;
	// TODO: try firebase custom claims
	const isAuthed = isLoaded && !isEmpty;

	useEffect(() => {
		if (path !== '/chats/:id') {
			window.scrollTo(0, 0);
			// window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [path]);

	return (
		<Route
			path={path}
			render={(props) => {
				if (!isAuthed) {
					return (
						<Redirect
							to={{
								pathname: '/login',
								state: props.history.location.state || null,
							}}
						/>
					);
				}
				// bookings only for trvalers to view
				if (profile.isAmbassador && path.includes('/booking')) {
					return <Redirect to="/profile" />;
				}

				return (
					<div>
						<Navigation {...props}></Navigation>
						<Container>
							<GoogleTranslate />
							{title && <div className="page-title">{title}</div>}
							{path === '/checkout/:id' ? (
								<StripeScriptLoader
									uniqueId="stripe-loader"
									script="https://js.stripe.com/v3/"
									loader={Loader}
								>
									<StripeProvider apiKey={config.stripeKey}>
										<Elements>
											<Component {...props} />
										</Elements>
									</StripeProvider>
								</StripeScriptLoader>
							) : path === '/booking/:id' ? (
								<StripeScriptLoader
									uniqueId="stripe-loader"
									script="https://js.stripe.com/v3/"
									loader={Loader}
								>
									<Component {...props} />
								</StripeScriptLoader>
							) : (
								<Component {...props} />
							)}
						</Container>
					</div>
				);
			}}
		/>
	);
};

export default PrivateRoute;
