import React, { useState, useEffect } from 'react';

import './toast.scss';

const Toast = ({ position, message, isOpen, setIsOpen, time }) => {
	const ToastWithTime = () => {
		setTimeout(() => {
			setIsOpen(false);
		}, time);
	};

	const mainToast = (
		<div className={`notification-container ${position}`}>
			<div className="notification-message">{message}</div>
			{ToastWithTime()}
		</div>
	);

	return <>{isOpen && mainToast}</>;
};

export default Toast;
