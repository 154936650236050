import Solo from '../assets/WizardForm/group/Solo.jpg';
import Couple from '../assets/WizardForm/group/Couple.jpg';
import Family from '../assets/WizardForm/group/Family.jpg';
import Group from '../assets/WizardForm/group/Group.jpg';

export const GROUP_SIZE_OPTIONS = {
	solo: {
		description:
			'A trip out with a RedKnot is a great way to explore a city as a Solo traveler. Your trip will be private to you and take you wherever you want to go. It’s the best way to get to know a city or a region!',
		img: Solo,
		inputs: false,
		adults: 1,
		kids: 0,
	},
	couple: {
		description:
			'RedKnot Ambassadors can plan an experience that accommodates both of your interests, your trip will be private to the two of you!',
		img: Couple,
		inputs: false,
		adults: 2,
		kids: 0,
	},
	family: {
		description:
			'Our RedKnot Ambassadors love traveling with families and can design an experience that your whole family will enjoy. A standard RedKnot experience is great for a family of up to 4. Let us know if your family is larger.',
		img: Family,
		inputs: true,
		adults: 1,
		kids: 0,
	},
	group: {
		description:
			'A RedKnot experience is a great way for you and your group to get to know your destination. A standard RedKnot experience is great for a group of up to 4 although we can accommodate bigger groups if you contact us!',
		img: Group,
		inputs: true,
		adults: 1,
		kids: 0,
	},
};
