import React from 'react';
import GroupSizeBox from './GroupSizeBox';
import { GROUP_SIZE_OPTIONS } from '../../consts/GroupSizeOptions';
import './step3.scss';

const Step3 = ({ handleChange, adults, kids, option }) => {
	const options = Object.keys(GROUP_SIZE_OPTIONS);

	return (
		<div id="step-3" className="step-container">
			<div className="step-title-container">
				<div className="step-number">
					<span>2</span>
				</div>
				<div className="step-title">
					<h2>Number of people going</h2>
				</div>
			</div>
			<div className="options-container">
				<div className="number-people-container">
					{options.map((opt) => {
						const optData = GROUP_SIZE_OPTIONS[opt];
						return (
							<GroupSizeBox
								key={opt}
								option={opt}
								optionData={optData}
								handleChange={handleChange}
								active={option === opt}
								kids={kids}
								adults={adults}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Step3;
