import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { isEmpty } from 'lodash';

import Loader from '../utils/Loader';
import Pagination from '../utils/Pagination';
import SuggestionCard from '../itineraryBuilder/SuggestionCard';

import {
	APP_ERROR,
	EXCEEDED_TIME,
	NO_MATCHING_ACTIVITIES,
} from '../../consts/Messages';

import './suggestedActivitiesList.scss';

const ITEMS_PER_PAGE = 4;

const SuggestedActivitiesList = ({
	activities,
	interests,
	openActivity,
	addActivity,
	setActivity,
	timeLimitAlert,
	dismissAlert,
	loading,
	error,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [firstOfPage, setFirstOfPage] = useState(0);
	const [lastOfPage, setLastOfPage] = useState(ITEMS_PER_PAGE);
	const [pageResults, setPageResults] = useState([]);

	useEffect(() => {
		setCurrentPage(1);
		setFirstOfPage(0);
		setLastOfPage(ITEMS_PER_PAGE);
	}, [interests]);

	useEffect(() => {
		setPageResults(activities.slice(firstOfPage, lastOfPage));
	}, [activities, firstOfPage, lastOfPage]);

	const onPaginationChange = (args) => {
		const { first, last, page } = args;
		setCurrentPage(page);
		setFirstOfPage(first);
		setLastOfPage(last);
	};

	return (
		<div id="suggested-activities-list-container">
			<div className="featured-heading">
				<h3 className="bold">Featured</h3>
			</div>
			<Alert
				color="danger"
				isOpen={timeLimitAlert}
				toggle={dismissAlert}
				style={{ zIndex: 1 }}
			>
				{EXCEEDED_TIME}
			</Alert>
			{loading ? (
				<Loader></Loader>
			) : error ? (
				<p>{APP_ERROR}</p>
			) : isEmpty(activities) ? (
				<p>{NO_MATCHING_ACTIVITIES}</p>
			) : (
				<div className="activities-container">
					{pageResults.map((activity) => (
						<SuggestionCard
							utility={true}
							suggestion={activity}
							isLoading={false}
							showSuggestion={openActivity}
							addSuggestion={addActivity}
							selectActivity={setActivity}
							display={!activity.added}
						/>
					))}
					<Pagination
						length={activities.length}
						itemsPerPage={ITEMS_PER_PAGE}
						currentPage={currentPage}
						handleChange={onPaginationChange}
						showArrows={false}
					/>
				</div>
			)}
		</div>
	);
};

export default SuggestedActivitiesList;
