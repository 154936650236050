import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty, pickBy, intersection } from 'lodash';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import VerifiedBadge from '../utils/VerifiedBadge';
import UserInfoBox from './UserInfoBox';
import InterestsInfoBox from './InterestsInfoBox';
import CardBadge from './CardBadge';

import TotalPrice from '../utils/TotalPrice';
import lang from '../../assets/user/lang.svg';
import prof from '../../assets/user/prof.svg';

import './resultCard.scss';

const gradient = `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)`;

const ResultCard = ({
	currentPage,
	dates,
	result,
	adults,
	kids,
	price,
	index,
	interests,
}) => {
	const {
		photo,
		firstName,
		languages,
		tagline,
		uid,
		categoryScores,
	} = result;

	const getSelectedInterests = () => {
		const selected = pickBy(interests, (interest) => {
			return interest.selected;
		});
		return selected;
	};

	const getTopInterests = (scores) => {
		const keys = Object.keys(scores);
		const sorted = keys.sort((a, b) => {
			return scores[a] - scores[b];
		});
		let top = [];
		// TODO: ensure 32 is a good top mark
		// (if all categories with a score of 32 or higher are considered top)
		const TOP_MARK = 32;

		let score;
		sorted.forEach((cat) => {
			score = scores[cat];
			if (score >= TOP_MARK) top.push(cat);
		});

		return top;
	};

	const getCommonInterests = (categoryScores) => {
		const interests = getSelectedInterests();
		const topInterests = getTopInterests(categoryScores);
		const interestsKeys = Object.keys(interests);
		const common = intersection(topInterests, interestsKeys);
		return common;
	};

	const selectedInterests = getSelectedInterests();
	const didSelectInterests = !isEmpty(selectedInterests);
	const topInterests = getTopInterests(categoryScores);
	const commonInterests = getCommonInterests(categoryScores);
	const hasCommon = !isEmpty(commonInterests);

	let interestsList = topInterests;
	if (didSelectInterests && hasCommon) {
		interestsList = commonInterests;
	}

	return (
		<div className="ambassador-result-card" key={index}>
			{didSelectInterests && index === 0 && currentPage === 1 && (
				<CardBadge icon={faStar} text="TOP MATCH" />
			)}
			<div className="card-photo-container">
				<Link
					to={{
						pathname: `/user/${uid}`,
						search: !isEmpty(dates) ? `?d=${dates}` : '',
						state: {
							adults,
							kids,
						},
					}}
				>
					<div
						className="photo-profile"
						style={{
							backgroundImage: `${gradient}, url('${photo}')`,
						}}
					></div>
				</Link>
			</div>
			<div className="card-info-body">
				<div className="card-info">
					<div className="card-headline">
						<span>{firstName}</span>
						<VerifiedBadge />
					</div>
					<div className="card-data">
						<UserInfoBox
							data={languages}
							img={lang}
							label="languages"
						/>
						<UserInfoBox
							data={tagline}
							img={prof}
							label="headline"
						/>
					</div>
				</div>
				<InterestsInfoBox
					hasCommon={hasCommon}
					userName={firstName}
					list={interestsList}
					interests={interests}
				/>
			</div>
			<div className="card-info-footer">
				<div className="pricing">
					<TotalPrice price={price} numTravelers={adults + kids} />
				</div>
				<Link
					to={{
						pathname: `/user/${uid}`,
						search: !isEmpty(dates) ? `?d=${dates}` : '',
						state: {
							adults,
							kids,
						},
					}}
				>
					<Button className="button-primary">
						Explore with {firstName}
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default ResultCard;
