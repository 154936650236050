import config from '../../config';

const { backendUrl } = config;

export const fetchChat = (data) => {
	return (dispatch, getState) => {
		const { id } = data;

		try {
			fetch(`${backendUrl}/chat/?id=${id}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}).then(async (resp) => {
				const chat = await resp.json();
				// console.log('got chat with redux', chat);
				dispatch({ type: 'GET_CHAT', chat });
			});
		} catch (error) {
			console.error(`Error fetching chat ${id}`, error);
		}
	};
};

export const createMessage = (data) => {
	return (dispatch, getState) => {
		dispatch({ type: 'CREATE_MESSAGE', data });

		const { id } = data;
		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/createMessage`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			});
		} catch (error) {
			console.error(`Error creating chat message ${id}`, error);
		}
	};
};
