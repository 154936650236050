import carAsset from '../assets/user/carIcon.svg';
import suvAsset from '../assets/user/SUV1.png';
import vanAsset from '../assets/user/SUV1.png';

export const VEHICLE_TYPES = {
	car: {
		capacity: 4,
		asset: carAsset,
	},
	suv: {
		capacity: 9,
		asset: suvAsset,
	},
	van: {
		capacity: 14,
		asset: vanAsset,
	},
};
