import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ResultCard from './ResultCard';
import NoResultsActions from './NoResultsActions';
import Pagination from '../utils/Pagination';

import { groupSizePrice } from '../../utils/Pricing';

import './searchResultList.scss';

const SearchResultList = ({ history, location }) => {
	const itemsPerPage = 3;

	const [currentPage, setPage] = useState(1);
	const [firstOfPage, setFirst] = useState(0);
	const [lastOfPage, setLast] = useState(itemsPerPage);

	if (!location.state) {
		return <Redirect to="/new" />;
	}

	const { state, prevpath } = location;
	const { result, dates, adults, kids, interests, city } = state;
	const numTravelers = adults + kids;
	const price = groupSizePrice(city, numTravelers, dates);
	const pageResults = result.slice(firstOfPage, lastOfPage);

	const onPaginationChange = (args) => {
		const { first, last, page } = args;
		setPage(page);
		setFirst(first);
		setLast(last);
	};

	return (
		<div id="result-list-container">
			{!isEmpty(result) ? (
				<div className="result-grid">
					<h4>Explore with one of our {result.length} Ambassadors</h4>
					{pageResults.map((res, index) => {
						return (
							<ResultCard
								adults={adults}
								kids={kids}
								price={price}
								result={res}
								dates={dates}
								interests={interests}
								currentPage={currentPage}
								index={index}
								history={history}
							></ResultCard>
						);
					})}
					<Pagination
						length={result.length}
						itemsPerPage={itemsPerPage}
						currentPage={currentPage}
						handleChange={onPaginationChange}
					/>
				</div>
			) : (
				<NoResultsActions />
			)}
			<div className="step-actions-container">
				<Link
					to={{
						pathname: prevpath,
						state: {
							step: 5,
						},
					}}
				>
					<Button className="button-primary gray">Previous</Button>
				</Link>
				<Link
					to={{
						pathname: prevpath,
						state: {
							step: 1,
						},
					}}
				>
					<Button className="button-primary">Try Again</Button>
				</Link>
			</div>
		</div>
	);
};

export default SearchResultList;
