import React from 'react';
import { Link } from 'react-router-dom';

import UserPhoto from '../utils/UserPhoto';
import Dates from '../utils/Dates';

import { ITINERARY_DISPLAY_FORMAT } from '../../consts/DateFormats';
import { CITIES_MAP } from '../../consts/Countries';

import './tripItem.scss';

const gradient = `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`;

const TripItem = ({ id, dates, photo, city }) => {
	const { country, asset } = CITIES_MAP[city];

	return (
		<Link
			className="trip-item"
			to={`/trips/${id}`}
			style={{ backgroundImage: `${gradient}, url('${asset}')` }}
		>
			<div className="trip-details">
				<div>{city},</div>
				<div>{country}</div>
				<div className="trip-dates">
					<Dates dates={dates} formatTo={ITINERARY_DISPLAY_FORMAT} />
				</div>
			</div>
			<UserPhoto image={photo} border={true}></UserPhoto>
		</Link>
	);
};

export default TripItem;
