import React from 'react';
import { Input } from 'reactstrap';
import { includes } from 'lodash';

import './itineraryField.scss';

const ambassadorEditable = ['info', 'activities'];

const ItineraryField = ({
	isAmbassador,
	label,
	value,
	handleChange,
	slug,
	userName,
	onBlur,
}) => {
	const isEditable = () => {
		let canEdit;
		if (isAmbassador) {
			canEdit = includes(ambassadorEditable, slug);
		} else {
			canEdit = !includes(ambassadorEditable, slug);
		}
		return canEdit;
	};

	return (
		<div className="itinerary-field">
			<label>{label}</label>
			{slug === 'activities' ? (
				<textarea
					id={slug}
					value={value}
					onChange={handleChange}
					onBlur={onBlur}
					disabled={!isEditable()}
				></textarea>
			) : (
				<Input
					id={slug}
					value={value}
					onChange={handleChange}
					label={label}
					disabled={!isEditable()}
					onBlur={onBlur}
				></Input>
			)}
			{/* {!isEditable() && (
				<div>{userName} will fill out this information</div>
			)} */}
		</div>
	);
};

export default ItineraryField;
