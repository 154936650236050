import React, { useState } from 'react';
import { Label, Input } from 'reactstrap';
import './exampleDayEdit.scss';

const ExampleDayEdit = ({ day, onChange }) => {
	const [hours, setHours] = useState(
		day ? Object.keys(day) : ['', '', '', '', '']
	);
	const [activities, setActivities] = useState(
		day ? Object.values(day) : ['', '', '', '', '']
	);

	const handleTimeChange = (e) => {
		const { id, value } = e.target;
		let tmp = Object.assign([], hours);
		tmp[id] = value;
		setHours(tmp);
		onChange(tmp, activities);
	};

	const handleActivityChange = (e) => {
		const { id, value } = e.target;
		let tmp = Object.assign([], activities);
		tmp[id] = value;
		setActivities(tmp);
		onChange(hours, tmp);
	};

	return (
		<div className="user-edit-field-input">
			<Label>What a day with you would look like</Label>
			<div className="d-flex flex-column">
				{hours.map((time, idx) => {
					return (
						<div className="example-day-entry">
							<Input
								id={idx}
								type="time"
								value={time}
								placeholder="00:00"
								// onBlur={handleChange}
								onChange={handleTimeChange}
								required
							></Input>
							<Input
								id={idx}
								type="text"
								value={activities[idx]}
								placeholder="Activity"
								// onBlur={handleChange}
								onChange={handleActivityChange}
								required
							></Input>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ExampleDayEdit;
