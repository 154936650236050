import React, { useState, useEffect } from 'react';
import { Input, InputGroupAddon, InputGroup, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';

import {
	emailAuth,
	googleAuth,
	fbAuth,
	resetPassword,
	resetError,
} from '../../store/actions/authActions';

import AlertMsg from '../utils/Alert';
import BtnLoader from '../utils/BtnLoader';

import google from '../../assets/auth/google-01-min.png';
import facebook from '../../assets/auth/facebook.png';

import './auth.scss';

const SignIn = ({
	emailAuth,
	googleAuth,
	fbAuth,
	resetPassword,
	resetError,
	signInError,
	history,
}) => {
	const [requestLoading, setLoading] = useState(false);
	const [errorData, setError] = useState('');
	const [isAlertOpen, setAlert] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { state } = history.location;

	useEffect(() => {
		setError(signInError);
	}, [signInError]);

	useEffect(() => {
		setAlert(!!errorData);
		setLoading(!errorData && !areFieldsEmpty());
	}, [errorData]);

	const areFieldsEmpty = () => {
		return isEmpty(email) && isEmpty(password);
	};

	const handleEmail = (e) => {
		const { value } = e.target;
		setEmail(value);
	};

	const handlePassword = (e) => {
		const { value } = e.target;
		setPassword(value);
	};

	const onKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleEmailAuth(e);
		}
	};

	const dismissAlert = () => {
		resetError();
	};

	const handleEmailAuth = (event) => {
		event.preventDefault();

		if (email.length < 4) {
			setError('Please enter an email address.');
			return;
		}

		if (password.length < 4) {
			setError('Please enter a password.');
			return;
		}

		resetError();
		setLoading(true);
		emailAuth({ email, password });
	};

	const handlePasswordReset = () => {
		if (email.length < 4) {
			setError('Please enter an email address.');
			return;
		}
		resetPassword(email);
	};

	return (
		<div id="auth-container">
			<AlertMsg
				content={errorData}
				dismiss={dismissAlert}
				isOpen={isAlertOpen}
			/>
			<div>
				<Button className="auth-provider-button" onClick={googleAuth}>
					<img alt="google-icon" src={google} />
					Log In with Google
				</Button>
				<Button className="auth-provider-button" onClick={fbAuth}>
					<img alt="facebook-icon" src={facebook} />
					Log In with Facebook
				</Button>
				<div className="auth-divider">
					<span className="auth-divider-line">
						<hr />
					</span>
					<span className="auth-divider-o">O</span>
					<span className="auth-divider-line">
						<hr />
					</span>
				</div>
				<div className="auth-fields">
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<FontAwesomeIcon icon={faEnvelope} />
						</InputGroupAddon>
						<Input
							className="form-input"
							placeholder="Email"
							onChange={handleEmail}
							onKeyPress={onKeyPress}
						/>
					</InputGroup>
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<FontAwesomeIcon icon={faLock} />
						</InputGroupAddon>
						<Input
							className="form-input"
							type="password"
							placeholder="Password"
							onChange={handlePassword}
							onKeyPress={onKeyPress}
						/>
					</InputGroup>
				</div>
			</div>
			<div className="auth-actions">
				<BtnLoader
					loading={requestLoading}
					text="Log In"
					onClick={handleEmailAuth}
				/>
				<Link
					onClick={resetError}
					to={{
						// pass the bookingId to signup to be able to redirect the user afterwards
						pathname: '/signup',
						state: {
							bookingId: state ? state.bookingId : null,
						},
					}}
				>
					<Button className="button-primary outlined">
						Register{' '}
						<span role="img" aria-label="point-left-emoji">
							👈
						</span>
					</Button>
				</Link>
				<div className="other-actions">
					<span>Help! </span>
					<strong>
						<u onClick={handlePasswordReset}>
							I forgot my password
						</u>
					</strong>
					<span role="img" aria-label="sweat-laugh-emoji">
						😅
					</span>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { signInError } = state.auth;
	return { signInError };
};

const mapDispatchToProps = (dispatch) => {
	return {
		emailAuth: (data) => dispatch(emailAuth(data)),
		googleAuth: (data) => dispatch(googleAuth(data)),
		fbAuth: (data) => dispatch(fbAuth(data)),
		resetPassword: (data) => dispatch(resetPassword(data)),
		resetError: (data) => dispatch(resetError(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
