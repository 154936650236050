import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import {
	updateItineraryDetails,
	getItineraryFromGql,
} from '../../store/actions/itinerariesActions';
import {
	getActivities,
	getActivitiesCoordinates,
} from '../../store/actions/activitiesActions';
import Map from '../itineraryBuilder/map/leaflet/MapDriveTime'; // hide map
import Loader from '../utils/Loader';
import AlertMsg from '../utils/Alert';
import Hero from '../utils/Hero';
import ItineraryDay from './ItineraryDay';
import ItineraryUserBar from './ItineraryUserBar';
import ItineraryField from './ItineraryField';
import ActivitySuggestions from '../itineraryBuilder/ActivitySuggestions';

import { CITIES_MAP } from '../../consts/Countries';
import { GET_TRIP } from '../../consts/Queries';

import './itinerary.scss';

const Trip = ({
	match,
	updateItineraryDetails,
	getItineraryFromGql,
	getActivities,
	getActivitiesCoordinates,
}) => {
	const requestedId = match.params.id;
	const { profile } = useSelector((state) => state.firebase);
	let itinerary = {};

	const [isAlertOpen, setAlert] = useState(false);
	const [selectedDate, setDate] = useState(null);
	const [day, setDay] = useState({});
	const { itineraryActivities } = useSelector((state) => state.activities);

	const { loading, error, data } = useQuery(GET_TRIP, {
		variables: { id: requestedId },
		fetchPolicy: 'network-only',
	});

	if (!loading && !error && data) {
		itinerary = data.trip;
	}

	useEffect(() => {
		if (!data) return;
		getItineraryFromGql(data.trip);
		getActivities(data.trip.activities ? data.trip.activities : {});
		const { days } = itinerary;
		if (!selectedDate) {
			// HACK: reverse to maintain dates order (object.keys messes it up)
			const firstDate = Object.keys(days).reverse()[0];
			setDate(firstDate);
			setDay(days[firstDate]);
			/*getActivitiesCoordinates(
				data.trip.activities ? data.trip.activities : {},
				firstDate
			);*/
		} else {
			/*getActivitiesCoordinates(
				data.trip.activities ? data.trip.activities : {},
				firstDate
			);*/
			setDay(days[selectedDate]);
		}
	}, [data]);

	useEffect(() => {
		getActivitiesCoordinates(
			itineraryActivities ? itineraryActivities : {},
			selectedDate
		);
	}, [itineraryActivities]);

	if (isEmpty(itinerary)) {
		return <Loader />;
	}

	const { ambassador, traveler, days, city, hasPassed, chatId } = itinerary;
	const dates = Object.keys(days).reverse();

	const { isAmbassador } = profile;
	const user = !isAmbassador ? ambassador : traveler;
	const { firstName } = user;

	const { meeting_time, meeting_point, info, activities } = day;
	const { country, asset } = CITIES_MAP[city];

	const selectDate = (date) => {
		setDate(date);
		setDay(days[date]);
		getActivitiesCoordinates(
			itineraryActivities ? itineraryActivities : {},
			date
		);
	};

	const handleChange = (e) => {
		const { id, value } = e.target;
		const tmp = {
			...day,
			[id]: value,
		};
		setDay(tmp);
	};

	const updateItinerary = () => {
		const updateData = {
			id: itinerary.id,
			props: {
				days: {
					...days,
					[selectedDate]: day,
				},
			},
		};

		updateItineraryDetails(updateData);
	};

	return (
		<div id="itinerary-container">
			<div className="hide-map">
				<Map />
			</div>
			<Hero
				size={165}
				image={asset}
				title={city + ', '}
				subtitle={country}
				fontSize={24}
				alignItems="flex-end"
				justifyContent="flex-start"
				position="top"
				textAlign="left"
			></Hero>
			<div className="section-container two-column-section">
				<AlertMsg
					color="success"
					content="Itinerary successfully saved"
					isOpen={isAlertOpen}
				></AlertMsg>
				<div className="section-side left-side">
					<ItineraryUserBar
						isAmbassador={isAmbassador}
						user={user}
						chatId={chatId}
					/>
					<hr></hr>
					<div className="itinerary-main-info">
						<div className="itinerary-container">
							<ItineraryField
								isAmbassador={isAmbassador}
								userName={firstName}
								label="Meeting Point"
								value={meeting_point}
								slug="meeting_point"
								handleChange={handleChange}
								onBlur={updateItinerary}
							/>
							<ItineraryField
								isAmbassador={isAmbassador}
								userName={firstName}
								label="Meeting Time"
								value={meeting_time}
								slug="meeting_time"
								handleChange={handleChange}
								onBlur={updateItinerary}
							/>
						</div>
					</div>
					<hr></hr>
					<ActivitySuggestions currentDay={selectedDate} />
				</div>
				<div className="itinerary-days section-side right-side">
					<div className="itinerary-days-container">
						{dates.map((date, idx) => (
							<ItineraryDay
								key={date}
								isAmbassador={isAmbassador}
								date={date}
								onClick={() => selectDate(date)}
								selected={date === selectedDate}
								day={idx + 1}
								activities={activities}
								disabled={hasPassed}
								handleChange={handleChange}
								onBlur={updateItinerary}
							></ItineraryDay>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateItineraryDetails: (data) =>
			dispatch(updateItineraryDetails(data)),
		getItineraryFromGql: (data) => dispatch(getItineraryFromGql(data)),
		getActivities: (data) => dispatch(getActivities(data)),
		getActivitiesCoordinates: (data, day) =>
			dispatch(getActivitiesCoordinates(data, day)),
	};
};

export default connect(null, mapDispatchToProps)(Trip);
