import React from 'react';
import defaultPicture from '../../assets/WizardForm/wizard-background.jpg';
import { INTERESTS, INTERESTS_CATEGORIES } from '../../consts/Interests';
import {
	INTERESTS_POPUP_TITLE,
	INTERESTS_POPUP_INTRO,
} from '../../consts/Messages';
import './interestsByCategory.scss';

const InterestsByCategory = ({ interests, onInterestClick }) => {
	const getCategoryInterests = (cat) => {
		const interestIds = Object.keys(INTERESTS);
		return interestIds.filter((id) => INTERESTS[id].category === cat);
	};

	const isInterestSelected = (id) => {
		return interests.includes(id);
	};

	return (
		<div
			id="interests-columns-container"
			style={{ backgroundImage: `url(${defaultPicture})` }}
		>
			<div className="interests-columns-header">
				<div className="interests-columns-title">
					{INTERESTS_POPUP_TITLE}
				</div>
				<div className="interests-column-intro">
					{INTERESTS_POPUP_INTRO}
				</div>
			</div>
			<div className="interests-columns">
				{INTERESTS_CATEGORIES.map(({ name, icon }) => (
					<div className="interests-column">
						<img src={icon} alt={`${name} icon`}></img>
						<div className="category-title">{name}</div>
						<ul className="interests-list">
							{getCategoryInterests(name).map((id, idx) => (
								<li className="interest-container">
									<input
										id={`interest-${id}-checkbox`}
										key={idx}
										name={INTERESTS[id].name}
										checked={isInterestSelected(id)}
										type="checkbox"
										onChange={() => onInterestClick(id)}
									/>
									<label for={`interest-${id}-checkbox`}>
										{INTERESTS[id].name}
									</label>
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		</div>
	);
};

export default InterestsByCategory;
