import React from 'react';
import { Switch } from 'react-router-dom';

import config from './config';
import routes from './routes';
import ReactPixel from './utils/FbPixel';

import './app.scss';
import './partials/_variables.scss';
import './partials/_overrides.scss';
import './partials/_layout.scss';
import './partials/_section.scss';
import './partials/_elements.scss';
import './partials/_buttons.scss';
import './partials/_inputs.scss';
import './partials/_texts.scss';
import './partials/_icons.scss';

const App = () => {
	ReactPixel.init(config.fbPixel, {}, { debug: true, autoConfig: false });

	return (
		<div className="App">
			<Switch>
				{routes.map((route) => {
					const { path, component, title, exact } = route;
					return (
						<route.type
							// key={path}
							exact={exact}
							path={path}
							component={component}
							title={title}
						/>
					);
				})}
			</Switch>
		</div>
	);
};

export default App;
