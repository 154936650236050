import moment from 'moment';
import {
	AVAILABILITY_DB_FORMAT,
	DATEPICKER_FORMAT,
} from '../consts/DateFormats';

export const getUserProfileName = (email) => {
	return (email = email.match(/^([^@]*)@/)[1]);
};

export const getEligibleDates = (availability) => {
	const dates = Object.keys(availability);
	const today = new Date().setHours(0, 0, 0, 0);

	let availableDates = [];
	let unavailableDates = [];

	let formattedDate;
	dates.forEach((date) => {
		formattedDate = new Date(
			moment(date, AVAILABILITY_DB_FORMAT).format(DATEPICKER_FORMAT)
		);

		if (availability[date]) {
			availableDates.push(formattedDate);
		}

		if (formattedDate.getTime() < today) {
			unavailableDates.push(formattedDate);
		}
	});

	// TODO: return them ordered from the API
	// reverse to show them in desc order
	return {
		availableDates: availableDates.reverse(),
		unavailableDates: unavailableDates.reverse(),
	};
};

// TODO: reuse getEligibleDates code
export const getAvailability = (availability) => {
	const userDates = availability || [];
	const dateKeys = Object.keys(availability || []);
	let availableDates = [];
	let unavailableDates = [];
	let formattedDate;
	dateKeys.forEach((date) => {
		// TODO: refactor
		formattedDate = new Date(
			moment(date, AVAILABILITY_DB_FORMAT).format(DATEPICKER_FORMAT)
		);
		userDates[date]
			? availableDates.push(formattedDate.getTime())
			: unavailableDates.push(formattedDate);
	});

	const maxDate = new Date(Math.max.apply(null, availableDates));
	const minDate = new Date(Math.min.apply(null, availableDates));
	const nullDates = getNullDates(minDate, maxDate);

	unavailableDates = nullDates.filter((date) => {
		return !availableDates.includes(date.getTime());
	});

	return { availableDates, unavailableDates };
};

export const getNullDates = (startDate, endDate) => {
	const dates = [];
	let currentDate = new Date(
		startDate.getFullYear(),
		startDate.getMonth(),
		startDate.getDate()
	);

	while (currentDate <= endDate) {
		dates.push(currentDate);

		currentDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate() + 1
		);
	}

	return dates;
};
