import React from 'react';
import { VEHICLE_TYPES } from '../../consts/Vechicles';
import './vehicleBox.scss';

const VehicleBox = ({ type }) => {
	const { capacity, asset } = VEHICLE_TYPES[type];

	return (
		<div className="vehicle-container">
			<div className="vehicle-box-title">Vehicle</div>
			<div className="vehicle-image-container">
				<img src={asset} alt="car" />
			</div>
			<div className="vehicle-details">
				<div>{type}</div>
				<div>(max {capacity} people)</div>
			</div>
		</div>
	);
};

export default VehicleBox;
