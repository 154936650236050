import { gql } from '@apollo/client';
import { pickBy } from 'lodash';
import { toMoment } from '../utils/Dates';
import { AVAILABILITY_DB_FORMAT } from './DateFormats';

export const GET_MATCH_USERS = gql`
	query matchUsersQuery(
		$carTypes: [String]
		$availability: [String]
		$categories: [String]
		$activities: [String]
		$city: String
	) {
		matchUsers(
			CarType: $carTypes
			Dates: $availability
			Interests: $categories
			Activities: $activities
			City: $city
		) {
			uid
			firstName
			bio
			carType
			photo
			city
			languages
			tagline
			rkVerified
			categoryScores
			tariff
		}
	}
`;

export const GET_ALL_USERS = gql`
	query allUsersQuery {
		allUsers {
			uid
			firstName
			email
			isAmbassador
		}
	}
`;

export const GET_TRIP = gql`
	query Trip($id: String) {
		trip(id: $id) {
			id
			chatId
			city
			days
			ambassador
			traveler
			hasPassed
			bookingId
			activities {
				id
				active
				addedBy
				createdBy
				day
				description
				duration
				name
				pictures
				categoryIds
				approved
				originalId
				order
				equipment
				extraFees
				mapEmbed
				kidFriendly
				longDescription
				location
			}
		}
	}
`;

export const GET_ACTIVITY_SUGGESTIONS = gql`
	query ActivitySuggestions($city: String, $activities: JSON) {
		activitySuggestions(city: $city, activities: $activities) {
			id
			categoryIds
			createdBy
			description
			duration
			published
			name
			pictures
			status
			equipment
			extraFees
			mapEmbed
			kidFriendly
			longDescription
			location
		}
	}
`;

export const GET_ALL_TRIPS = gql`
	query allTripsQuery {
		allTrips {
			id
			chatId
			city
			days
			ambassadorId
			travelerId
			ambassadorName
		}
	}
`;

export const TRIP_QUERY = async (args, client) => {
	const { id } = args;

	const queryResult = await client.query({
		query: GET_TRIP,
		variables: { id },
	});

	return queryResult.data.trip;
};

export const getMatchQueryParams = (args) => {
	const { city, dates, adults, kids, interests, activities } = args;
	const numTravelers = parseInt(adults) + parseInt(kids);
	// const capacity = numTravelers;

	const selectedInterests = pickBy(interests, (interest) => {
		return interest.selected;
	});
	const categories = Object.keys(selectedInterests);

	let carTypes = [];
	if (0 < numTravelers && numTravelers < 5) carTypes.push('car');
	if (0 < numTravelers && numTravelers < 10) carTypes.push('suv');
	if (0 < numTravelers && numTravelers < 15) carTypes.push('van');

	const availability = toMoment(dates, AVAILABILITY_DB_FORMAT);

	const params = {
		carTypes,
		// capacity,
		availability,
		categories,
		city,
		activities,
	};

	return params;
};

export const AMBASSADOR_SEARCH = async (args, client) => {
	const variables = getMatchQueryParams(args);
	const queryResult = await client.query({
		query: GET_MATCH_USERS,
		variables,
	});
	const searchResult = queryResult.data.matchUsers;
	return searchResult;
};

export const GET_BOOKING_CORE = gql`
	query bookingCore($id: String) {
		bookingCore(id: $id) {
			id
			price
			numAdults
			numChildren
			completedOn
		}
	}
`;
