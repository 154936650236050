import React from 'react';
import { useSelector } from 'react-redux';

import InterestsGrid from './InterestsGrid';
import './step5.scss';

const Step5 = ({ selectedInterests, handleChange }) => {
	const { interests } = useSelector((state) => state.firestore.data);

	return (
		<div id="step-5" className="step-container">
			<div className="step-title-container">
				<div className="step-number">
					<span>4</span>
				</div>
				<div className="step-title">
					<h2>Let us know more about your interests!</h2>
				</div>
			</div>
			<InterestsGrid
				handleChange={handleChange}
				interests={interests}
				selectedInterests={selectedInterests}
			/>
		</div>
	);
};

export default Step5;
