import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import UserPhoto from '../utils/UserPhoto';
import { getCountryByCity } from '../../consts/Countries';

import './profileShortInfo.scss';

const ProfileShortInfo = ({ ambassadorId, setCountry, setCity }) => {
	useFirestoreConnect([
		{
			collection: 'users',
			doc: ambassadorId,
			storeAs: 'ambassador',
		},
	]);

	const { ambassador } = useSelector((state) => state.firestore.data);

	useEffect(() => {
		if (isEmpty(ambassador)) return;

		const { city } = ambassador;
		setCountry(getCountryByCity(city));
		setCity(city);
	}, [ambassador]);

	if (!ambassador) return null;

	const { photo, firstName } = ambassador;

	return (
		<div id="ambassador-short-info-container">
			<UserPhoto size={116} image={photo}></UserPhoto>
			<span>{firstName}</span>
		</div>
	);
};

export default ProfileShortInfo;
