import React from 'react';
import { Spinner } from 'reactstrap';
import './activityLoader.scss';

const ActivityLoader = () => {
	return (
		<div id="activity-loader-container">
			<Spinner />
		</div>
	);
};

export default ActivityLoader;
