import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import upArrow from '../../assets/icons/up-arrow.png';
import downArrow from '../../assets/icons/down-arrow.png';

export const validateProps = (value) => {
	let status = isEmpty(value) ? 'No information...' : value;
	return status;
};

export const convertToHours = (date) => {
	return moment
		.utc(moment.duration(date, 'minutes').asMilliseconds())
		.format('HH:mm');
};

export const toRemainingTime = (mins) => {
	const hr = Math.floor(mins / 60);
	const min = mins % 60;
	let time = hr > 0 ? hr : min > 0 ? '' : hr;
	time += min > 0 && hr > 0 ? ` hr` : '';
	time += min > 0 ? ` ${min} min` : '';
	return time;
};

export const getTimeFormatForActivities = (time) => {
	let hours = time.slice(0, time.indexOf(':'));
	let minutes = time.split(':')[1];
	hours = hours > 0 ? `${parseInt(hours)} hr` : '';
	minutes = minutes > 0 ? `${minutes} min` : '';
	return `${hours} ${minutes} `;
};

export const getTimeFormatProgressBar = (time) => {
	let hours = time.slice(0, time.indexOf(':'));
	let minutes = time.split(':')[1];
	hours = hours > 0 ? `${parseInt(hours)}` : '0';
	minutes = minutes > 0 ? `:${minutes}` : '';
	return `${hours}${minutes}`;
};

export const getDayActivities = (day, itineraryActivities) => {
	return itineraryActivities.filter((act) => act.day === day).sort(compare);
};

export const compare = (a, b) => {
	if (a.order > b.order) return 1;
	if (b.order > a.order) return -1;
	return 0;
};

export const validateArrayProps = (value) => {
	let data = isEmpty(value) ? (
		'No information...'
	) : (
		<ul>
			{value.map((data, key) => (
				<li key={key}>{data}</li>
			))}
		</ul>
	);

	return data;
};

export const getReceiptDates = (value) => {
	let data = isEmpty(value) ? ' ' : Object.keys(value).toString();
	return data;
};

export const getTotalOfDays = (value) => {
	let data = isEmpty(value) ? ' ' : Object.keys(value).length;
	return data;
};

export const formatCurrency = (value) => {
	value = value.toFixed(2);
	return `$ ${value}`;
};

export const getTimeFormatPdf = (value) => {
	return value
		? new Date(value.bookingCore.completedOn._seconds * 1000)
				.toISOString()
				.slice(0, 10)
		: '';
};

export const getValidatePdfData = (value, key) => {
	return value ? value.bookingCore[key] : '';
};

export const getValidateitineraryPdfData = (value, key) => {
	return value ? value[key] : '';
};

export const getDescription = (
	description,
	longDescription,
	status,
	setStatus
) => {
	let defaultMessage = 'No information....';
	let image = (
		<img
			src={status ? upArrow : downArrow}
			alt="arrow"
			className="arrow-see-more"
		/>
	);

	let text = status ? 'See Less' : 'See More';
	let fullDescription = (
		<>
			{description}
			<br /> {longDescription}
		</>
	);

	if (!isEmpty(description)) {
		return (
			<>
				<p>{status ? fullDescription : description}</p>
				<p
					onClick={() => setStatus(!status)}
					className="description-see-more"
				>
					{!isEmpty(longDescription) ? text : null}
					{!isEmpty(longDescription) ? image : null}
				</p>
			</>
		);
	} else {
		return defaultMessage;
	}
};

export const getGoogleMap = (mapEmbed, location) => {
	if (!isEmpty(mapEmbed)) {
		return (
			<iframe
				src={mapEmbed}
				style={{
					width: 450,
					height: 182,
					frameborder: 0,
					allowfullscreen: '',
					ariaHidden: false,
					tabIndex: 0,
					marginTop: 20,
					marginBottom: 20,
				}}
			/>
		);
	} else if (location.lat) {
		let customMapUrl = `https://maps.google.com/maps?q=${location.lat}, ${location.lng}&z=15&output=embed
		`;

		return (
			<iframe
				src={customMapUrl}
				style={{
					width: 450,
					height: 182,
					frameborder: 0,
					allowfullscreen: '',
					ariaHidden: false,
					tabIndex: 0,
					marginTop: 20,
					marginBottom: 20,
				}}
			/>
		);
	}
};
