const initState = {
	itinerary: null,
	itineraries: null,
};

const itinerariesReducer = (state = initState, action) => {
	let itinerary;

	switch (action.type) {
		case 'GET_ITINERARIES':
			const { itineraries } = action;
			return {
				...state,
				itineraries,
			};

		case 'GET_ITINERARY':
			({ itinerary } = action);
			return {
				...state,
				itinerary,
			};

		case 'GET_ITINERARY_ERROR':
			// console.error('error getting itinerary with gql');
			return state;

		case 'CREATE_ITINERARY':
			itinerary = action.data;
			return {
				...state,
				itinerary,
			};

		case 'UPDATE_ITINERARY_DATA':
			const { props } = action.data;

			return {
				...state,
				itinerary: Object.assign({}, state.itinerary, props),
			};

		case 'CREATE_CHAT':
			const { chat } = action;
			return {
				...state,
				itinerary: {
					...itinerary,
					chat,
				},
			};
		case 'GET_ITINERARY_FROM_GQL':
			itinerary = action.data;
			return {
				...state,
				itinerary,
			};
		default:
			return state;
	}
};

export default itinerariesReducer;
