import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './drawer.scss';

const Drawer = ({ component: Component, isVisible, onClose, ...rest }) => {
	const html = document.documentElement;

	useEffect(() => {
		isVisible ? open() : close();
	}, [isVisible]);

	const close = () => {
		onClose();
		html.classList.remove('active');
	};

	const open = () => {
		html.classList.add('active');
	};

	return (
		<div className="header">
			<div className="header--nav">
				<div className="close-menu" onClick={close}>
					<FontAwesomeIcon icon={faTimesCircle} size="sm" />
				</div>
				<Component {...rest} close={close} />
			</div>
		</div>
	);
};

export default Drawer;

Drawer.defaultProps = {
	isVisible: false,
};
