import React, { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { remove } from 'lodash';
import arrayMove from 'array-move';

// import { isExtValid } from '../../utils/Validation';
import { LIFESTYLE_PICS_STORAGE_REF } from '../../consts/Storage';

import './picturesEdit.scss';

const PicturesEdit = ({ images = [], uid, handleChange }) => {
	const [progress, setProgress] = useState(0);
	// const [photoValid, setPhotoValid] = useState(false);

	useEffect(() => {
		if (progress === 100) {
			setProgress(0);
		}
	}, [images]);

	const getSortableList = () => {
		const SortableList = SortableContainer(({ items }) => {
			if (!items) {
				return null;
			}
			return (
				<ul>
					{items.map((value, idx) => {
						const key = `sortable-item-${idx}`;
						return getSortableItem(key, idx, value);
					})}
				</ul>
			);
		});

		return (
			<SortableList items={images} onSortEnd={onSortEnd} axis={'xy'} />
		);
	};

	const getSortableItem = (key, idx, value) => {
		const { url, filename } = value;

		const SortableItem = SortableElement(() => (
			<div
				className="sortable-picture"
				style={{ backgroundImage: `url('${url}')` }}
			>
				<span>
					<FontAwesomeIcon
						icon={faMinusCircle}
						size={'2x'}
						// TODO: click doesnt work
						onMouseDown={() => {
							deletePicture(filename);
						}}
					/>
				</span>
			</div>
		));

		return <SortableItem key={key} index={idx} value={value} />;
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const pictures = arrayMove(images, oldIndex, newIndex);
		handleChange(pictures);
	};

	const deletePicture = (filename) => {
		const pictures = images;
		LIFESTYLE_PICS_STORAGE_REF.child(filename)
			.delete()
			.then(() => {
				remove(pictures, (pic) => {
					return pic.filename === filename;
				});
				handleChange(pictures);
			});
	};

	const handleUploadStart = (file) => {
		// TODO: handle unsuccessful case
		// - when invalid extension
		// - when size too large
	};

	const handleUploadSuccess = (filename) => {
		LIFESTYLE_PICS_STORAGE_REF.child(filename)
			.getDownloadURL()
			.then((url) => {
				const pictures = [...images, { url, filename }];
				handleChange(pictures);
			});
	};

	const handleProgress = (progress) => {
		setProgress(progress);
	};

	const buildFilename = (filename) => {
		return `${uid}-${filename.split('.')[0]}`;
	};

	return (
		<>
			<div className="pictures-container">{getSortableList()}</div>
			<div className="file-uploader">
				<FileUploader
					accept="image/*"
					multiple
					filename={(file) => buildFilename(file.name)}
					storageRef={LIFESTYLE_PICS_STORAGE_REF}
					onUploadStart={handleUploadStart}
					onUploadSuccess={handleUploadSuccess}
					onProgress={handleProgress}
					hidden={true}
				>
					<span>Add Photo</span>
					<FontAwesomeIcon icon={faPlusCircle} size={'lg'} />
				</FileUploader>
				{progress > 0 && (
					<Progress value={progress} color="success">
						{progress + '%'}
					</Progress>
				)}
			</div>
		</>
	);
};

export default PicturesEdit;
