import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { isEmpty } from 'lodash';
import {
	faMapMarkerAlt,
	faLanguage,
	faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import Carousel, { Modal, ModalGateway } from 'react-images';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Loader from '../utils/Loader';
import UserPhoto from '../utils/UserPhoto';
import CustomModal from '../utils/CustomModal';
import ShareButtons from '../utils/ShareButtons';
import DataSpan from '../utils/DataSpan';
import DayActivities from './DayActivities';
import BookingBar from './BookingBar';
import AboutBox from './AboutBox';
import VehicleBox from './VehicleBox';
import InterviewedByBox from './InterviewedByBox';
import ImageCarousel from './ImageCarousel';
import InterestsBars from './InterestsBars';

import { cleanUrlToShare } from '../../utils/Urls';
import { CITIES_MAP } from '../../consts/Countries';

import UserSvg from '../../assets/user/003-user.svg';
import CompassSvg from '../../assets/user/001-compass.svg';
import AroundSvg from '../../assets/user/002-around.svg';

import './user.scss';

const User = ({ match, location, history }) => {
	const uid = match.params.uid;

	useFirestoreConnect([
		{
			collection: 'users',
			doc: uid,
			storeAs: 'user',
		},
	]);

	const { profile } = useSelector((state) => state.firebase);
	const { user } = useSelector((state) => state.firestore.data);

	const [galleryOpen, setGallery] = useState(false);
	const [photoModalOpen, setPhotoModal] = useState(false);
	const [modalOpen, setModal] = useState(false);
	const [clickedPhoto, setPhoto] = useState(0);
	const [modalTitle, setModalTitle] = useState(null);
	const [modalBody, setModalBody] = useState(null);

	// TODO: use isloaded
	if (isEmpty(user)) {
		return <Loader />;
	}

	if (!isEmpty(profile) && profile.isAmbassador) {
		return <Redirect to="/profile" />;
	}

	const {
		firstName,
		photo,
		bio,
		city,
		lifestylePics,
		categoryScores,
		languages,
		tagline,
		carType,
		fav,
		places,
		exampleDay,
		interviewBy,
		tariff,
	} = user;
	const country = CITIES_MAP[city].country;

	const shareUrl =
		cleanUrlToShare(window.location.href) || window.location.href;

	const openGallery = (photo) => {
		setPhoto(photo);
		setGallery(true);
	};

	const toggleGallery = () => {
		setGallery(!galleryOpen);
	};

	const openPhotoModal = () => {
		setPhotoModal(true);
	};

	const togglePhotoModal = () => {
		setPhotoModal(!photoModalOpen);
	};

	const openModal = (title, body) => {
		// For now, two full stops represent line breaks
		body = body.replace(/\.\./g, '.\n\n');
		setModal(true);
		setModalTitle(title);
		setModalBody(body);
	};

	const toggleModal = () => {
		setModal(!modalOpen);
	};

	return (
		<div className="user-profile-container">
			<ImageCarousel
				images={lifestylePics}
				onClick={(idx) => openGallery(idx)}
				selectedItem={clickedPhoto}
			/>
			<div className="user-header-row">
				<div className="user-core-column">
					<UserPhoto
						image={photo}
						size={100}
						position="top"
						onClick={openPhotoModal}
						showVerified={true}
					/>
					<div className="user-core-data">
						<span className="user-name">{firstName}</span>
						<div className="info-container">
							<DataSpan
								data={city}
								icon={faMapMarkerAlt}
								tooltipText="City"
								label="city-field"
							/>
							<DataSpan
								data={languages}
								icon={faLanguage}
								tooltipText="Languages"
								label="lang-field"
							/>
							<DataSpan
								data={tagline}
								icon={faBriefcase}
								tooltipText="Work & Studies"
								label="headline-field"
							/>
						</div>
					</div>
				</div>
				<div className="top-interests-column">
					<InterestsBars categoryScores={categoryScores} />
				</div>
				<div className="other-info-column">
					<VehicleBox type={carType} />
					<InterviewedByBox interviewer={interviewBy} />
				</div>
			</div>
			<div className="user-about-container">
				<AboutBox
					data={bio}
					title="About me"
					icon={UserSvg}
					openModal={() => openModal(firstName, bio)}
				/>
				<AboutBox
					data={fav}
					title="My favorite things to do in my country"
					icon={CompassSvg}
					openModal={() =>
						openModal('My favorite things to do in my country', fav)
					}
				/>
				<AboutBox
					data={places}
					title="Places I’ve been"
					icon={AroundSvg}
					openModal={() => openModal('Places I’ve been', places)}
				/>
			</div>
			<DayActivities activities={exampleDay} userName={firstName} />

			<ShareButtons
				url={shareUrl}
				text={`Share ${firstName}'s profile`}
			/>
			<BookingBar user={user} location={location} history={history} />

			<ModalGateway>
				{galleryOpen && (
					<Modal onClose={toggleGallery}>
						<Carousel
							views={lifestylePics.map((photo) => {
								return {
									src: photo.url,
									caption: '',
								};
							})}
							currentIndex={clickedPhoto}
						/>
					</Modal>
				)}
			</ModalGateway>
			<ModalGateway>
				{photoModalOpen && (
					<Modal onClose={togglePhotoModal}>
						<Carousel
							views={[{ src: photo, caption: '' }]}
							components={{ Footer: null }}
						/>
					</Modal>
				)}
			</ModalGateway>
			<CustomModal
				isOpen={modalOpen}
				toggle={toggleModal}
				title={modalTitle}
				body={modalBody}
			></CustomModal>
		</div>
	);
};

export default User;
