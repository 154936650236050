import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { reducer as formReducer } from 'redux-form';

import authReducer from './authReducer';
import bookingReducer from './bookingReducer';
import itinerariesReducer from './itinerariesReducer';
import chatReducer from './chatReducer';
import activitiesReducer from './activitiesReducer';

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	auth: authReducer,
	booking: bookingReducer,
	itineraries: itinerariesReducer,
	chat: chatReducer,
	activities: activitiesReducer,
	form: formReducer,
});

export default rootReducer;
