import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import defaultPicture from '../../../assets/WizardForm/wizard-background.jpg';
import {
	faChevronRight,
	faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import './activitySlider.scss';

export default function ActivitiesSlider({ images }) {
	if (isEmpty(images)) {
		images = { pic1: { url: defaultPicture, name: 'default-pic' } };
	}

	const [currentImage, setCurrentImage] = useState(0);

	const limit = Object.values(images).length;
	const gallery = Object.values(images);
	const image = gallery[currentImage]
		? gallery[currentImage].url
		: defaultPicture;

	const next = (actualIndex) => {
		let nextImage = currentImage + 1;
		nextImage < limit ? setCurrentImage(nextImage) : setCurrentImage(0);
	};

	const prev = (actualIndex) => {
		let prevImage = currentImage - 1;
		prevImage < 0 ? setCurrentImage(limit) : setCurrentImage(prevImage);
	};

	return (
		<div id="activity-slider-container">
			<div
				className="img-container"
				style={{
					backgroundImage: `url('${image}')`,
				}}
			>
				<div className="controls">
					{limit > 1 && (
						<div className="slider-left" onClick={prev}>
							<FontAwesomeIcon icon={faChevronLeft} size="lg" />
						</div>
					)}
					{limit > 1 && (
						<div className="slider-right" onClick={next}>
							<FontAwesomeIcon icon={faChevronRight} size="lg" />
						</div>
					)}
					<div className="circle-controler">
						{gallery.map((value, index) => (
							<div
								className={
									currentImage === index
										? 'circle-index circle-index-selected'
										: 'circle-index'
								}
								onClick={() => {
									setCurrentImage(index);
								}}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
