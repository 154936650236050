import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function GalleryHandler({
	id,
	path,
	pictures,
	setPictures,
	icon,
}) {
	const firebase = useFirebase();
	const [progress, setProgress] = useState(0);
	const [gallery, setGallery] = useState(pictures);
	const [galleryArray, setGalleryArray] = useState(
		Object.values(pictures) || []
	);

	const handleProgress = (progress) => setProgress(progress);

	const handleUploadSuccess = (filename) => {
		const index = Object.keys(gallery).length + 1;

		firebase
			.storage()
			.ref(path)
			.child(filename)
			.getDownloadURL()
			.then((url) => {
				const tempGallery = gallery;
				tempGallery['pic' + index] = { url, filename };
				setGallery({ ...tempGallery });
				setProgress(0);
				setPictures({ ...tempGallery });
				setGalleryArray(Object.values(tempGallery));
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	const deleteFile = (filename) => {
		firebase
			.deleteFile(`${path}/${filename}`)
			.then((response) => {
				let newGallery = {};
				const values = galleryArray.filter(
					(value, index) => value.filename != filename
				);
				values.map((value, index) => {
					newGallery['pic' + (index + 1)] = value;
				});
				setGallery({ ...newGallery });
				setPictures({ ...newGallery });
				setGalleryArray(values);
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	return (
		<>
			<FileUploader
				accept="image/*"
				multiple
				filename={(file) => id + '-' + file.name.split('.')[0]}
				storageRef={firebase.storage().ref(path)}
				onUploadSuccess={handleUploadSuccess}
				onProgress={handleProgress}
				hidden={true}
			>
				<div
					className={
						galleryArray.length > 0
							? ' upload-img upload-img-active'
							: 'upload-img'
					}
				>
					<div>
						{galleryArray.length > 0 ? (
							galleryArray.length
						) : (
							<img src={icon} alt="logo" />
						)}
					</div>
					{galleryArray.length > 0
						? 'photos uploaded'
						: 'Upload Photo'}
				</div>
			</FileUploader>
			{progress > 0 && <Progress value={progress} />}
			<div className="gallery-preview-container">
				{galleryArray.map((value, index) => (
					<>
						<img src={value.url} key={index} />
						<FontAwesomeIcon
							icon={faTimes}
							onClick={() => deleteFile(value.filename)}
						/>
					</>
				))}
			</div>
		</>
	);
}
