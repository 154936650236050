import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import ActivityLoader from './activityLoader/ActivityLoader';
import { convertToHours } from '../utils/itinerary';

import defaultPicture from '../../assets/WizardForm/wizard-background.jpg';

import './suggestionCard.scss';

const SuggestionCard = ({
	suggestion,
	isLoading,
	showSuggestion,
	addSuggestion,
	selectActivity,
	utility,
	display = true,
}) => {
	if (!suggestion || !display) return null;

	const { pictures, name, duration } = suggestion;

	return (
		<div
			id="suggestion-card-container"
			className={`${utility ? 'utility-layout' : ''}`}
			onClick={(e) => {
				// TODO: refactor contidion
				if (
					e.target.tagName === 'DIV' &&
					e.target.className !== 'suggestion-add-icon'
				) {
					showSuggestion();
					selectActivity(suggestion);
				}
			}}
		>
			<div
				className="suggestion-img"
				style={{
					backgroundImage: `url('${
						pictures.pic1 ? pictures.pic1.url : defaultPicture
					}')`,
				}}
			>
				{isLoading ? (
					<div data-testid="loading-icon">
						<ActivityLoader />
					</div>
				) : (
					<div
						className="suggestion-add-icon"
						data-testid="add-icon"
						onClick={() => {
							addSuggestion(suggestion);
						}}
					>
						<FontAwesomeIcon icon={faPlus} size="sm" />
					</div>
				)}
			</div>
			<div className="suggestion-info">
				<div className="suggestion-title">{name}</div>
				{utility ? (
					<div className="see-more-button">
						See more
						<FontAwesomeIcon icon={faChevronRight} size="xs" />
					</div>
				) : (
					<div className="suggestion-hour">
						{`${convertToHours(duration.total)} hr`}
					</div>
				)}
			</div>
		</div>
	);
};

export default SuggestionCard;
