import React, { useEffect, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActivitySlider from '../activitySlider/ActivitySlider';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import {
	validateProps,
	convertToHours,
	validateArrayProps,
	getTimeFormatForActivities,
	getDescription,
	getGoogleMap,
} from '../../utils/itinerary';
import kidfriendlyicon from '../../../assets/icons/kidfriendlyicon.png';
import clockIcon from '../../../assets/icons/clock.png';

import './activityDescription.scss';

export default function ActivityDescription({
	addOption,
	activityInfo,
	cloneActivity,
	close,
}) {
	const [seeMoreOpen, setSeeMoreOpen] = useState(false);
	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) {
			close();
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	if (isEmpty(activityInfo)) return null;

	const {
		createdBy,
		pictures,
		name,
		duration,
		kidFriendly,
		longDescription,
		description,
		location,
		mapEmbed,
		equipment,
		extraFees,
	} = activityInfo;

	return (
		<div id="activity-description-container">
			<div className="activity-slider-container">
				<ActivitySlider images={pictures} />
			</div>

			<div className="activity-description-content">
				<div className="content">
					{createdBy && (
						<div className="activity-author-desktop">
							Created by {validateProps(createdBy.name)}
						</div>
					)}
					<div className="activity-title">{name}</div>
					{createdBy && (
						<div className="activity-author-mobile">
							Created by {validateProps(createdBy.name)}
						</div>
					)}
					<div className="activity-time">
						<img src={clockIcon} alt="Time" />
						{duration && (
							<p>{`${getTimeFormatForActivities(
								convertToHours(duration.total)
							)}`}</p>
						)}

						{kidFriendly && (
							<div className="kid-frinedly-container">
								<div>
									<img
										src={kidfriendlyicon}
										alt="Kid Friendly"
									/>
									<p>Kid Friendly</p>
								</div>
							</div>
						)}
					</div>
					<div className="activity-description-title">
						Description
					</div>
					<div className="activity-description">
						{getDescription(
							description,
							longDescription,
							seeMoreOpen,
							setSeeMoreOpen
						)}
					</div>
					{mapEmbed && location && getGoogleMap(mapEmbed, location)}
					<div className="activity-description-title">Equipment</div>
					<div className="activity-description">
						{validateArrayProps(equipment)}
					</div>
					<div className="activity-description-title">Extra Fees</div>
					<div className="activity-description">
						{validateArrayProps(extraFees)}
					</div>
				</div>
			</div>
			{addOption && (
				<div className="add-btn">
					<span>Add to Itinerary</span>
					<div
						className="suggestion-add-icon"
						onClick={() => {
							cloneActivity();
							close();
						}}
					>
						<FontAwesomeIcon icon={faPlus} size="sm" />
					</div>
				</div>
			)}
		</div>
	);
}

ActivityDescription.defaultProps = {
	addOption: false,
};
