import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { isEmpty } from 'lodash';
import './customModal.scss';

const CustomModal = ({ toggle, isOpen, body, title }) => {
	const renderBody = (body) => {
		if (!isEmpty(body) && body.value) {
			return (
				<ul>
					{body.map((value) => {
						return (
							<li>
								{value.shortDescription}
								<p>{value.LongDescription}</p>
							</li>
						);
					})}
				</ul>
			);
		}
		return <p>{body}</p>;
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>{title}</ModalHeader>
			<ModalBody>{renderBody(body)}</ModalBody>
		</Modal>
	);
};

export default CustomModal;
