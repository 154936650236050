import { pickBy } from 'lodash';

// HACK: removes old interests
export const filterInterests = (interests) => {
	const filteredInterests = pickBy(interests, (interest) => {
		const { name } = interest;
		return (
			name !== 'Activities for solo travelers' &&
			name !== 'Cooking' &&
			name !== 'Restaurants And Local Food' &&
			name !== 'Major sites / orientation' &&
			name !== 'Architecture' &&
			name !== 'History and historical landmarks' &&
			name !== 'Religious sites' &&
			name !== 'Galleries and museums' &&
			name !== 'Cultural sites'
		);
	});

	return filteredInterests;
};
