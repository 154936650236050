import React from 'react';
import { Spinner } from 'reactstrap';
import './loader.scss';

const Loader = ({ fullHeight = true, height }) => {
	return (
		<div
			id="loader-container"
			style={!fullHeight ? { height: '100%' } : height ? { height } : {}}
		>
			<Spinner />
		</div>
	);
};

export default Loader;
