import React from 'react';
import './customSwitch.scss';

const CustomSwitch = ({ isOn, handleToggle, name }) => {
	return (
		<div className="custom-switch-container">
			<input
				name={name}
				checked={isOn}
				onChange={handleToggle}
				className="react-switch-checkbox"
				id={name}
				type="checkbox"
			/>
			<label className="react-switch-label" htmlFor={name}>
				<span className={`react-switch-button`} />
			</label>
		</div>
	);
};

export default CustomSwitch;
