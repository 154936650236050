import React from 'react';
import { Modal } from 'reactstrap';
import CustomBtn from '../utils/CustomBtn';

import './HighOrderModal.scss';

export default function HighOrderModal({
	toggle,
	isOpen,
	fullScreen,
	width,
	closeBtn,
	component: Component,
	...rest
}) {
	let fullScreenclass = {
		dialogClassname: 'custom-modal',
		bsClass: 'my-modal',
	};
	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className={fullScreen ? fullScreenclass.dialogClassname : ''}
			style={{ maxWidth: width }}
		>
			{closeBtn && (
				<div className="close-container">
					<CustomBtn
						loading={false}
						text={'Close'}
						width={156}
						className="button-primary gradient squared narrow"
						onClick={toggle}
					/>
				</div>
			)}

			<Component {...rest} />
		</Modal>
	);
}

HighOrderModal.defaultProps = {
	closeBtn: true,
};
