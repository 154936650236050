import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPen,
	faDownload,
	faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import ItineraryActivities from './ItineraryActivities';
import ItineraryInvoice from './itineraryInvoice/ItineraryInvoice';

import { GET_BOOKING_CORE } from '../../consts/Queries';
import {
	DOWNLOAD_ACTIVITY,
	ITINERARY_BUILDER_CTA,
	MY_ITINERARY,
} from '../../consts/Messages';
import saveItinerary from '../../assets/icons/saveItinerary.png';

import './itineraryBuilder.scss';

const ItineraryBuilder = ({ currentDay }) => {
	const [isReady, setIsReady] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState({});

	const { itinerary } = useSelector((state) => state.itineraries);
	const { itineraryActivities } = useSelector((state) => state.activities);

	const { loading, error, data } = useQuery(GET_BOOKING_CORE, {
		variables: { id: itinerary ? itinerary.bookingId : '' },
	});

	useEffect(() => {
		setIsReady(false);
		if (isEmpty(data) || isEmpty(itinerary)) return;
		// HACK: allow pdf to render when everything is ok (change later)
		setTimeout(() => {
			setIsReady(true);
		}, 2000);
	}, [data, itinerary, itineraryActivities]);

	const toggle = () => setTooltipOpen(!tooltipOpen);

	const editModeHandler = () => {
		setEditMode(!editMode);
	};

	return (
		<div id="itinerary-builder-container">
			<div className={'heading'}>
				{MY_ITINERARY}
				<FontAwesomeIcon
					className={editMode ? 'edit-icon active' : 'edit-icon'}
					icon={faPen}
					size="sm"
					onClick={editModeHandler}
				/>
				{isReady ? (
					<PDFDownloadLink
						document={
							<ItineraryInvoice
								itinerary={itinerary}
								activities={itineraryActivities}
								booking={data}
							/>
						}
						fileName="My-RedKnot-Itinerary.pdf"
						className="download-invoice"
					>
						{({ loading, error }) =>
							loading ? (
								<FontAwesomeIcon
									icon={faSpinner}
									size="sm"
									spin
								/>
							) : error ? null : (
								<>
									<FontAwesomeIcon
										icon={faDownload}
										size="sm"
										id="download-icon"
									/>
									<Tooltip
										placement="top"
										isOpen={tooltipOpen}
										target="download-icon"
										toggle={toggle}
									>
										{DOWNLOAD_ACTIVITY}
									</Tooltip>
								</>
							)
						}
					</PDFDownloadLink>
				) : (
					<FontAwesomeIcon
						className="download-invoice"
						icon={faSpinner}
						size="sm"
						spin
					/>
				)}
			</div>

			<div className="sub-heading">{ITINERARY_BUILDER_CTA}</div>
			{/* <div className="save-itinerary">
				<img src={saveItinerary} alt="save" />
				<h3 className="sub-heading">Save Itinerary</h3>
			</div> */}
			<ItineraryActivities
				selectActivity={setSelectedActivity}
				selectedActivity={selectedActivity}
				currentDay={currentDay}
				editMode={editMode}
				setDownloadReady={setIsReady}
			/>
		</div>
	);
};

export default ItineraryBuilder;
