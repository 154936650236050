import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const GoBackButton = ({ history }) => {
	return (
		<div className="go-back-icon">
			<FontAwesomeIcon
				size="lg"
				icon={faAngleLeft}
				onClick={() => {
					history.goBack();
				}}
			/>
		</div>
	);
};

export default GoBackButton;
