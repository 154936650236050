import React, { useEffect } from 'react';
import './googleTranslate.scss';

const GoogleTranslate = () => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
		script.async = true;
		document.body.appendChild(script);
	}, []);

	return <div id="google_translate_element">Translate:</div>;
};

export default GoogleTranslate;
