import React from 'react';
import { Alert } from 'reactstrap';
import './alert.scss';

const AlertMsg = ({ content, dismiss, isOpen, color }) => {
	return (
		<Alert
			color={color ? color : 'danger'}
			isOpen={isOpen}
			toggle={dismiss}
		>
			{content}
		</Alert>
	);
};

export default AlertMsg;
