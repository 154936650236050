import React from 'react';
import { isEmpty } from 'lodash';
import './userInfoBox.scss';

const UserInfoBox = ({ data, img, label }) => {
	if (isEmpty(data)) {
		return null;
	}

	return (
		<div className="user-info-box">
			<span>
				<img src={img} alt={label} />
				<div>{data}</div>
			</span>
		</div>
	);
};

export default UserInfoBox;
