const initState = {
	booking: null,
	checkoutSession: null,
};

const bookingReducer = (state = initState, action) => {
	let booking;

	switch (action.type) {
		case 'GET_BOOKING':
			({ booking } = action);
			return {
				...state,
				booking,
			};

		case 'CREATE_BOOKING':
			({ booking } = action);
			return {
				...state,
				booking,
			};

		case 'UPDATE_BOOKING_DATA':
			const { props } = action.data;
			return {
				...state,
				booking: Object.assign({}, state.booking, props),
			};

		case 'CREATE_CHECKOUT_SESSION':
			const { id } = action;
			return {
				...state,
				checkoutSession: id,
			};

		case 'UPDATE_AVAILABILITY':
			return state;

		default:
			return state;
	}
};

export default bookingReducer;
