import React from 'react';
import './hero.scss';

const gradient = `linear-gradient(0deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22))`;

const Hero = ({
	size = '300px',
	image,
	title,
	subtitle,
	alignItems,
	justifyContent,
	position = 'center',
	fontSize,
	textAlign,
	fullWidth = true,
}) => {
	return (
		<div
			className={`section-hero ${fullWidth ? 'full-width' : ''}`}
			style={{
				height: size,
				backgroundImage: `${gradient}, url('${image}')`,
				backgroundPosition: position,
				alignItems,
				justifyContent,
			}}
		>
			<div className="hero-title" style={{ fontSize, textAlign }}>
				{title}
				{subtitle && <div>{subtitle}</div>}
			</div>
		</div>
	);
};

export default Hero;
