import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './cardBadge.scss';

const CardBadge = ({ icon, text }) => {
	return (
		<div class="card-badge">
			<FontAwesomeIcon icon={icon}></FontAwesomeIcon>
			<div>{text}</div>
		</div>
	);
};

export default CardBadge;
