import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import config from './config';

const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: new HttpLink({
		uri: config.graphqlUrl,
	}),
});

export default apolloClient;
