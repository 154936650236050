const initState = {
	signInError: null,
	signUpError: null,
};

const authReducer = (state = initState, action) => {
	let error = null;

	switch (action.type) {
		case 'SIGNIN_SUCCESS':
			return {
				...state,
				signInError: null,
			};

		case 'SIGNIN_ERROR':
			({ error } = action);
			const { code, message } = error;
			let errorMsg = message;

			switch (code) {
				case 'auth/user-not-found':
				case 'auth/wrong-password':
					errorMsg =
						'Invalid email address or password. Please try again or register if you wish to create an account.';
					break;
				default:
					break;
			}

			return {
				...state,
				signInError: errorMsg,
			};

		case 'SIGNUP_SUCCESS':
			return {
				...state,
				signUpError: null,
			};

		case 'SIGNUP_ERROR':
			({ error } = action);

			return {
				...state,
				signUpError: error.message,
			};

		case 'PASSWORD_RESET_LINK_SUCCESS':
			return {
				...state,
				signInError: null,
			};

		case 'PASSWORD_RESET_LINK_ERROR':
			return {
				...state,
				signInError:
					'Error sending a password recovery email. Please try again or send us an email to bridges@goredknot.com',
			};

		case 'SIGNOUT_SUCCESS':
			return {
				...state,
				signInError: null,
			};

		case 'RESET_ERROR':
			return {
				...state,
				signInError: null,
				signUpError: null,
			};

		default:
			return state;
	}
};

export default authReducer;
