import React from 'react';
import brand_white from '../../assets/brand/brand_white.png';
import './brandHeader.scss';

const BrandHeader = () => {
	return (
		<div className="brand-header">
			<img alt={'RedKnot Brand'} src={brand_white}></img>
		</div>
	);
};

export default BrandHeader;
