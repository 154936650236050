import React from 'react';
import GroupSizeInputs from '../utils/GroupSizeInputs';
import './groupSizeBox.scss';

const GroupSizeBox = ({
	handleChange,
	option,
	optionData,
	active,
	kids,
	adults,
}) => {
	const { img, inputs, description } = optionData;

	const setSolo = () => {
		handleChange({
			name: 'adults',
			value: 1,
		});
		handleChange({
			name: 'kids',
			value: 0,
		});
		handleChange({
			name: 'numTravelers',
			value: 1,
		});
	};

	const setCouple = () => {
		handleChange({
			name: 'adults',
			value: 2,
		});
		handleChange({
			name: 'kids',
			value: 0,
		});
		handleChange({
			name: 'numTravelers',
			value: 2,
		});
	};

	const selectOption = (option) => {
		switch (option) {
			case 'solo':
				setSolo();
				break;
			case 'couple':
				setCouple();
				break;
			default:
				break;
		}

		handleChange({
			name: 'option',
			value: option,
		});
	};

	return (
		<div id="group-size-box-container">
			<div
				className={
					active
						? 'number-people-box active'
						: 'number-people-box inactive'
				}
				style={{
					backgroundImage: `url('${img}')`,
				}}
				onClick={() => {
					selectOption(option);
				}}
			>
				<span>{option}</span>
			</div>
			<div className={active ? 'description active' : 'description'}>
				{inputs && (
					<GroupSizeInputs
						handleChange={handleChange}
						adults={adults}
						kids={kids}
					/>
				)}
				{description}
			</div>
		</div>
	);
};

export default GroupSizeBox;
