const initState = {
	itineraryActivities: [],
	testerActivities: [],
	utilityActivities: [],
	timePerDay: {},
	calculateSuggestions: true,
	cloneActivityLoading: false,
	createActivityLoading: false,
	activitiesCoordinates: [],
	activitiesTime: {},
	newActivityTime: {},
	sendItineraryPdf: false,
};

const activitiesReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_ACTIVITIES':
			return {
				...state,
				itineraryActivities: action.activities,
				calculateSuggestions: true,
			};
		case 'GET_ACTIVITIES_COORDINATES':
			return {
				...state,
				activitiesCoordinates: action.coordinates,
				calculateSuggestions: false,
			};
		case 'GET_TIME_PER_ACTIVITIES':
			return {
				...state,
				activitiesTime: action.time,
				calculateSuggestions: false,
			};
		case 'GET_NEW ACTIVITY_TIME':
			return {
				...state,
				newActivityTime: action.time,
				calculateSuggestions: true,
			};
		case 'SET_TESTER_ACTIVITIES':
			return {
				...state,
				testerActivities: action.activityIds,
				calculateSuggestions: false,
			};
		case 'CREATE_ACTIVITY_SUCCESS':
			return {
				...state,
				itineraryActivities: action.newActivities,
				calculateSuggestions: true,
			};
		case 'CREATE_ACTIVITY_LOADING':
			return {
				...state,
				createActivityLoading: action.loading,
				calculateSuggestions: false,
			};
		case 'CLONE_ACTIVITY_LOADING':
			return {
				...state,
				cloneActivityLoading: action.loading,
				calculateSuggestions: false,
			};
		case 'UPDATE_ACTIVITY_SUCCESS':
			return {
				...state,
				itineraryActivities: action.updated,
				calculateSuggestions: false,
			};
		case 'DELETE_ACTIVITY_SUCCESS':
			return {
				...state,
				itineraryActivities: action.data,
				calculateSuggestions: true,
			};
		case 'UPDATE_TIME_PER_DAY':
			return {
				...state,
				timePerDay: action.timePerDay,
			};
		case 'UPDATE_ORDER_SUCCESS':
			return {
				...state,
				itineraryActivities: action.newActivities,
				calculateSuggestions: false,
			};
		case 'ADD_ITINERARY_ACTIVITIES_SUCCESS':
			return {
				...state,
				itineraryActivities: action.newActivities,
				calculateSuggestions: true,
			};
		case 'SET_UTILITY_ACTIVITIES':
			return {
				...state,
				utilityActivities: action.activities,
			};
		case 'SEND_PDF_SUCCESS':
			return {
				...state,
				sendItineraryPdf: action.sendSuccesPdf,
			};
		default:
			return state;
	}
};

export default activitiesReducer;
