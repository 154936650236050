import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './pagination.scss';

const Pagination = ({
	length,
	itemsPerPage,
	currentPage,
	handleChange,
	showArrows = true,
}) => {
	const totalPages = Math.ceil(length / itemsPerPage);

	const loadPage = (page) => {
		const newLast = itemsPerPage * page;
		const newFirst = itemsPerPage * page - itemsPerPage;

		handleChange({
			page: parseInt(page),
			first: newFirst,
			last: newLast,
		});
	};

	const loadNextPage = () => {
		const next = currentPage + 1;
		if (next <= totalPages) {
			loadPage(next);
		}
	};

	const loadPrevPage = () => {
		const prev = currentPage - 1;
		if (prev >= 1) {
			loadPage(prev);
		}
	};

	const createPagination = () => {
		let numbers = [];
		for (let i = 1; i <= totalPages; i++) {
			numbers.push(
				<div
					className={
						currentPage === i
							? 'pagination-number'
							: 'pagination-number active'
					}
					onClick={() => loadPage(i)}
				>
					<span value={i}>{i}</span>
				</div>
			);
		}

		return numbers;
	};

	const hasPrevPage = () => {
		return currentPage !== 1;
	};

	const hasNextPage = () => {
		return currentPage !== totalPages;
	};

	return (
		<div className="pagination">
			{hasPrevPage() && showArrows && (
				<span className="arrow-left">
					<FontAwesomeIcon
						onClick={loadPrevPage}
						icon={faAngleLeft}
					/>
				</span>
			)}
			<div className="pagination-numbers-container">
				{createPagination()}
			</div>
			{hasNextPage() && showArrows && (
				<span className="arrow-right">
					<FontAwesomeIcon
						onClick={loadNextPage}
						icon={faAngleRight}
					/>
				</span>
			)}
		</div>
	);
};

export default Pagination;
