import React from 'react';

import defaultPhoto from '../../assets/user/default-placeholder.png';
import RedknotV from '../../assets/user/RedKnot_Verified.svg';

import './userPhoto.scss';

const whiteBorder = '1.5px solid white';

const UserPhoto = ({
	size = 80,
	borderRadius = '100%',
	image = defaultPhoto,
	position = 'center',
	border,
	name,
	onClick,
	showVerified = false,
}) => {
	return (
		<div
			className="user-photo"
			onClick={onClick}
			style={{ cursor: onClick && 'pointer' }}
		>
			<div
				className="user-image"
				style={{
					width: size,
					height: size,
					borderRadius: borderRadius,
					backgroundImage: `url('${image || defaultPhoto}')`,
					backgroundPosition: position,
					border: border && whiteBorder,
				}}
			>
				{showVerified && (
					<div
						className="redknot-verified-logo"
						style={{
							backgroundImage: `url('${RedknotV}')`,
						}}
					></div>
				)}
			</div>
			{name && <div className="user-name">{name}</div>}
		</div>
	);
};

export default UserPhoto;
