import React from 'react';
import GroupSizeInputs from './GroupSizeInputs';
import { VEHICLE_TYPES } from '../../consts/Vechicles';

const GroupSizeHandler = ({
	adults,
	kids,
	handleChange,
	handleValidation,
	carType,
	disabled,
}) => {
	const { capacity } = VEHICLE_TYPES[carType];

	const handleSizeChange = ({ name, value }) => {
		if (name === 'numTravelers') {
			return;
		}

		let validTrip;
		if (name === 'adults') {
			validTrip = isTripValid(value, kids);
			handleValidation(validTrip);
			if (!validTrip) return;
		} else {
			validTrip = isTripValid(adults, value);
			handleValidation(validTrip);
			if (!validTrip) return;
		}

		handleChange({
			adults: name === 'adults' ? value : adults,
			kids: name === 'kids' ? value : kids,
		});
	};

	const isTripValid = (adults, kids) => {
		const numTravelers = parseInt(adults) + parseInt(kids);
		return numTravelers <= capacity;
	};

	return (
		<GroupSizeInputs
			handleChange={handleSizeChange}
			adults={adults}
			kids={kids}
			disabled={disabled}
		/>
	);
};

export default GroupSizeHandler;
