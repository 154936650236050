import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import MultipleDatePicker from 'react-multiple-datepicker';

const CustomBtn = ({
	loading,
	text,
	icon,
	loadingText = 'Loading...',
	disabledText = text,
	onClick,
	width,
	disabled,
	disabledIcon,
	datesMode,
	availableDates,
	disabledDates,
	selectedDates,
	handleDatesInput,
	className,
	imgIcon,
	imgIconClass,
}) => {
	const falseOnclick = () => {
		let test = document
			.getElementById('dates-booking-buttons')
			.getElementsByTagName('input');
		test[0].click();
	};
	return (
		<>
			<Button
				onClick={datesMode ? falseOnclick : onClick}
				className={className}
				disabled={loading || disabled}
				style={{ width }}
			>
				{loading ? (
					<>
						<FontAwesomeIcon icon={faSpinner} spin />
						<span>{loadingText}</span>
					</>
				) : disabled ? (
					<>
						<span>{disabledText}</span>
						{disabledIcon && (
							<FontAwesomeIcon
								icon={disabledIcon}
								style={{ marginLeft: 5 }}
							/>
						)}
					</>
				) : (
					<>
						{icon && <FontAwesomeIcon icon={icon} />}
						{imgIcon && (
							<img
								src={imgIcon}
								alt="icon"
								className={imgIconClass}
							/>
						)}
						<span>{text}</span>
					</>
				)}
			</Button>
			{datesMode && (
				<div id="dates-booking-buttons">
					<MultipleDatePicker
						onSubmit={(dates) => handleDatesInput(dates)}
						minDate={new Date()}
						maxDate={new Date(Math.max.apply(null, availableDates))}
						selectedDates={selectedDates}
						disabledDates={disabledDates}
					/>
				</div>
			)}
		</>
	);
};

export default CustomBtn;
