import { isEmpty } from 'lodash';
import config from '../../config';
import { buildItineraryObject } from '../../schemas/Itinerary';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const { backendUrl } = config;

export const fetchItinerary = (data) => {
	return (dispatch, getState) => {
		const { id } = data;
		const { itineraries } = getState();
		let { itinerary } = itineraries;

		// TODO: improve caching
		if (!isEmpty(itinerary) && itinerary.id === id) {
			// console.log('itinerary already in store', itinerary);
			dispatch({ type: 'GET_ITINERARY', itinerary });
			return;
		}

		try {
			fetch(`${backendUrl}/itinerary/?id=${id}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}).then(async (resp) => {
				itinerary = await resp.json();
				// console.log('got itinerary with redux', itinerary);
				dispatch({ type: 'GET_ITINERARY', itinerary });
			});
		} catch (error) {
			console.error(`Error fetching itinerary ${id}`, error);
		}
		// }
	};
};

export const getItineraries = (data) => {
	return (dispatch, getState) => {
		const { uid } = data;
		// TODO: api middleware to standarize API calls
		try {
			fetch(`${backendUrl}/itineraries?uid=${uid}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}).then(async (resp) => {
				const itineraries = await resp.json();
				// console.log('got itineraries with redux', itineraries);
				dispatch({ type: 'GET_ITINERARIES', itineraries });
			});
		} catch (error) {
			console.error(`Error fetching itineraries for user ${uid}`, error);
		}
	};
};

export const createItinerary = (data) => {
	return (dispatch, getState) => {
		// create doesn't need to wait for API response
		dispatch({ type: 'CREATE_ITINERARY', data });

		const itinerary = buildItineraryObject(data);
		const body = JSON.stringify(itinerary);
		try {
			fetch(`${backendUrl}/createItinerary`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(async (res) => {
				const { id } = await res.json(); // data = { id, chat }
				const updateData = {
					props: {
						id,
						// chat,
					},
				};
				dispatch({
					type: 'UPDATE_ITINERARY_DATA',
					data: updateData,
				});
			});
		} catch (error) {
			console.error(`Error creating itinerary`, error);
		}
	};
};

export const updateItineraryDetails = (data) => {
	return (dispatch, getState) => {
		// update doesn't need to wait for API response
		dispatch({ type: 'UPDATE_ITINERARY_DATA', data });

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/updateItineraryData`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(() => {
				// console.log('updated itinerary with redux', data);
			});
		} catch (error) {
			console.error(`Error updating itinerary ${data.id}`, error);
		}
	};
};

// export const updateItineraryActivity = (data) => {
// 	return (dispatch, getState) => {
// 		const body = JSON.stringify(data);
// 		const { activityId } = data;
// 		try {
// 			fetch(`${backendUrl}/updateItineraryActivity`, {
// 				method: 'POST',
// 				mode: 'no-cors',
// 				headers: { 'Content-Type': 'application/json' },
// 				body,
// 			}).then(() => {
// 				console.log('updated itinerary activity with redux', data);
// 			});
// 		} catch (error) {
// 			console.error(
// 				`Error updating itinerary activity ${activityId}`,
// 				error
// 			);
// 		}
// 	};
// };

export const getItineraryFromGql = (data) => {
	return (dispatch) => {
		dispatch({ type: 'GET_ITINERARY_FROM_GQL', data });
	};
};
