import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { EXAMPLE_DAY_FORMAT } from '../../consts/DateFormats';
import './dayActivities.scss';

const DayActivities = ({ activities, userName }) => {
	if (isEmpty(activities)) {
		return null;
	}

	const hours = Object.keys(activities);
	// order day hours
	hours.sort((h1, h2) => {
		return moment(h1, EXAMPLE_DAY_FORMAT) - moment(h2, EXAMPLE_DAY_FORMAT);
	});

	return (
		<div className="day-activities-container">
			<div className="day-activities-title">
				An example of what a day with me could look like
				<div>{userName} will create an itinerary just for you</div>
			</div>
			<div className="timeline-container">
				<ul className="timeline">
					{hours.map((time) => (
						<li key={time} className="timeline__step">
							<div className="timeline__title">
								<p className="timeline-hour">{time}</p>
								{activities[time]}
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default DayActivities;
