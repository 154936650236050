import firebase from 'firebase';

const PROFILE_PICS_FOLDER = 'profile-pictures';
const LIFESTYLE_PICS_FOLDER = 'lifestyle-pictures';

export const PROFILE_PICS_STORAGE_REF = firebase
	.storage()
	.ref(PROFILE_PICS_FOLDER);

export const LIFESTYLE_PICS_STORAGE_REF = firebase
	.storage()
	.ref(LIFESTYLE_PICS_FOLDER);
