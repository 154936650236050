import React, { useState, useEffect, useRef } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import { useSelector } from 'react-redux';

import GoBackButton from './GoBackButton';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';

import RedKnotBrand from '../utils/RedKnotBrand';

import './navigation.scss';

const Navigation = ({ history }) => {
	const { profile, auth } = useSelector((state) => state.firebase);
	const [isOpen, toggle] = useState(false);

	const { isAgent } = profile;
	const { isLoaded, isEmpty } = auth;
	const isAuthed = isLoaded && !isEmpty;

	const links = isAuthed ? (
		<SignedInLinks toggle={toggle} isOpen={isOpen} />
	) : (
		<SignedOutLinks toggle={toggle} isOpen={isOpen} />
	);

	// Trick to close nav on outside click
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const node = useRef();
	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			return;
		}
		toggle(false);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Escape') {
			toggle(false);
		}
	};

	return (
		<div id="navbar" ref={node}>
			<Navbar color="faded" light className={isAgent && 'agent'}>
				<GoBackButton history={history} />
				<RedKnotBrand target="_blank"></RedKnotBrand>
				<NavbarToggler
					onClick={() => toggle(!isOpen)}
					className="mr-2"
				/>
				<Collapse isOpen={isOpen} navbar>
					<Nav navbar>{links}</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default Navigation;
