import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { isEmpty } from 'lodash';
import './imageCarousel.scss';

const ImageCarousel = ({ images, onClick, selectedItem }) => {
	if (isEmpty(images)) {
		return null;
	}

	return (
		<Carousel
			autoPlay
			showThumbs={false}
			infiniteLoop={true}
			onClickItem={onClick}
			selectedItem={selectedItem}
			centerMode={true}
		>
			{images.map((pic, index) => {
				return (
					<div
						key={index}
						className="carousel-image-container"
						style={{
							backgroundImage: `url('${pic.url}')`,
						}}
					></div>
				);
			})}
		</Carousel>
	);
};

export default ImageCarousel;
