import React from 'react';
import { COUNTRIES, getCountryCities } from '../../consts/Countries';
import { INTERESTS } from '../../consts/Messages';

import './activityFilters.scss';

const ActivityFilters = ({
	country,
	city,
	disabled,
	toggleInterests,
	handleCountry,
	handleCity,
	numInterests,
	showInterests = true,
}) => {
	const countryCities = getCountryCities(country);

	return (
		<div id="activity-filters-container">
			<select
				className="activities-filter"
				value={country}
				onChange={(e) => handleCountry(e.target.value)}
				disabled={disabled}
			>
				<option></option>
				{COUNTRIES.map(({ country }, idx) => (
					<option key={idx} value={country}>
						{country}
					</option>
				))}
			</select>
			<select
				className="activities-filter"
				value={city}
				disabled={disabled || !country}
				onChange={(e) => handleCity(e.target.value)}
			>
				{countryCities.map(({ value }, idx) => (
					<option key={idx} value={value}>
						{value}
					</option>
				))}
			</select>
			{showInterests && (
				<button
					className={`activities-filter ${
						numInterests > 0 ? 'selected' : ''
					}`}
					onClick={toggleInterests}
					disabled={disabled}
				>
					{INTERESTS}
					{numInterests > 0 && (
						<span>{`${numInterests} selected`}</span>
					)}
				</button>
			)}
		</div>
	);
};

export default ActivityFilters;
