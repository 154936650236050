// TODO: get from the DB
export const PROMO_CODES = {
	xsuo3ma8tHlJ51j9c41Y: {
		code: 'REDKNOT30',
		ammountOff: 30,
	},
	HP08Icav0QLhU0P9EJmc: {
		code: 'HOLIDAY35',
		ammountOff: 35,
	},
	uDSqcOZgO7IZ4kmIQ7Fg: {
		code: 'RedKnotFree',
		ammountOff: 120,
	},
	hcRapQvXg2lJI94wvqAG: {
		code: 'SVPUROSURF10',
		ammountOff: 10,
	},
	zG0Nt2pNxdRzqBPp97DC: {
		code: 'SVLKNGGOOD10',
		ammountOff: 10,
	},
	MakqP9gXIh0CWZ7VDFGc: {
		code: 'REDKNOT20',
		ammountOff: 20,
	},
	Itg1QqCC6SG9AFAUbDMx: {
		code: 'IFIGUEROA10',
		ammountOff: 10,
	},
	TQMDDT5dkmUciQvArr2P: {
		code: 'ABOTRAN10',
		ammountOff: 10,
	},
	hfS7OSsh3aS5U9oOow0D: {
		code: 'REDKNOT40',
		ammountOff: 40,
	},
	nfaqfo5yqYVfHGXSayf7: {
		code: 'JCOSG10',
		ammountOff: 10,
	},
	ebORyXdz4DHSglHAqTUf: {
		code: 'SLVHCTY056',
		ammountOff: 10,
	},
	MqGbWuHvrrj96kPzpXzM: {
		code: 'SLVHCTY058',
		ammountOff: 10,
	},
	nPe53h7vC2fa273th4FJ: {
		code: 'SLVHCTY057',
		ammountOff: 10,
	},

	vcbgeorfakdJSTrqnRiG: {
		code: 'REDKNOT10',
		ammountOff: 10,
	},
	T9VU0ScBO90ANmuOUU5M: {
		code: 'MAMAPAN10',
		ammountOff: 10,
	},
	aIkXvq9oznnKmuhHoqr2: {
		code: 'CASA10',
		ammountOff: 10,
	},
	TqsjfmTdZI3WGDCJvHuz: {
		code: 'GTHCTY077',
		ammountOff: 10,
	},
	u9hNqyZQncMakuzDo5vw: {
		code: 'SLVHCTY059',
		ammountOff: 10,
	},
	r5bSVKG4uD7Mixwmsr2z: {
		code: 'NOMADE5',
		ammountOff: 10,
	},
	iXh8GbLwPCHhYkT0n7HP: {
		code: 'NOMADE10',
		ammountOff: 10,
	},
	dFay2SJ9bbEevTDusPon: {
		code: 'GTHCTY077',
		ammountOff: 10,
	},
	qTJruCrjLcboqFRMXcmO: {
		code: 'JANGULO10',
		ammountOff: 10,
	},
	HiqbVSFO7lqb6gEhGOfG: {
		code: 'CRWITHREDKNOT35',
		ammountOff: 35,
	},

	ROh2BugARvGSNStLcSTo: {
		code: 'CRTRIP30',
		ammountOff: 30,
	},
};
