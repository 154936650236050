import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { isEmpty, union, remove } from 'lodash';

import { createBooking } from '../../store/actions/bookingActions';
import { setUtilityActivities } from '../../store/actions/activitiesActions';

import SuggestedActivitiesList from './SuggestedActivitiesList';
import SelectedActivitiesList from './SelectedActivitiesList';
import ActivityDescription from '../itineraryBuilder/activityDescription/ActivityDescription';
import ActivityFilters from './ActivityFilters';
import InterestsByCategory from './InterestsByCategory';
import Login from '../auth/Login';

import ProfileShortInfo from '../utils/ProfileShortInfo';
import BtnLoader from '../utils/BtnLoader';
import HighOrderModal from '../utils/HighOrderModal';
import Drawer from '../itineraryBuilder/drawer/Drawer';

import { getUrlParameters, changeQueryParams } from '../../utils/Urls';
import { groupSizePrice } from '../../utils/Pricing';
import { compare } from '../utils/itinerary';

import { getCountryCities } from '../../consts/Countries';
import {
	ITINERARY_UTILITY_WELCOME,
	ITINERARY_UTILITY_CTA,
	ITINERARY_WITH_AMBASSADOR,
} from '../../consts/Messages';
import {
	getMatchQueryParams,
	GET_MATCH_USERS,
	GET_ACTIVITY_SUGGESTIONS,
} from '../../consts/Queries';
import { INTERESTS } from '../../consts/Interests';
import { areEqualBookings } from '../../schemas/Booking';

import './itineraryUtility.scss';

const PER_DAY_TIME_LIMIT = 8 * 60;

const ItineraryUtility = ({
	history,
	editable = false,
	ambassadorId,
	dates = [],
}) => {
	const { auth, profile } = useSelector((state) => state.firebase);
	const { booking } = useSelector((state) => state.booking);

	const [itineraryCountry, setCountry] = useState('');
	const [itineraryCity, setCity] = useState('');
	const [numTravelers, setTravelers] = useState({});
	const [bookingData, setBooking] = useState({});
	const [ambassador, setAmbassador] = useState('');
	const [queryActivities, setQueryActivities] = useState([]);
	const [addedActivities, setAddedActivities] = useState([]);
	const [selectedActivity, setSelectedActivity] = useState({});
	const [incomingActivities, setIncomingActivities] = useState([]); // incoming activities from url/localstorage
	const [remainingTime, setRemainingTime] = useState(PER_DAY_TIME_LIMIT);
	const [selectedInterests, setSelectedInterests] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [timeLimitAlert, setTimeLimitAlert] = useState(false);
	const [runMatchQuery, setRunMatchQuery] = useState(false);
	const [runActivitiesQuery, setRunActivitiesQuery] = useState(true);
	const [queryLoading, setQueryLoading] = useState(true);
	const [queryError, setQueryError] = useState(false);
	const [queryVariables, setQueryVariables] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
	const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

	const { isLoaded, isEmpty: authEmpty } = auth;
	const isAuthed = isLoaded && !authEmpty;

	const dispatch = useDispatch();

	const ambassadorsQuery = useQuery(GET_MATCH_USERS, {
		variables: queryVariables,
		skip: !runMatchQuery,
		notifyOnNetworkStatusChange: true,
	});

	const suggestionsQuery = useQuery(GET_ACTIVITY_SUGGESTIONS, {
		variables: {
			city: itineraryCity || '',
			activities: addedActivities,
		},
		skip: !runActivitiesQuery || !itineraryCity,
		notifyOnNetworkStatusChange: true,
	});

	// initialize all variables on mount depending on where the user comes from
	useEffect(() => {
		const setInterests = (interests) => {
			if (isEmpty(interests)) return;
			const ids = interests.split(',');
			localStorage.setItem('interests', interests);
			setSelectedInterests(ids);
		};

		const queryParams = getUrlParameters();
		let {
			country,
			city,
			adults,
			kids,
			interests,
			activities,
			ambassador,
		} = queryParams;

		country = country || localStorage.getItem('country') || '';
		city = city || localStorage.getItem('city') || '';
		adults = adults || localStorage.getItem('adults') || 1;
		kids = kids || localStorage.getItem('kids') || 0;
		interests = interests || localStorage.getItem('interests') || [];
		activities = activities || localStorage.getItem('activities') || [];

		const travelers = {
			adults: parseInt(adults),
			kids: parseInt(kids),
		};

		// set ambassador coming from the props or from query params
		setAmbassador(ambassadorId || ambassador);
		setCountry(country);
		setCity(city);
		setTravelers(travelers);
		setInterests(interests);
		setIncomingActivities(activities);
	}, []);

	useEffect(() => {
		const { data, loading, error } = suggestionsQuery;
		setQueryLoading(loading);
		setQueryError(error);

		if (data) {
			const { activitySuggestions } = data;
			let activities = [];
			activitySuggestions.forEach((act) => {
				let tmp = Object.assign({}, act);
				tmp['added'] = false;
				activities.push(tmp);
			});
			// set shared activities coming from the url
			if (!isEmpty(incomingActivities)) {
				setInitialActivities(activities);
			}
			setQueryActivities(activities);
			setRunActivitiesQuery(false);
			dismissAlert();
		}
	}, [suggestionsQuery]);

	useEffect(() => {
		if (isEmpty(queryVariables)) return;

		const { data, loading, error } = ambassadorsQuery;
		setIsLoading(loading);

		if (isEmpty(data) && !error) return;

		const result = data.matchUsers;
		const { adults, kids } = numTravelers;
		const interests = getSelectedInterests();

		history.push({
			pathname: '/new/results',
			state: {
				result,
				dates,
				interests,
				adults,
				kids,
				city: itineraryCity,
			},
			prevpath: history.location.pathname,
		});
	}, [ambassadorsQuery]);

	useEffect(() => {
		calculateTime();
	}, [addedActivities]);

	useEffect(() => {
		// this condition means a booking was created
		if (!isEmpty(booking) && booking.id && !isEmpty(bookingData)) {
			history.push({
				pathname: `/booking/${booking.id}`,
				state: { bookingId: booking.id },
			});
		}
	}, [booking]);

	const setInitialActivities = (activities) => {
		if (isEmpty(activities)) return;
		const ids = incomingActivities.split(',');

		// find incoming activities objects and add them in the correct order
		ids.forEach((id, idx) => {
			let act = activities.find((act) => act.id === id);
			if (act) {
				act.added = true;
				act['order'] = idx;
			}
		});

		handleActivities(getAddedActivities(activities), ids);
	};

	const handleCountry = (country) => {
		if (!country) return;
		const cities = getCountryCities(country);
		localStorage.setItem('country', country);
		changeQueryParams('country', country);
		handleCity(cities[0].value);
		setCountry(country);
		setAddedActivities([]);
	};

	const handleCity = (city) => {
		if (!city) return;
		localStorage.setItem('city', city);
		changeQueryParams('city', city);
		setCity(city);
		setRunActivitiesQuery(true);
		setAddedActivities([]);
	};

	const handleInterests = (id) => {
		const interests = selectedInterests.includes(id)
			? remove(selectedInterests, (i) => id !== i)
			: union(selectedInterests, [id]);
		localStorage.setItem('interests', interests);
		changeQueryParams('interests', interests);
		setSelectedInterests([...interests]);
	};

	const handleActivities = (activities, ids) => {
		const activityIds = ids || activities.map((a) => a.id);
		localStorage.setItem('activities', activityIds);
		changeQueryParams('activities', activityIds);
		setAddedActivities(activities);
		dispatch(setUtilityActivities(activities));
	};

	const openActivity = () => {
		setIsDrawerOpen(true);
	};

	const closeActivity = () => {
		setIsDrawerOpen(false);
	};

	const toggleEditMode = () => {
		setEditMode(!editMode);
	};

	const toggleInterestsModal = () => {
		setIsInterestsModalOpen(!isInterestsModalOpen);
	};

	const toggleActionsModal = () => {
		setIsActionsModalOpen(!isActionsModalOpen);
	};

	const dismissAlert = () => setTimeLimitAlert(false);

	const addActivity = (activity) => {
		if (!canAddActivity(activity)) {
			setTimeLimitAlert(true);
			return;
		}

		toggleActivity(activity);
		setRemainingTime(remainingTime - activity.duration.total);
		dismissAlert();
	};

	const canAddActivity = (activity) => {
		const duration = activity.duration.total;
		return remainingTime - duration >= 0;
	};

	const deleteActivity = (id) => {
		const activity = queryActivities.find((act) => act.id === id);
		toggleActivity(activity);
		setRemainingTime(remainingTime + activity.duration.total);
		dismissAlert();
	};

	const toggleActivity = (activity) => {
		activity.added = !activity.added;
		activity['order'] = addedActivities.length;
		setQueryActivities([...queryActivities]);
		handleActivities(getAddedActivities());
	};

	const calculateTime = () => {
		if (isEmpty(addedActivities)) {
			setRemainingTime(PER_DAY_TIME_LIMIT);
			return;
		}

		let time = PER_DAY_TIME_LIMIT;
		addedActivities.forEach(
			({ duration }) => (time -= duration ? duration.total : 0)
		);
		setRemainingTime(time);
	};

	const getAddedActivities = (activities = queryActivities) => {
		const setIndices = (act, idx) => {
			act['order'] = idx;
			return act;
		};

		const addedAndSorted = activities
			.filter((act) => act.added)
			.sort((act1, act2) => compare(act1, act2))
			.map((act, idx) => setIndices(act, idx));
		return addedAndSorted;
	};

	const getFilteredActivities = () => {
		if (isEmpty(selectedInterests) || !isEmpty(ambassador)) {
			return queryActivities;
		}

		let intersection;
		const filtered = queryActivities
			.filter((act) => !act.added)
			.filter(({ categoryIds }) => {
				intersection = categoryIds.filter((cat) =>
					selectedInterests.includes(cat)
				);
				return !isEmpty(intersection);
			});
		return filtered;
	};

	const getSelectedInterests = () => {
		let interests = JSON.parse(JSON.stringify(INTERESTS));
		selectedInterests.forEach((i) => {
			interests[i]['selected'] = true;
		});
		return interests;
	};

	const getShareParams = () => {
		return {
			country: itineraryCountry,
			city: itineraryCity,
			dates,
			kids: numTravelers.kids,
			adults: numTravelers.adults,
			interests: selectedInterests,
			activities: addedActivities.map((act) => act.id),
			ambassador,
		};
	};

	const runMatch = async () => {
		setIsLoading(true);
		dispatch(setUtilityActivities(addedActivities));

		const activitiesIds = addedActivities.map((act) => act.id);
		const { adults, kids } = numTravelers;
		const interests = getSelectedInterests();

		const filters = {
			city: itineraryCity,
			dates,
			adults,
			kids,
			interests,
			activities: activitiesIds,
		};

		console.log({ filters });
		const params = getMatchQueryParams(filters);
		setQueryVariables(params);
		setRunMatchQuery(true);
	};

	const requestBooking = () => {
		setIsLoading(true);
		dispatch(setUtilityActivities(addedActivities));

		const { adults, kids } = numTravelers;
		const price = groupSizePrice(itineraryCity, adults + kids, dates);

		const bookingData = {
			ambassadorId: ambassador,
			price,
			dates,
			numAdults: adults,
			numChildren: kids,
			city: itineraryCity,
			travelerId: profile.uid,
			testerActivities: addedActivities.map((act) => act.id),
		};

		console.log({ bookingData });

		setBooking(bookingData);

		// avoid creating identical bookings
		if (areEqualBookings(booking, bookingData)) {
			history.push({
				pathname: `/booking/${booking.id}`,
				state: { bookingId: booking.id },
			});
		} else {
			dispatch(createBooking(bookingData));
		}
	};

	return (
		<div id="itinerary-utility-container">
			{!isEmpty(ambassador) ? (
				<>
					<h1 className="bolder">{ITINERARY_WITH_AMBASSADOR}</h1>
					<ProfileShortInfo
						ambassadorId={ambassador}
						setCountry={handleCountry}
						setCity={handleCity}
					/>
				</>
			) : (
				<>
					<h1 className="bolder">{ITINERARY_UTILITY_WELCOME}</h1>
					<p className="sub-heading">{ITINERARY_UTILITY_CTA}</p>
				</>
			)}

			<ActivityFilters
				country={itineraryCountry}
				city={itineraryCity}
				disabled={!isEmpty(ambassador)}
				toggleInterests={toggleInterestsModal}
				handleCountry={handleCountry}
				handleCity={handleCity}
				numInterests={selectedInterests.length}
				showInterests={isEmpty(ambassador)}
			/>

			<div className="section-container two-column-section">
				<div className="activity-list section-side left-side">
					<SuggestedActivitiesList
						activities={getFilteredActivities()}
						interests={selectedInterests}
						openActivity={openActivity}
						addActivity={addActivity}
						setActivity={setSelectedActivity}
						timeLimitAlert={timeLimitAlert}
						dismissAlert={dismissAlert}
						loading={queryLoading}
						error={queryError}
					/>
				</div>

				<div className="activity-list section-side right-side">
					<SelectedActivitiesList
						activities={queryActivities}
						openActivity={openActivity}
						setActivity={setSelectedActivity}
						deleteActivity={deleteActivity}
						addedActivities={addedActivities}
						handleActivities={handleActivities}
						remainingTime={remainingTime}
						editMode={editMode}
						toggleEditMode={toggleEditMode}
						toggleActionsModal={toggleActionsModal}
					/>

					{!isEmpty(ambassador) ? (
						<BtnLoader
							className="gradient squared narrow"
							onClick={requestBooking}
							loading={isLoading}
							loadingText=""
							text="Book Now!"
							disabled={isEmpty(dates) || numTravelers.adults < 1}
							disabledText="Make sure to choose your booking dates and number or travelers!"
						/>
					) : (
						<BtnLoader
							className="button-primary outlined squared narrow"
							onClick={runMatch}
							loading={isLoading}
							loadingText=""
							text="See our RedKnot Ambassadors"
							disabled={!itineraryCountry || !itineraryCity}
						/>
					)}
				</div>
			</div>

			<Drawer
				component={ActivityDescription}
				isVisible={isDrawerOpen && !isEmpty(selectedActivity)}
				onClose={closeActivity}
				addOption={true}
				activityInfo={selectedActivity}
				cloneActivity={() => toggleActivity(selectedActivity)}
			/>

			<HighOrderModal
				isOpen={isActionsModalOpen}
				component={Login}
				toggle={toggleActionsModal}
				fullScreen={false}
				actions={false}
				closeBtn={false}
				itineraryParams={getShareParams()}
				isAuthed={isAuthed}
				width={586}
				close={toggleActionsModal}
			/>

			<HighOrderModal
				toggle={toggleInterestsModal}
				isOpen={isInterestsModalOpen}
				width={1000}
				component={InterestsByCategory}
				onInterestClick={handleInterests}
				interests={selectedInterests}
			/>
		</div>
	);
};

export default ItineraryUtility;
