import React from 'react';
import brand from '../../assets/brand/brand-logo.png';
import './redKnotBrand.scss';

const RedKnotBrand = ({ target }) => {
	return (
		<a
			id="redknot-brand"
			target={target}
			rel="noopener noreferrer"
			href="https://goredknot.com"
		>
			<img src={brand} alt="RedKnot Logo"></img>
		</a>
	);
};

export default RedKnotBrand;
