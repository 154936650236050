import React, { useState } from 'react';
import { Button } from 'reactstrap';
import MultipleDatePicker from 'react-multiple-datepicker';
import { isEmpty } from 'lodash';

import AlertMsg from '../utils/Alert';
import { getDateRange, toDate, toMoment } from '../../utils/Dates';
import { BOOKING_DB_FORMAT } from '../../consts/DateFormats';
import { COVID_SUSPENSION } from '../../consts/Messages';
import {
	COVID_SUSPENSION_START,
	COVID_SUSPENSION_END,
} from '../../consts/Misc';

import './step4.scss';

const Step4 = ({ handleChange, openSearch, dates }) => {
	const [persistedDates, setDates] = useState(dates);

	// COVID specific
	const disabledDates = getDateRange(
		COVID_SUSPENSION_START,
		COVID_SUSPENSION_END
	);

	let formattedDates = [];
	if (!isEmpty(dates)) {
		formattedDates = toDate(dates);
	}

	const handleDatesInput = (dates) => {
		let formattedDates = toMoment(dates, BOOKING_DB_FORMAT);
		handleChange({
			name: 'dates',
			value: formattedDates,
		});
	};

	const toggleOpenSearch = () => {
		handleChange({
			name: 'openSearch',
			value: !openSearch,
		});

		if (!openSearch) {
			setDates(dates);
		}

		handleChange({
			name: 'dates',
			value: !openSearch ? [] : persistedDates,
		});
	};

	return (
		<div id="step-4" className="step-container">
			<div className="step-title-container">
				<div className="step-number">
					<span>3</span>
				</div>
				<div className="step-title">
					<h2>When would you like to travel with a RedKnot?</h2>
				</div>
			</div>
			<div className="options-container">
				<div
					className={
						openSearch
							? 'dates-container open-search'
							: 'dates-container'
					}
				>
					<MultipleDatePicker
						className={!isEmpty(dates) && 'filled'}
						minDate={new Date()}
						onSubmit={(dates) => {
							if (openSearch) {
								toggleOpenSearch();
							}
							handleDatesInput(dates);
						}}
						selectedDates={formattedDates}
						disabledDates={disabledDates}
					/>
					<AlertMsg
						content={COVID_SUSPENSION}
						color="warning"
						isOpen={true}
					></AlertMsg>
					<div className="no-dates-checkbox">
						<Button
							className="no-dates-button"
							active={openSearch}
							onClick={toggleOpenSearch}
						>
							I’m not sure of my travel dates, just match me with
							a RedKnot Ambassador
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Step4;
