import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import {
	ReactReduxFirebaseProvider,
	getFirebase,
	isLoaded,
} from 'react-redux-firebase';
import {
	createFirestoreInstance,
	reduxFirestore,
	getFirestore,
} from 'redux-firestore';
import { ApolloProvider } from '@apollo/client';

import * as serviceWorker from './serviceWorker';
import firebase from './firebase';
import apolloClient from './apollo';
import AnalyticsListener from './Analytics';

import App from './App';
import Loader from './components/utils/Loader';

import rootReducer from './store/reducers/rootReducer';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// redux store with firebase and firestore
const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore })];

//redux tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// add reduxFirestore store enhancer to store creator
const store = createStore(
	rootReducer,
	compose(
		composeEnhancers(
			applyMiddleware(...middlewares),
			reduxFirestore(firebase)
		)
	)
);

const rrfConfig = {
	userProfile: 'users',
	useFirestoreForProfile: true,
};

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

// auth and user profile load status check
function AuthIsLoaded({ children }) {
	const { auth, profile } = useSelector((state) => state.firebase);
	if (!isLoaded(auth) || !isLoaded(profile)) return <Loader />;
	return children;
}

ReactDOM.render(
	<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<BrowserRouter>
				<AuthIsLoaded>
					<ApolloProvider client={apolloClient}>
						<AnalyticsListener trackingId="UA-146230051-1">
							<App />
						</AnalyticsListener>
					</ApolloProvider>
				</AuthIsLoaded>
			</BrowserRouter>
		</ReactReduxFirebaseProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
