import React from 'react';
import { isEmpty } from 'lodash';

import UserPhoto from '../utils/UserPhoto';
import { INTERVIEWERS } from '../../consts/Interviewers';

import './interviewedByBox.scss';

const InterviewedByBox = ({ interviewer }) => {
	if (isEmpty(interviewer)) {
		return null;
	}

	const { name } = interviewer;

	return (
		<div className="interviewed-by-container">
			<a
				href="https://www.goredknot.com/about-us"
				target="_blank"
				rel="noopener noreferrer"
			>
				<div>
					<div>INTERVIEWED BY</div>
					<span>
						<b>{name === 'RK' ? 'RedKnot HQ Team' : name}</b>
					</span>
				</div>
				<UserPhoto size={30} image={INTERVIEWERS[name]} />
			</a>
		</div>
	);
};

export default InterviewedByBox;
