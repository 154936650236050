import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useSelector, useDispatch } from 'react-redux';
import arrayMove from 'array-move';

import {
	deleteActivity,
	updateActivitiesOrder,
	setTimePerDay,
} from '../../store/actions/activitiesActions';

import Drawer from './drawer/Drawer';
import ActivityTile from './ActivityTile';
import CreateActivity from './createActivity/CreateActivity';
import ActivityDescription from './activityDescription/ActivityDescription';
import TimeBar from './timeBar/TimeBar';

import { dragAndDropOrderSchema, deleteSchema } from '../../schemas/Activity';
import { getDayActivities } from '../utils/itinerary';
import { CREATE_ACTIVITY } from '../../consts/Messages';

import './itineraryActivities.scss';
import './animations/animations.scss';

function ItineraryActivities({
	selectActivity,
	selectedActivity,
	currentDay,
	editMode,
	setDownloadReady,
}) {
	const [currentActivities, setCurrentActivities] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [deleteAnimated, setDeleteAnimated] = useState(false); // flag to show animations only once
	const [createOpen, setCreateOpen] = useState(false);
	const [activityOpen, setActivityOpen] = useState(false);

	const {
		itineraryActivities,
		timePerDay,
		createActivityLoading,
	} = useSelector((state) => state.activities);
	const { itinerary } = useSelector((state) => state.itineraries);

	const dispatch = useDispatch();

	useEffect(() => {
		// calculate total time per day
		const calculateTimePerDay = () => {
			let timePerDay = {};
			itineraryActivities.forEach(({ day, duration }) => {
				timePerDay[day] = timePerDay[day] || 0;
				timePerDay[day] += duration.total;
			});
			dispatch(setTimePerDay(timePerDay));
		};

		// get current activities acording to the selected day tab
		const dayActivities = getDayActivities(currentDay, itineraryActivities);

		setCurrentActivities(dayActivities);
		setDeleteId(null);
		setDeleteAnimated(false);
		calculateTimePerDay();
	}, [itineraryActivities, currentDay]);

	useEffect(() => {
		setCreateOpen(createActivityLoading && createOpen);
	}, [createActivityLoading]);

	const showCreate = () => {
		setDownloadReady(false);
		setCreateOpen(true);
	};

	const hideCreate = () => {
		setDownloadReady(true);
		setCreateOpen(false);
	};

	const showActivity = () => {
		setActivityOpen(true);
	};

	const hideActivity = () => {
		setActivityOpen(false);
	};

	const deleteSelectedActivity = (activityId) => {
		setDeleteId(activityId);
		const { id } = itinerary;
		const activity = deleteSchema(
			itineraryActivities,
			activityId,
			currentDay,
			id
		);
		setTimeout(() => {
			setDeleteAnimated(true);
			dispatch(deleteActivity(activity));
		}, 800);
	};

	const getSortableList = () => {
		const SortableList = SortableContainer(() => {
			return (
				<ul>
					{currentActivities.map((activity, idx) => {
						const key = `sortable-item-${idx}`;
						return getSortableItem(key, idx, activity);
					})}
				</ul>
			);
		});

		return (
			<SortableList
				items={itineraryActivities}
				onSortEnd={onSortEnd}
				axis={'xy'}
				pressDelay={220}
			/>
		);
	};

	const getSortableItem = (key, idx, activity) => {
		const SortableItem = SortableElement(() => (
			<ActivityTile
				activity={activity}
				deleteId={deleteId}
				deleteAnimated={deleteAnimated}
				showActivity={showActivity}
				showEdit={showCreate}
				editMode={editMode}
				selectActivity={selectActivity}
				deleteActivity={deleteSelectedActivity}
			/>
		));

		return <SortableItem index={idx} key={key} />;
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const { id } = itinerary;
		const newActivities = arrayMove(currentActivities, oldIndex, newIndex);
		const updateObj = dragAndDropOrderSchema(
			newActivities,
			id,
			itineraryActivities
		);
		dispatch(updateActivitiesOrder(updateObj));
	};

	return (
		<>
			<TimeBar minutes={timePerDay[currentDay]} />
			<div id="activities-container">
				{getSortableList()}
				{activityOpen && (
					<Drawer
						component={ActivityDescription}
						isVisible={activityOpen}
						onClose={hideActivity}
						activityInfo={selectedActivity}
					/>
				)}
				{createOpen && (
					<Drawer
						component={CreateActivity}
						isVisible={createOpen}
						onClose={hideCreate}
						currentDay={currentDay}
						activityInfo={editMode ? selectedActivity : {}}
						currentAmountOfTime={timePerDay[currentDay]}
					/>
				)}
			</div>

			<button
				className="button-primary outlined narrow"
				onClick={() => {
					showCreate();
					selectActivity({});
				}}
			>
				{CREATE_ACTIVITY}
			</button>
		</>
	);
}

export default ItineraryActivities;
