import { MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { isEmpty } from 'lodash';
import { withLeaflet } from 'react-leaflet';

class calculateDriveTime extends MapLayer {
	createLeafletElement() {
		let coordinatesLeafletFormat = [];
		const {
			map,
			coordinates,
			getTimePerActivities,
			saveNewActivityTime,
			compareCoordinates,
		} = this.props;

		if (!isEmpty(compareCoordinates) && !isEmpty(coordinates)) {
			let lastIndex = coordinates.length - 1;
			let newOrEditCoordenates = [
				compareCoordinates,
				coordinates[lastIndex],
			];

			coordinatesLeafletFormat = newOrEditCoordenates.map((value) => {
				return L.latLng(value.lat, value.lng);
			});
		} else {
			coordinatesLeafletFormat = coordinates.map((value) => {
				return L.latLng(value.lat, value.lng);
			});
		}

		let leafletElement = L.Routing.control({
			waypoints: [...coordinatesLeafletFormat],
			show: false,
			createMarker: function (p1, p2) {},
		}).addTo(map.leafletElement);

		const getDistance = (start, end, instructions, id) => {
			let timeArray = instructions.filter((el) => {
				return el.index >= start && el.index <= end;
			});
			let totalTime = timeArray.reduce((a, b) => ({
				time: a.time + b.time,
			}));
			return { [id]: totalTime.time };
		};

		const getDistancesBetweenEachPoint = (
			waypointIndices,
			instructions
		) => {
			let time = [];
			waypointIndices.map((value, index) => {
				if (index < waypointIndices.length - 1) {
					let id = coordinates[index + 1].id;
					let distance = getDistance(
						value,
						waypointIndices[index + 1],
						instructions,
						id
					);
					time = { ...time, ...distance };
				}
				!isEmpty(compareCoordinates)
					? saveNewActivityTime(time)
					: getTimePerActivities(time);
			});
		};

		leafletElement.on('routesfound', (e) => {
			let distance = e.routes[0].summary.totalDistance;
			let time = e.routes[0].summary.totalTime;
			let instructions = e.routes[0].instructions;
			let waypoints = e.routes[0].waypointIndices;

			getDistancesBetweenEachPoint(waypoints, instructions);
		});
		var _updateLines = leafletElement._updateLines;

		leafletElement._updateLines = function (p1) {};
		return leafletElement.getPlan();
	}
}
export default withLeaflet(calculateDriveTime);
