import { concat, pickBy, includes } from 'lodash';

export const USER_FIELDS = ['photo', 'firstName', 'gender', 'bio'];
export const TRAVELER_FIELDS = ['countryOrigin', 'dob'];
export const AMBASSADOR_FIELDS = [
	'countryOrigin',
	'carType',
	// TODO: vehicle capacity
	// 'vehicle',
	'tagline',
	'languages',
	'places',
	'fav',
	'exampleDay',
	'lifestylePics',
	'availability',
];

export const FIELD_SCHEMA = {
	photo: {
		type: 'photo',
	},
	firstName: {
		label: 'Name',
	},
	gender: {
		label: 'Gender',
		type: 'select',
		caps: true,
		options: [
			{
				label: 'Male',
				value: 'male',
			},
			{
				label: 'Female',
				value: 'female',
			},
			{
				label: 'Other',
				value: 'other',
			},
		],
	},
	bio: {
		label: 'Tell us about yourself',
		type: 'textarea',
	},
	country: {
		label: 'Country',
		caps: true,
	},
	countryOrigin: {
		label: 'Country of origin',
		caps: true,
	},
	dob: {
		label: 'Date of birth',
		type: 'date',
	},
	carType: {
		label: 'Vehicle type',
		type: 'select',
		caps: true,
		options: [
			{
				label: 'Car (up to 4 free seats)',
				value: 'car',
			},
			{
				label: 'SUV (up to 10 free seats)',
				value: 'suv',
			},
			{
				label: 'Van (up to 14 free seats)',
				value: 'van',
			},
		],
	},
	// vehicle: {
	// 	label: 'Vehicle capacity (free seats)',
	// 	type: 'number',
	// },
	tagline: {
		label: 'Headline',
	},
	languages: {
		label: 'Languages you speak',
	},
	places: {
		label: "Places you've been",
		type: 'textarea',
	},
	fav: {
		label: 'Your favorite things to do in your country',
		type: 'textarea',
	},
	exampleDay: {
		label: 'What a day with you would look like',
	},
	lifestylePics: {
		label: 'Pictures of your lifestyle',
		type: 'gallery',
	},
	availability: {
		label: 'Your availability',
		type: 'datepicker',
	},

	info: {},
	activities: {},
};

export const getUserFields = (isAmbassador) => {
	const userFields = isAmbassador
		? concat(USER_FIELDS, AMBASSADOR_FIELDS)
		: concat(USER_FIELDS, TRAVELER_FIELDS);
	return userFields;
};

export const buildUserObject = (userData) => {
	const { isAmbassador } = userData;
	const userFields = getUserFields(isAmbassador);
	const user = pickBy(userData, (value, field) =>
		includes(userFields, field)
	);
	return user;
};
