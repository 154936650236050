import React, { useRef, useState } from 'react';
import { getShareUrl } from '../../utils/Urls';
import CustomBtn from '../utils/CustomBtn';
import Toast from '../utils/Toast';

import './sharelink.scss';

export default function ShareLink({ itineraryParams }) {
	const { country, city } = itineraryParams;
	const textAreaRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const copyToClipboard = (e) => {
		setIsOpen(true);
		textAreaRef.current.select();
		document.execCommand('copy');
		e.target.focus();
	};

	return (
		<div className="share-link-container">
			<div className="title-share-link">
				Share your itinerary with anyone!
			</div>
			<div className="subtitle-share-link">
				<p>
					With the public link, anyone on the web can view this
					itineray for testing, social sharing, and more.
				</p>
			</div>

			{country && city ? (
				<div className="share-controls">
					<input
						type="text"
						className="share-input overflow"
						value={getShareUrl(itineraryParams)}
						ref={textAreaRef}
					/>
					<CustomBtn
						text="Copy"
						onClick={copyToClipboard}
						width={77}
						className="button-primary outline-gray"
					/>
					<Toast
						position="top-center"
						message="Copied to the clipboard"
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						time={3000}
					/>
				</div>
			) : (
				<div className="share-alert">
					Please pick at least one activity and country
				</div>
			)}
		</div>
	);
}
