import React from 'react';
import moment from 'moment';

import { AVAILABILITY_DB_FORMAT } from '../../consts/DateFormats';
import './dates.scss';

const Dates = ({
	dates,
	orientation = 'column',
	formatFrom = AVAILABILITY_DB_FORMAT,
	formatTo = AVAILABILITY_DB_FORMAT,
}) => {
	const today = new Date().setHours(0, 0, 0, 0);

	switch (orientation) {
		case 'row':
			const datesLength = dates.length;

			return (
				<div className="dates-container">
					{dates.map((date, idx) => {
						if (new Date(date).getTime() < today) {
							return null;
						}
						return (
							<span key={date}>
								{moment(date, formatFrom).format(formatTo)}
								{idx < datesLength - 1 && ', '}
							</span>
						);
					})}
				</div>
			);

		case 'column':
		default:
			return (
				<div className="dates-container">
					{dates.map((date) => (
						<div key={date}>
							{moment(date, formatFrom).format(formatTo)}
						</div>
					))}
				</div>
			);
	}
};

export default Dates;
