import React, { useState, useEffect } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Search from 'react-leaflet-search';
import CalculateDriveTime from './CalculateDriveTime';
import { getTimePerActivities } from '../../../../store/actions/activitiesActions';
import { useSelector, useDispatch } from 'react-redux';

import './map.scss';

export default function MapDriveTime() {
	const rideCoordinates = useSelector(
		(state) => state.activities.activitiesCoordinates
	);
	const dispatch = useDispatch();

	const [isMapInit, setIsMapInit] = useState(false);
	const [currentMap, setCurrentMap] = useState(null);

	const saveMap = (map) => {
		setCurrentMap(map);
		setIsMapInit(true);
	};

	useEffect(() => {
		isMapInit ? setIsMapInit(false) : setIsMapInit(true);
	}, [rideCoordinates]);

	const saveTime = (time) => {
		dispatch(getTimePerActivities(time));
	};

	return (
		<Map
			center={[-89.92972, -87.84389]}
			zoom={20}
			ref={saveMap}
			id="leaflet-map"
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>

			{isMapInit && (
				<CalculateDriveTime
					map={currentMap}
					coordinates={rideCoordinates}
					getTimePerActivities={saveTime}
				/>
			)}
		</Map>
	);
}
