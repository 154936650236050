import Mailer from '../../utils/Mailer';
import ReactPixel from '../../utils/FbPixel';
import { getUserProfileName } from '../../utils/User';

export const emailSignup = (credentials) => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		const { name, email, password } = credentials;

		firebase
			.auth()
			.createUserWithEmailAndPassword(email, password)
			.then((result) => {
				ReactPixel.fbq('track', 'CompleteRegistrationEmail');

				const { user } = result;
				const { email, uid } = user;
				const firstName = name;

				const profile = {
					email,
					uid,
					firstName,
					isAmbassador: false,
					createdAt: new Date(),
				};

				firestore.set(
					{
						collection: 'users',
						doc: uid,
					},
					profile
				);

				dispatch({ type: 'SIGNUP_SUCCESS' });
				Mailer.sendUserSignupMessage(email);
			})
			.catch((error) => {
				dispatch({ type: 'SIGNUP_ERROR', error });
			});
	};
};

export const emailAuth = (credentials) => {
	return (dispatch, getState, { getFirebase }) => {
		const firebase = getFirebase();
		const { email, password } = credentials;

		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				dispatch({ type: 'SIGNIN_SUCCESS' });
			})
			.catch((error) => {
				dispatch({ type: 'SIGNIN_ERROR', error });
			});
	};
};

export const googleAuth = () => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		const provider = new firebase.auth.GoogleAuthProvider();

		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				if (result.additionalUserInfo.isNewUser) {
					ReactPixel.fbq('track', 'CompleteRegistrationGoogle');

					const { user } = result;
					const { email, uid, displayName } = user;
					const firstName = displayName;

					const profile = {
						email,
						uid,
						firstName,
						isAmbassador: false,
						createdAt: new Date(),
					};

					firestore.set(
						{
							collection: 'users',
							doc: uid,
						},
						profile
					);

					dispatch({ type: 'SIGNUP_SUCCESS' });
					Mailer.sendUserSignupMessage(email);
				} else {
					dispatch({ type: 'SIGNIN_SUCCESS' });
				}
			})
			.catch((error) => {
				dispatch({ type: 'SIGNIN_ERROR', error });
			});
	};
};

export const fbAuth = () => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();
		const provider = new firebase.auth.FacebookAuthProvider();

		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				if (result.additionalUserInfo.isNewUser) {
					ReactPixel.fbq('track', 'CompleteRegistrationFacebook');

					const { user } = result;
					const { email, uid, displayName } = user;
					const firstName = displayName || getUserProfileName(email);

					const profile = {
						email,
						uid,
						firstName,
						isAmbassador: false,
						createdAt: new Date(),
					};

					firestore.set(
						{
							collection: 'users',
							doc: uid,
						},
						profile
					);

					dispatch({ type: 'SIGNUP_SUCCESS' });
					Mailer.sendUserSignupMessage(email);
				} else {
					dispatch({ type: 'SIGNIN_SUCCESS' });
				}
			})
			.catch((error) => {
				dispatch({ type: 'SIGNIN_ERROR', error });
			});
	};
};

// TODO: send only if email exists, otherwise show alert to register
export const resetPassword = (email) => {
	return (dispatch, getState, { getFirebase }) => {
		const firebase = getFirebase();

		firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(function () {
				dispatch({ type: 'PASSWORD_RESET_LINK_SUCCESS' });
				alert(`A password recovery link was sent to ${email}!`);
			})
			.catch((error) => {
				dispatch({ type: 'PASSWORD_RESET_LINK_ERROR', error });
			});
	};
};

export const signOut = () => {
	return (dispatch, getState, { getFirebase }) => {
		localStorage.clear();
		const firebase = getFirebase();

		firebase
			.auth()
			.signOut()
			.then(() => {
				dispatch({ type: 'SIGNOUT_SUCCESS' });
				// history.push('/login');
			});
	};
};

export const resetError = () => {
	return (dispatch, getState, { getFirebase }) => {
		dispatch({ type: 'RESET_ERROR' });
	};
};
