export const cleanUrlToShare = (url) => {
	return (url = url.substring(0, url.indexOf('?')));
};

export const getUrlParameters = () => {
	var urls = {};
	const currentUrlParams = new URLSearchParams(window.location.search);
	for (var pair of currentUrlParams.entries()) {
		urls[pair[0]] = pair[1];
	}
	return urls;
};

export const getShareUrl = (data) => {
	const {
		country,
		city,
		kids = 0,
		adults = 1,
		dates = [],
		interests,
		activities,
		ambassador,
	} = data;
	let baseurl = `https://app.goredknot.com/itinerary-builder?ambassador=${ambassador}&country=${country}&city=${city}&kids=${kids}&adults=${adults}&dates=${dates}&interests=${interests}&activities=${activities}`;
	return baseurl;
};

export const changeQueryParams = (param, value) => {
	const history = window.history;
	let currentUrlParams = new URLSearchParams(window.location.search);
	currentUrlParams.set(param, value);

	const url = `${window.location.pathname}?${currentUrlParams.toString()}`;
	history.replaceState({}, '', url);
};

export const getGroupType = (group) => {
	switch (group) {
		case 1:
			return 'solo';
			break;
		case 2:
			return 'couple';
			break;
		default:
			return 'group';
	}
};
