import moment from 'moment';
import { isEmpty } from 'lodash';

export const calculateAge = (date) => {
	var years = moment().diff(date, 'years', false);
	return years;
};

export const isOfAge = (date) => {
	const years = calculateAge(date);
	if (years < 18) {
		alert('you must be 18 or higher');
	}
	return years >= 18;
};

export const isExtValid = (filePatch) => {
	let file = filePatch;
	let extensions = /(\.jpg|\.jpeg|\.png)$/i;
	if (!extensions.exec(file)) {
		alert('File extension not supported');
		return false;
	}
	return true;
};

export const getInvalidField = (inputFields) => {
	let invalidField;
	for (var index in inputFields) {
		if (isEmpty(inputFields[index])) {
			invalidField = index;
			break;
		}
	}
	return invalidField;
};
