import React from 'react';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import { PROFILE_PICS_STORAGE_REF } from '../../consts/Storage';
import UserPhoto from '../utils/UserPhoto';

const PhotoEdit = ({ uid, image, handleChange }) => {
	const handleUploadStart = (file) => {
		// TODO: handle unsuccessful case
		// const extValid = isExtValid(file.name);
		// setPhotoValid(extValid);
	};

	const onProfilePicUploaded = (filename) => {
		PROFILE_PICS_STORAGE_REF.child(filename)
			.getDownloadURL()
			.then((url) => {
				handleChange(url);
			});
	};

	return (
		<FileUploader
			accept="image/*"
			filename={uid}
			storageRef={PROFILE_PICS_STORAGE_REF}
			onUploadStart={handleUploadStart}
			onUploadSuccess={onProfilePicUploaded}
			hidden={true}
		>
			<div className="profile-photo">
				<UserPhoto size={150} image={image} position="top" />
				<div className="upload-photo-icon">
					<FontAwesomeIcon icon={faCamera} />
				</div>
			</div>
		</FileUploader>
	);
};

export default PhotoEdit;
