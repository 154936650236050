import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import UserPhoto from '../utils/UserPhoto';
import ChatWidget from '../utils/ChatWidget';
import './itineraryUserBar.scss';

const ItineraryUserBar = ({ isAmbassador, user, chatId }) => {
	if (isEmpty(user)) {
		return;
	}

	const { uid, photo, firstName } = user;
	return (
		<div className="itinerary-user-bar">
			<div className="user-role">
				{isAmbassador ? (
					<div>
						Traveling<br></br>RedKnot
					</div>
				) : (
					<div>
						RedKnot <br></br>Ambassador
					</div>
				)}
			</div>
			<div className="user-contact">
				{isAmbassador ? (
					<UserPhoto
						image={photo}
						name={firstName}
						size={50}
					></UserPhoto>
				) : (
					<Link to={`/user/${uid}`}>
						<UserPhoto
							image={photo}
							name={firstName}
							size={50}
						></UserPhoto>
					</Link>
				)}

				<ChatWidget chatId={chatId}></ChatWidget>
			</div>
		</div>
	);
};

export default ItineraryUserBar;
