import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';

import './chatWidget.scss';

const ChatWidget = ({
	chatId,
	size = '50px',
	backgroundColor = '#dc3545',
	text = 'Message',
}) => {
	return (
		<Link className="chat-widget" to={`/chats/${chatId}`}>
			<div
				className="widget-icon"
				style={{
					width: size,
					height: size,
					backgroundColor,
				}}
			>
				<FontAwesomeIcon
					icon={faCommentDots}
					color={'white'}
					size="2x"
				/>
			</div>
			{text && <div className="widget-text">{text}</div>}
		</Link>
	);
};

export default ChatWidget;
