import config from '../../config';
import ReactPixel from '../../utils/FbPixel';
import { buildBookingObject } from '../../schemas/Booking';

const { backendUrl, stripeKey } = config;

export const fetchBooking = (data) => {
	return (dispatch, getState) => {
		const id = data;

		try {
			fetch(`${backendUrl}/booking/?id=${id}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}).then(async (resp) => {
				const booking = await resp.json();
				// console.log('got booking with redux', booking);
				dispatch({ type: 'GET_BOOKING', booking });
			});
		} catch (error) {
			console.error(`Error fetching booking ${id}`, error);
		}
	};
};

export const createBooking = (data) => {
	return (dispatch, getState) => {
		const booking = buildBookingObject(data);

		// TODO: avoid create booking when it already exists or when user has a trip on (any of) those dates
		// create doesn't need to wait for API response
		dispatch({ type: 'CREATE_BOOKING', booking });
		ReactPixel.pageView();
		ReactPixel.fbq('track', 'Booking');

		const body = JSON.stringify(booking);
		try {
			fetch(`${backendUrl}/createBooking`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(async (res) => {
				const { id, ambassador } = await res.json();
				const updateData = {
					props: {
						id,
						ambassador,
					},
				};
				dispatch({
					type: 'UPDATE_BOOKING_DATA',
					data: updateData,
				});
			});
		} catch (error) {
			console.error(error);
		}
	};
};

export const updateBookingDetails = (data) => {
	return (dispatch, getState) => {
		// update doesn't need to wait for API response
		dispatch({ type: 'UPDATE_BOOKING_DATA', data });

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/updateBookingData`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(() => {
				// console.log('updated booking with redux', data);
			});
		} catch (error) {
			console.error(`Error updating booking ${data.id}`, error);
		}
	};
};

// TODO: concierge - add traveler
export const addTraveler = (data) => {
	return (dispatch, getState) => {
		// dispatch({ type: 'UPDATE_BOOKING_DATA', data });

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/addTravelerToBooking`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(() => {
				// console.log('added traveler to booking with redux', data);
			});
		} catch (error) {
			console.error(`Error updating booking ${data.id}`, error);
		}
	};
};

export const createCheckoutSession = (data) => {
	return (dispatch, getState) => {
		const body = JSON.stringify(data);
		const { id, bookingId } = data;

		try {
			fetch(`${backendUrl}/checkoutSession`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(async (resp) => {
				if (resp.ok) {
					const session = await resp.json();
					const sessionId = session.id;
					dispatch({ type: 'CREATE_CHECKOUT_SESSION', id });

					const stripe = window.Stripe(stripeKey);
					stripe.redirectToCheckout({ sessionId }).then((result) => {
						const { error } = result;
						if (error) {
							alert(error.message);
							console.error(
								`Error redirecting to checkout session ${sessionId}`,
								error
							);
						}
					});
				} else {
					console.error(
						`Error creating checkout session for booking ${bookingId}`,
						resp
					);
				}
			});
		} catch (error) {
			console.error(
				`Error creating checkout session for booking ${bookingId}`,
				error
			);
		}
	};
};

export const updateAvailability = (data) => {
	return (dispatch, getState) => {
		dispatch({ type: 'UPDATE_AVAILABILITY', data });

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/updateAvailability`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			});
		} catch (error) {
			console.error(error);
		}
	};
};

// TODO: on create booking, CHECK if booking already exists
