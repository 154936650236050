import React, { useState } from 'react';
import GroupSizeHandler from '../utils/GroupSizeHandler';
import './pricingBtn.scss';

export default function PricingBtn({
	adults,
	kids,
	carType,
	handleChange,
	handleValidation,
	disabled,
}) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div id="group-controls">
			{isOpen && (
				<div className="group-controles-user-profile">
					<GroupSizeHandler
						adults={adults}
						kids={kids}
						carType={carType}
						handleChange={handleChange}
						handleValidation={handleValidation}
						disabled={disabled}
					></GroupSizeHandler>
				</div>
			)}

			<button
				className="button-primary gradient round large"
				onClick={() => setIsOpen(!isOpen)}
			>
				Number of Travelers
			</button>
		</div>
	);
}
