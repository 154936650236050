import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { connect, useSelector } from 'react-redux';

import { signOut } from '../../store/actions/authActions';

const SignedInLinks = ({ toggle, isOpen, signOut }) => {
	const { profile } = useSelector((state) => state.firebase);

	const { isAmbassador } = profile;
	const { firstName } = profile;

	return (
		<div onClick={() => toggle(!isOpen)}>
			<NavItem>
				<Link to="/profile">
					<NavLink>Home</NavLink>
				</Link>
			</NavItem>
			{!isAmbassador &&
				// dont allow new trip until name is provided
				(firstName ? (
					<NavItem>
						<Link to="/new">
							<NavLink>New Trip</NavLink>
						</Link>
					</NavItem>
				) : (
					<NavItem>
						<Link
							onClick={(e) => {
								alert(
									'First please tell us a bit about yourself!'
								);
							}}
							to="/profile/edit"
						>
							<NavLink>New Trip</NavLink>
						</Link>
					</NavItem>
				))}
			<NavItem>
				<Link to="/profile">
					<NavLink>My Profile</NavLink>
				</Link>
			</NavItem>
			<NavItem>
				<Link to="/trips">
					<NavLink>My Trips</NavLink>
				</Link>
			</NavItem>
			<div className="nav-footer">
				<NavItem>
					<NavLink onMouseDown={signOut}>Logout</NavLink>
				</NavItem>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(signOut()),
	};
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
