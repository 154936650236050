import React from 'react';
import { Progress } from 'reactstrap';
import {
	convertToHours,
	getTimeFormatProgressBar,
} from '../../utils/itinerary';

import './timeBar.scss';

export default function TimeBar({ minutes }) {
	const totalTime = convertToHours(minutes);
	return (
		<div className="time-bar-container">
			<div className="bar-text">
				<span>{`${getTimeFormatProgressBar(totalTime)}/8 hours`}</span>
			</div>
			<Progress
				value={totalTime.slice(0, totalTime.indexOf(':'))}
				max="8"
			></Progress>
		</div>
	);
}
