import React from 'react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './groupSizeInputs.scss';

const GroupSizeInputs = ({ handleChange, adults, kids, disabled }) => {
	const increaseAdults = () => {
		let nAdults = parseInt(adults) || 0;
		const nKids = parseInt(kids) || 0;
		const numTravelers = nAdults + nKids;
		if (numTravelers > 13) {
			return;
		}
		nAdults += 1;
		handleChange({
			name: 'adults',
			value: nAdults,
		});
		handleChange({
			name: 'numTravelers',
			value: numTravelers + 1,
		});
	};

	const increaseKids = () => {
		let nKids = parseInt(kids) || 0;
		const nAdults = parseInt(adults) || 0;
		const numTravelers = nAdults + nKids;
		if (numTravelers > 13) {
			return;
		}
		nKids += 1;
		handleChange({
			name: 'kids',
			value: nKids,
		});
		handleChange({
			name: 'numTravelers',
			value: numTravelers + 1,
		});
	};

	const decreaseAdults = () => {
		let nAdults = parseInt(adults) || 0;
		const nKids = parseInt(kids) || 0;
		const numTravelers = nAdults + nKids - 1;
		if (nAdults === 0) {
			return;
		}
		nAdults -= 1;
		handleChange({
			name: 'adults',
			value: nAdults,
		});
		handleChange({
			name: 'numTravelers',
			value: numTravelers,
		});
	};

	const decreaseKids = () => {
		let nKids = parseInt(kids) || 0;
		const nAdults = parseInt(adults) || 0;
		const numTravelers = nAdults + nKids - 1;
		if (nKids === 0) {
			return;
		}
		nKids -= 1;
		handleChange({
			name: 'kids',
			value: nKids,
		});
		handleChange({
			name: 'numTravelers',
			value: numTravelers,
		});
	};

	return (
		<div className="group-size-inputs-container">
			<p>Adults</p>
			<div className="number-input">
				<span className="btn-decrease" onClick={decreaseAdults}>
					<FontAwesomeIcon icon={faMinus} />
				</span>
				<input
					type="number"
					placeholder="Adults"
					name="adults"
					value={parseInt(adults) || ''}
					className="num-travelers"
					disabled
				></input>
				<span
					className={`btn-increase ${disabled ? 'disabled' : ''}`}
					onClick={!disabled && increaseAdults}
				>
					<FontAwesomeIcon icon={faPlus} />
				</span>
			</div>
			<p>Kids</p>
			<div className="number-input">
				<span className="btn-decrease" onClick={decreaseKids}>
					<FontAwesomeIcon icon={faMinus} />
				</span>
				<input
					type="number"
					placeholder="Kids"
					name="kids"
					value={parseInt(kids) || ''}
					className="num-travelers"
					disabled
				></input>
				<span
					className={`btn-increase ${disabled ? 'disabled' : ''}`}
					onClick={!disabled && increaseKids}
				>
					<FontAwesomeIcon icon={faPlus} />
				</span>
			</div>
		</div>
	);
};
export default GroupSizeInputs;
