import { includes, isEmpty } from 'lodash';
import {
	NORMAL_PRICE_ADD_ON,
	SPECIAL_PRICE_ADD_ON,
	NORMAL_MAX_ADD_ON,
	SPECIAL_MAX_ADD_ON,
	NORMAL_BASE_PRICE,
	SPECIAL_BASE_PRICE,
	GROUP_SIZE_LIMIT,
	NORMAL_PRICE_COUNTRIES,
} from '../consts/Pricing';
import { getCountryByCity } from '../consts/Countries';

const normalGroupPrice = (travelers) => {
	let addOn = 0;

	for (let i = 0; i < travelers; i++) {
		addOn +=
			i < GROUP_SIZE_LIMIT ? NORMAL_PRICE_ADD_ON[i] : NORMAL_MAX_ADD_ON;
	}

	return NORMAL_BASE_PRICE + addOn;
};

const specialGroupPrice = (travelers) => {
	let addOn = 0;

	for (let i = 0; i < travelers; i++) {
		addOn +=
			i < GROUP_SIZE_LIMIT ? SPECIAL_PRICE_ADD_ON[i] : SPECIAL_MAX_ADD_ON;
	}

	return SPECIAL_BASE_PRICE + addOn;
};

export const groupSizePrice = (city, numTravelers, dates) => {
	if (numTravelers < 1) return 0;

	const numDays = isEmpty(dates) ? 1 : dates.length;
	const country = getCountryByCity(city);
	let groupPrice;

	if (includes(NORMAL_PRICE_COUNTRIES, country)) {
		groupPrice = normalGroupPrice(numTravelers);
	} else {
		groupPrice = specialGroupPrice(numTravelers);
	}

	return groupPrice * numDays;
};
