const initState = {
	chat: null,
};

const chatReducers = (state = initState, action) => {
	switch (action.type) {
		case 'GET_CHAT':
			const { chat } = action;
			return {
				...state,
				chat,
			};

		case 'CREATE_MESSAGE':
			const { message } = action.data;
			let tmp = state.chat;
			tmp.messages.push(message);

			return {
				...state,
				chat: tmp,
			};

		default:
			return state;
	}
};

export default chatReducers;
