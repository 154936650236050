import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const BtnLoader = ({
	loading,
	disabled = false,
	text,
	icon,
	disabledText = text,
	disabledIcon,
	loadingText = 'Loading...',
	onClick,
	className = '',
}) => {
	return (
		<Button
			onClick={onClick}
			className={`button-primary ${className}`}
			disabled={loading || disabled}
		>
			{loading ? (
				<>
					<FontAwesomeIcon icon={faSpinner} spin />
					<span>{loadingText}</span>
				</>
			) : disabled ? (
				<>
					<span>{disabledText}</span>
					{disabledIcon && (
						<FontAwesomeIcon
							icon={disabledIcon}
							style={{ marginLeft: 5 }}
						/>
					)}
				</>
			) : (
				<>
					{icon && <FontAwesomeIcon icon={icon} />}
					<span>{text}</span>
				</>
			)}
		</Button>
	);
};

export default BtnLoader;
