import { pickBy, includes, isEqual } from 'lodash';

export const BOOKING_FIELDS = [
	'numAdults',
	'numChildren',
	'price',
	'city',
	'dates',
	'ambassadorId',
	'travelerId',
	'testerActivities',
];

export const buildBookingObject = (bookingData) => {
	const booking = pickBy(bookingData, (value, field) =>
		includes(BOOKING_FIELDS, field)
	);
	return booking;
};

export const areEqualBookings = (booking1, booking2) => {
	const schema1 = buildBookingObject(booking1);
	const schema2 = buildBookingObject(booking2);
	return isEqual(schema1, schema2);
};
