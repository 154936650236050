import React from 'react';
import { isEmpty } from 'lodash';
import { COUNTRIES } from '../../consts/Countries';
import './step1.scss';

const Step1 = ({ handleChange, country }) => {
	const selectCountry = (country) => {
		const currentCountry = localStorage.getItem('country') || '';

		handleChange({
			name: 'country',
			value: country,
		});

		// wipe only city if a different country is selected
		if (!isEmpty(currentCountry) && currentCountry === country) {
			return;
		}

		handleChange({
			name: 'city',
			value: '',
		});
	};

	return (
		<div id="step-1" className="step-container">
			<div className="step-title-container">
				<div className="step-number">
					<span>1</span>
				</div>
				<div className="step-title">
					<h2>Tell us where you are traveling</h2>
				</div>
			</div>
			<div className="options-container">
				{COUNTRIES.map((item) => {
					return (
						<div
							key={item.value}
							className={`country-box ${
								item.value === country ? 'active' : ''
							}`}
							onClick={() => {
								selectCountry(item.value);
							}}
							style={{
								backgroundImage: `url('${item.img}')`,
							}}
						>
							<span>{item.value}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Step1;
