import React from 'react';
import { Label } from 'reactstrap';
import { isEmpty } from 'lodash';

import Gallery from '../utils/Gallery';
import Dates from '../utils/Dates';

import './userField.scss';

const UserField = ({ className, slug, type, label, value, caps }) => {
	if (isEmpty(value)) {
		return null;
	}

	if (slug === 'exampleDay') {
		const hours = Object.keys(value);

		return (
			<div className="user-profile-field">
				{label && <Label>{label}</Label>}
				<div className={className}>
					{hours.map((time) => {
						return (
							<div key={time}>
								<span>{time}</span>
								<p>{value[time]}</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	switch (type) {
		case 'gallery':
			return (
				<div className="user-profile-field">
					{label && <Label>{label}</Label>}
					<Gallery images={value} />
				</div>
			);

		case 'datepicker':
			const dates = Object.keys(value);

			return (
				<div className="user-profile-field">
					{label && <Label>{label}</Label>}
					{/* reverse to show them in desc order*/}
					<Dates dates={dates.reverse()} orientation="row" />
				</div>
			);

		default:
			return (
				<div className="user-profile-field">
					{label && <Label>{label}</Label>}
					<p
						className={className}
						style={caps && { textTransform: 'capitalize' }}
					>
						{value}
					</p>
				</div>
			);
	}
};

export default UserField;
