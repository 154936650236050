import React from 'react';
import { isEmpty } from 'lodash';
import './interestsInfoBox.scss';

const InterestsInfoBox = ({ hasCommon, userName, list, interests }) => {
	if (isEmpty(list)) {
		return null;
	}

	const listLength = list.length;

	return (
		<div className="interests-box-container">
			{hasCommon ? (
				<span>Common interests with {userName}:</span>
			) : (
				<span>{userName}'s top interests:</span>
			)}
			<p>
				{list.map((key, idx) => {
					if (interests[key]) {
						const interest = interests[key];
						const name = interest.name;
						return (
							<span>
								{name}
								{idx !== listLength - 1 && ', '}
							</span>
						);
					}
					return null;
				})}
			</p>
		</div>
	);
};

export default InterestsInfoBox;
