import { pickBy, includes } from 'lodash';

export const ITINERARY_FIELDS = [
	'ambassadorId',
	// 'agentId',
	'bookingId',
	'city',
	'days',
	'numAdults',
	'numChildren',
	'travelerId',
];

export const buildItineraryObject = (itineraryData) => {
	const itinerary = pickBy(itineraryData, (value, field) =>
		includes(ITINERARY_FIELDS, field)
	);
	return itinerary;
};
