import React from 'react';
import { isEmpty, filter, take, chunk } from 'lodash';

import InterestBar from './InterestBar';
import { INTERESTS } from '../../consts/Interests';
import './interestsBars.scss';

const InterestsBars = ({ categoryScores }) => {
	if (isEmpty(categoryScores)) {
		return null;
	}

	const categoriesIds = Object.keys(categoryScores);
	// filter by currently used interests
	const categories = filter(categoriesIds, (key) => {
		return INTERESTS[key];
	});

	if (isEmpty(categories)) {
		return null;
	}

	// order interests by score desc
	categories.sort((k1, k2) => {
		return categoryScores[k2] - categoryScores[k1];
	});

	// take first 6 interests and split into two groups for easier display
	const topCategories = take(categories, 6);
	const splitCategories = chunk(topCategories, 3);
	const main = splitCategories[0];
	const secondary = splitCategories[1];

	const getValue = (id) => {
		const value = ((categoryScores[id] / 50) * 100).toFixed();
		return value;
	};

	return (
		<div className="interests-bars-container">
			<div className="main-title">Things I am passionate about</div>
			<div className="interests-row">
				<div className="main-interests-column">
					{main.map((id) => (
						<InterestBar interestId={id} value={getValue(id)} />
					))}
				</div>
				<div className="secondary-interests-column">
					{!isEmpty(secondary) &&
						secondary.map((id) => (
							<InterestBar interestId={id} value={getValue(id)} />
						))}
				</div>
			</div>
		</div>
	);
};

export default InterestsBars;
