import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { isEmpty, partition } from 'lodash';
import { connect } from 'react-redux';

import { getItineraries } from '../../store/actions/itinerariesActions';

import TripsList from './TripsList';
import Loader from '../utils/Loader';
import Hero from '../utils/Hero';

import HeroBg from '../../assets/trips/trips-list-hero.jpg';

import './trips.scss';

// TODO: refactor to functional component
class Trips extends Component {
	constructor(props) {
		super();

		const { auth, getItineraries } = props;
		const { uid } = auth;

		getItineraries({ uid });
	}

	render() {
		const { history, itineraries } = this.props;

		if (!itineraries) {
			return <Loader />;
		}

		if (isEmpty(itineraries)) {
			return (
				<>
					<p>You have no trips yet.</p>
					<Link to="/new">
						<Button className="button-primary">New Trip</Button>
					</Link>
				</>
			);
		}

		// past and upcoming trips partition
		const tripsPartition = partition(
			itineraries,
			(itinerary) => itinerary.hasPassed
		);
		const pastTrips = tripsPartition[0];
		const upcomingTrips = tripsPartition[1];

		return (
			<>
				<Hero
					image={HeroBg}
					title="My RedKnot Trips"
					fullWidth={false}
				></Hero>
				<div
					id="trips-container"
					className="section-container two-column-section"
				>
					<div className="section-side left-side">
						<TripsList
							trips={upcomingTrips}
							title="Upcoming Trips"
							history={history}
						></TripsList>
					</div>
					<div className="section-side right-side">
						<TripsList
							trips={pastTrips}
							title="Past Trips"
							history={history}
						></TripsList>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const { itineraries } = state.itineraries;
	const { auth } = state.firebase;
	return { itineraries, auth };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getItineraries: (data) => dispatch(getItineraries(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Trips);
