import {
	faSwimmer,
	faHiking,
	faLeaf,
	faCocktail,
	faCamera,
	faUtensils,
	faAppleAlt,
	faDumbbell,
	faTags,
	faArchway,
	faFootballBall,
	faDove,
	faMosque,
	faChurch,
	faPalette,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';

import Cathedral from '../assets/icons/cathedral.png';
import Camera from '../assets/icons/camera.png';
import Local from '../assets/icons/local.png';
import Hiking from '../assets/icons/hiking.png';

export const INTERESTS_CATEGORIES = [
	{ name: 'History and Culture', icon: Cathedral },
	{ name: 'Outdoor Activities', icon: Hiking },
	{ name: 'Local Life', icon: Local },
	{ name: 'Hobbies and Others', icon: Camera },
];

// TODO: handle order here instead of in the DB
export const INTERESTS = {
	Ei95oyO6kPQntGX0qRnO: {
		name: 'Surfing and Other Water Sports',
		icon: faSwimmer,
		category: 'Outdoor Activities',
	},
	JQrfgqO4ZEW4mmgMQBEg: {
		name: 'Hiking and Walks',
		icon: faHiking,
		category: 'Outdoor Activities',
	},
	DQoQMSAjltFfjB1p3uqN: {
		name: 'Wildlife and Nature',
		icon: faLeaf,
		category: 'Outdoor Activities',
	},
	t0yBSawiUs5cumRvZ8Nz: {
		name: 'Wellness and Exercise',
		icon: faDumbbell,
		category: 'Hobbies and Others',
	},
	D5eeFRpD7fx9s8Y4v49x: {
		name: 'Bars and Nightlife',
		icon: faCocktail,
		category: 'Local Life',
	},
	HiPMLgADzGjV7i4oFmmu: {
		name: 'Food and Cooking',
		icon: faUtensils,
		category: 'Local Life',
	},
	DpKQ3Ldh7DymDGidQXlu: {
		name: 'Photography',
		icon: faCamera,
		category: 'Hobbies and Others',
	},
	Gw7ktFK4PzCT6mcsRDV4: {
		name: 'Local Artisans and Markets',
		icon: faAppleAlt,
		category: 'Local Life',
	},
	QHiLPQzOHNue9ixM6fXB: {
		name: 'Shopping and Fashion',
		icon: faTags,
		category: 'Local Life',
	},
	KB67tdt57U2QQrSCSrcu: {
		name: 'Unique & Unusual Places',
		icon: faArchway,
		category: 'History and Culture',
	},
	P3ghJW1Za8G29kvc87py: {
		name: 'Sporting Events',
		icon: faFootballBall,
		category: 'Local Life',
	},
	QcbZbhVyxc3vXUS6oHOd: {
		name: 'Ethical Travel and Volunteering',
		icon: faDove,
	},
	vaHYsgHjVvO0zAcXrzmi: {
		name: 'Major Sites, Architecture and Orientation',
		icon: faMosque,
	},
	MPFfI1RKzee8r0tp8FUf: {
		name: 'Historical and Religious Sites',
		icon: faChurch,
		category: 'History and Culture',
	},
	MlLpwYavOtrk226VX2px: {
		name: 'Art, Culture and Museums',
		icon: faPalette,
		category: 'History and Culture',
	},
	f0lL8XvpmU5z0H70z7sx: {
		name: 'Family and kids activities',
		icon: faUsers,
	},
};
