import ElSalvador from '../assets/WizardForm/countries/ElSalvador.jpg';
import Mexico from '../assets/WizardForm/countries/Mexico.jpg';
import Guatemala from '../assets/WizardForm/countries/Guatemala.jpg';
import CostaRica from '../assets/WizardForm/countries/CostaRica.jpg';
import Guanacaste from '../assets/WizardForm/cities/Guanacaste.jpg';
import SanJose from '../assets/WizardForm/cities/Sanjose.jpg';
import MexicoCity from '../assets/WizardForm/cities/MexicoCity.jpg';
import LaLibertad from '../assets/WizardForm/cities/LaLibertad.jpg';
import SanSalvador from '../assets/WizardForm/cities/SanSalvador.jpg';
import GuatemalaCity from '../assets/WizardForm/cities/GuatemalaCity.jpg';

// TODO: refactor to better data structures for caountries and cities
export const COUNTRIES = [
	{
		id: '1',
		country: 'México',
		value: 'México',
		img: Mexico,
	},
	{
		id: '2',
		country: 'El Salvador',
		value: 'El Salvador',
		img: ElSalvador,
	},
	{
		id: '3',
		country: 'Guatemala',
		value: 'Guatemala',
		img: Guatemala,
	},
	{
		id: '4',
		country: 'Costa Rica',
		value: 'Costa Rica',
		img: CostaRica,
	},
];

export const CITIES = {
	'El Salvador': [
		{ value: 'San Salvador', img: SanSalvador },
		{ value: 'La Libertad', img: LaLibertad },
	],
	México: [{ value: 'Mexico City', img: MexicoCity }],
	Guatemala: [{ value: 'Guatemala City', img: GuatemalaCity }],
	'Costa Rica': [
		{ value: 'San José', img: SanJose },
		{ value: 'Guanacaste', img: Guanacaste },
	],
};

export const CITIES_MAP = {
	'San Salvador': {
		country: 'El Salvador',
		asset: SanSalvador,
		defaultLocation: { lat: 13.68935, lng: -89.18718 },
	},
	'La Libertad': {
		country: 'El Salvador',
		asset: LaLibertad,
		defaultLocation: { lat: 13.48333, lng: -89.31667 },
	},
	'Mexico City': {
		country: 'México',
		asset: MexicoCity,
		defaultLocation: { lat: 19.42847, lng: -99.12766 },
	},
	'México City': {
		country: 'México',
		asset: MexicoCity,
		defaultLocation: { lat: 19.42847, lng: -99.12766 },
	},
	'Guatemala City': {
		country: 'Guatemala',
		asset: GuatemalaCity,
		defaultLocation: { lat: 15.783471, lng: -90.230759 },
	},
	'San José': {
		country: 'Costa Rica',
		asset: SanJose,
		defaultLocation: { lat: 9.748917, lng: -83.753428 },
	},
	Guanacaste: {
		country: 'Costa Rica',
		asset: Guanacaste,
		defaultLocation: { lat: 10.4958, lng: -85.355 },
	},
};

export const getCountryCities = (country) => {
	return CITIES[country] || [];
};

export const getCountryByCity = (city) => {
	const cityData = CITIES_MAP[city];
	const country = cityData ? cityData.country : '';
	return country;
};

export const getDefaultLocation = (city) => {
	const cityData = CITIES_MAP[city];
	const country = cityData ? cityData.defaultLocation : '';
	return country;
};
