import config from '../../config';
import { activityStructure } from '../../schemas/Activity';
import { getDayActivities } from '../../components/utils/itinerary';

const { backendUrl } = config;

export const getActivities = (data) => {
	let activities = data;
	return (dispatch) => {
		dispatch({ type: 'GET_ACTIVITIES', activities });
	};
};

export const getActivitiesCoordinates = (activities, day) => {
	const dayActivities = getDayActivities(day, activities);
	let coordinates = [];
	dayActivities.map((value) => {
		if (value.location.lat) {
			const { id } = value;
			let lat = value.location.lat;
			let lng = value.location.lng;
			coordinates.push({ lat, lng, id });
		}
	});
	return (dispatch) => {
		dispatch({ type: 'GET_ACTIVITIES_COORDINATES', coordinates });
	};
};

export const getTimePerActivities = (data) => {
	let time = data;
	return (dispatch) => {
		dispatch({ type: 'GET_TIME_PER_ACTIVITIES', time });
	};
};

export const getNewActivityTime = (data) => {
	let time = data;
	return (dispatch) => {
		dispatch({ type: 'GET_NEW ACTIVITY_TIME', time });
	};
};

export const createActivity = (data) => {
	return (dispatch, getState) => {
		const { day } = data;
		const { activities } = getState();
		const { itineraryActivities } = activities;

		let loading = true;
		dispatch({ type: 'CREATE_ACTIVITY_LOADING', loading });
		dispatch({ type: 'CLONE_ACTIVITY_LOADING', loading });

		// always set order when creating the activity
		const dayActivities = getDayActivities(day, itineraryActivities);
		const order = dayActivities.length;
		data['order'] = order;

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/createItineraryActivity`, {
				method: 'POST',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			})
				.then(function (response) {
					return response.json();
				})
				.then((response) => {
					data['id'] = response.id;
					const newActivities = [...itineraryActivities, data];

					loading = false;
					dispatch({
						type: 'CREATE_ACTIVITY_SUCCESS',
						newActivities,
					});
					dispatch({ type: 'CREATE_ACTIVITY_LOADING', loading });
					dispatch({ type: 'CLONE_ACTIVITY_LOADING', loading });
				});
		} catch (error) {
			console.error(`Error creating the activity `, error);
		}
	};
};

export const deleteActivity = (deleteInformation) => {
	const { data, activityId, itineraryId } = deleteInformation;
	return (dispatch) => {
		dispatch({ type: 'DELETE_ACTIVITY_SUCCESS', data });

		const body = JSON.stringify({ activityId, itineraryId });
		try {
			fetch(`${backendUrl}/deleteItineraryActivity`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			});
		} catch (error) {
			console.error(`Error deleting the activity `, error);
		}
	};
};

export const updateActivity = (data, oldState) => {
	return (dispatch, getState) => {
		const { activities } = getState();
		const { itineraryActivities } = activities;

		const updated = itineraryActivities.map((value) => {
			if (value.id === data.activityId) {
				return { ...value, ...activityStructure(data) };
			}
			return value;
		});

		dispatch({ type: 'UPDATE_ACTIVITY_SUCCESS', updated });

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/updateItineraryActivity`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			});
		} catch (error) {
			console.error(`Error updating the activity `, error);
		}
	};
};

export const setTesterActivities = (activityIds) => {
	return (dispatch) => {
		dispatch({ type: 'SET_TESTER_ACTIVITIES', activityIds });
	};
};

export const setTimePerDay = (timePerDay) => {
	return (dispatch) => {
		dispatch({ type: 'UPDATE_TIME_PER_DAY', timePerDay });
	};
};

export const updateActivitiesOrder = (data) => {
	let { newActivities, ...dragAndDropOrder } = data;
	return (dispatch) => {
		// dispatch doesn't need to wait for response
		dispatch({ type: 'UPDATE_ORDER_SUCCESS', newActivities });

		const body = JSON.stringify(dragAndDropOrder);
		try {
			fetch(`${backendUrl}/updateItineraryActivities`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			});
		} catch (error) {
			console.error(`Error updating activities order`, error);
		}
	};
};

export const addItineraryActivities = (data) => {
	return (dispatch, getState) => {
		const { activities } = getState();
		const { itineraryActivities } = activities;

		const body = JSON.stringify(data);
		try {
			fetch(`${backendUrl}/addItineraryActivities`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(async (response) => {
				const testerActivities = await response.json();
				const newActivities = [
					...itineraryActivities,
					...testerActivities,
				];
				dispatch({
					type: 'ADD_ITINERARY_ACTIVITIES_SUCCESS',
					newActivities,
				});
			});
		} catch (error) {
			console.error(`Error adding itinerary activities`, error);
		}
	};
};

export const setUtilityActivities = (activities) => {
	return (dispatch) => {
		dispatch({ type: 'SET_UTILITY_ACTIVITIES', activities });
	};
};

export const sendItineraryPdf = (email, activities, ambassador) => {
	return (dispatch, getState) => {
		const sendSuccesPdf = true;
		const body = JSON.stringify({ email, activities, ambassador });
		try {
			fetch(`${backendUrl}/sendItineraryPdf`, {
				method: 'POST',
				mode: 'no-cors',
				headers: { 'Content-Type': 'application/json' },
				body,
			}).then(async (response) => {
				dispatch({ type: 'SEND_PDF_SUCCESS', sendSuccesPdf });
			});
		} catch (error) {
			console.error(`Error sending PDF `, error);
		}
	};
};
