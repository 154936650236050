import React, { Component } from 'react';
import { Progress, Button } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { isEmpty, split } from 'lodash';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

import AlertMsg from '../utils/Alert';
import BtnLoader from '../utils/BtnLoader';

import { getUrlParameters, getGroupType } from '../../utils/Urls';
import { filterInterests } from '../../utils/Interests';

import { setTesterActivities } from '../../store/actions/activitiesActions';

import { AMBASSADOR_SEARCH } from '../../consts/Queries';

import './newTrip.scss';

class NewTrip extends Component {
	constructor(props) {
		super(props);
		const { location, history, profile, interests } = props;

		if (profile.isAmbassador) {
			history.push('/profile');
		}

		const country = localStorage.getItem('country') || '';
		const city = localStorage.getItem('city');
		const option = localStorage.getItem('option') || '';
		const adults = parseInt(localStorage.getItem('adults'));
		const kids = parseInt(localStorage.getItem('kids'));
		const numTravelers =
			parseInt(localStorage.getItem('numTravelers')) || 0;
		// HACK: remove old dates giving problems
		// const persistedDates = localStorage.getItem('dates') || [];
		const persistedDates = [];
		const openSearch =
			JSON.parse(localStorage.getItem('openSearch')) || false;
		const testerData = localStorage.getItem('testerActivities') || [];
		const testerActivities = !isEmpty(testerData)
			? testerData.split(',')
			: [];

		// HACK: read step from outside components
		const step = !isEmpty(location.state)
			? parseInt(location.state.step)
			: 1; // Default step is 1

		// HACK: wipe step from location
		if (location.state) {
			history.replace({ state: {} });
		}

		let datesArray = [];
		if (!isEmpty(persistedDates)) {
			datesArray = persistedDates.split(',');
		}

		let dates = this.hasOldDate(datesArray) || openSearch ? [] : datesArray;

		this.state = {
			step,
			city,
			country,
			option: option,
			adults,
			kids,
			numTravelers,
			dates,
			openSearch,
			error: false,
			errorMsg: 'Please fill in the fields to continue',
			requestLoading: false,
			isStepValid: false,
			interests,
			testerActivities,
			testerInterests: [],
		};
	}

	componentDidMount() {
		this.forceUpdate();
		const { setTesterActivities } = this.props;
		const {
			adults,
			city,
			country,
			kids,
			acts,
			cats,
			dates,
		} = getUrlParameters();

		const numAdults = parseInt(adults) || 1;
		const numKids = parseInt(kids) || 0;
		const numTravelers = numAdults + numKids;
		const step = adults || kids ? 5 : 3;
		const testerActivities = acts ? split(acts, ',') : [];
		const testerInterests = cats ? split(cats, ',') : [];
		const bookingBarDates = dates ? split(dates, ',') : [];

		if (country && city) {
			localStorage.setItem('city', city);
			localStorage.setItem('country', country);
			localStorage.setItem('kids', numKids);
			localStorage.setItem('adults', numAdults);
			localStorage.setItem('numTravelers', numTravelers);
			localStorage.setItem('option', getGroupType(numTravelers));

			if (!isEmpty(dates)) {
				localStorage.setItem('dates', dates);
				localStorage.setItem('openSearch', false);
			} else {
				localStorage.setItem('openSearch', true);
				localStorage.removeItem('dates');
			}
			this.setState({
				country,
				city,
				adults: numAdults,
				kids: numKids,
				numTravelers,
				testerActivities,
				testerInterests,
				step,
				dates: bookingBarDates,
			});
		}

		if (!isEmpty(acts)) {
			setTesterActivities(testerActivities);
			localStorage.setItem('testerActivities', acts);
		}
	}

	handleChange = (event) => {
		const currentStep = this.state.step;
		const { name, value } = event;
		// HACK: go next automatically on step 1 and 2
		const step =
			name === 'step'
				? value
				: currentStep === 1
				? 2
				: currentStep === 2
				? 3
				: currentStep;

		// this.dismissAlert();
		this.setState({
			[name]: value,
			step,
		});

		localStorage.setItem(name, value);
	};

	hasOldDate = (dates) => {
		const today = new Date().setHours(0, 0, 0, 0);
		let dateObj;
		let dateTime;
		let slashDate;
		let isOldDate = false;

		dates.forEach((date) => {
			slashDate = date.split('-').join('/');
			dateObj = new Date(slashDate);
			dateTime = dateObj.getTime();
			if (dateTime < today) {
				// wipe all dates from storage when old dates present
				localStorage.setItem('dates', []);
				isOldDate = true;
			}
		});

		return isOldDate;
	};

	nextStep = () => {
		const validation = this.validateStep();
		const { isStepValid } = validation;
		if (isStepValid) {
			let { step } = this.state;
			step = step >= 4 ? 5 : step + 1;
			this.handleChange({ name: 'step', value: step });
		} else {
			this.setState({
				error: true,
			});
		}
	};

	prevStep = () => {
		let { step } = this.state;
		step = step <= 1 ? 1 : step - 1;
		this.handleChange({ name: 'step', value: step });
	};

	validateStep = () => {
		const { step, adults, dates, openSearch } = this.state;

		let isStepValid = false;
		let errorMsg = 'Please select an option';
		switch (step) {
			case 3:
				isStepValid = parseInt(adults) > 0;
				break;
			case 4:
				isStepValid = !isEmpty(dates) || openSearch;
				break;
			default:
				break;
		}

		return { isStepValid, errorMsg };
	};

	dismissAlert = () => {
		this.setState({ error: false });
	};

	runMatch = async () => {
		this.setState({ requestLoading: true });
		const allInterests = this.props.interests;
		// HACK: remove old interests
		const filteredInterests = filterInterests(allInterests);

		const {
			city,
			dates,
			adults,
			kids,
			interests,
			testerInterests,
			testerActivities,
		} = this.state;
		const { client, history } = this.props;

		// HACK: convert interest ids to interests obj
		// interests that come from tester are an array of id's
		// but algorithm expects an object with a 'selected: true' prop
		const interestsObj = filteredInterests;
		testerInterests.forEach((i) => {
			interestsObj[i] = Object.assign({}, filteredInterests[i]);
			interestsObj[i].selected = true;
		});

		const nKids = kids || 0;
		const params = {
			city,
			dates,
			adults,
			kids: nKids,
			interests: interests || interestsObj,
			activities: testerActivities,
		};
		const result = await AMBASSADOR_SEARCH(params, client);

		history.push({
			pathname: '/new/results',
			state: {
				result,
				dates,
				// interests from wizard || interests from tester || all interests
				interests: interests || interestsObj || filteredInterests,
				adults,
				kids: nKids,
				city,
			},
			prevpath: history.location.pathname,
		});
	};

	get previousButton() {
		const { step } = this.state;

		if (step !== 1) {
			return (
				<Button className="button-primary gray" onClick={this.prevStep}>
					Previous
				</Button>
			);
		}

		return null;
	}

	get nextButton() {
		const { testerInterests } = this.state;
		const validation = this.validateStep();
		const { isStepValid } = validation;
		const { step } = this.state;
		const disabled = !isStepValid;

		if (
			(2 < step && step < 4) || // no categories in url
			(step === 4 && isEmpty(testerInterests)) // categories in url
		) {
			return (
				<Button
					className="button-primary"
					onClick={this.nextStep}
					disabled={disabled}
				>
					Next
				</Button>
			);
		}

		return null;
	}

	get doneButton() {
		const { testerInterests } = this.state;
		const { interests } = this.props;
		const { step, requestLoading } = this.state;

		// TODO: use isloaded
		if (
			(step >= 5 && !isEmpty(interests)) || // no categories in url
			(step >= 4 && !isEmpty(testerInterests)) // categories in url
		) {
			return (
				<BtnLoader
					onClick={this.runMatch}
					loading={requestLoading}
					disabled={isEmpty(interests)}
					text={'Connect Me!'}
					loadingText={'Searching...'}
				/>
			);
		}

		return null;
	}

	render() {
		const {
			step,
			country,
			city,
			option,
			adults,
			kids,
			numTravelers,
			dates,
			openSearch,
			testerActivities,
			testerInterests,
			error,
			errorMsg,
		} = this.state;

		const progressNumber =
			step === 1 || step === 2 ? 1 : parseInt(step) - 1;

		return (
			<div id="new-trip-container">
				<div className="progress-bar-container">
					<Progress color="danger" value={step * 20}>
						<span className="progress-status">
							{`${progressNumber} / 4`}
						</span>
					</Progress>
				</div>
				<form>
					{step === 1 && (
						<Step1
							country={country}
							handleChange={this.handleChange}
						/>
					)}
					{step === 2 && (
						<Step2
							handleChange={this.handleChange}
							country={country}
							city={city}
						/>
					)}
					{step === 3 && (
						<Step3
							handleChange={this.handleChange}
							option={option}
							adults={adults}
							kids={kids}
							numTravelers={numTravelers}
						/>
					)}
					{step === 4 && (
						<Step4
							handleChange={this.handleChange}
							dates={dates}
							openSearch={openSearch}
						/>
					)}
					{step === 5 && (
						<Step5
							selectedInterests={testerInterests}
							handleChange={this.handleChange}
						/>
					)}
					<div className="step-actions-container">
						{this.previousButton}
						{this.nextButton}
						{this.doneButton}
						<AlertMsg
							content={errorMsg}
							dismiss={this.dismissAlert}
							isOpen={error}
						/>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { profile } = state.firebase;
	const { interests } = state.firestore.data;
	// TODO: consider caching interests
	// localStorage.setItem('interests', interests);
	return { interests, profile };
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTesterActivities: (data) => dispatch(setTesterActivities(data)),
	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect([{ collection: 'interests' }])
)(NewTrip);
