import React from 'react';
import RedknotVerified from '../../assets/user/Redknot-verified.png';
import './verifiedBadge.scss';

const VerifiedBadge = () => {
	return (
		<div id="redknot-verified-badge">
			<img src={RedknotVerified} alt="redknot-verified"></img>
			<span>Redknot Verified</span>
		</div>
	);
};

export default VerifiedBadge;
