import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import CustomBtn from './CustomBtn';
import AlertMsg from './Alert';
import { useSelector, useDispatch } from 'react-redux';
import Toast from '../utils/Toast';

import { sendItineraryPdf } from '../../store/actions/activitiesActions';

import { isEmpty } from 'lodash';
import './printItinerary.scss';

export default function PrintItinerary({ close }) {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [loading, setloading] = useState(false);
	const [errorData, setError] = useState('');
	const [isAlertOpen, setAlert] = useState(false);
	const { utilityActivities, sendItineraryPdf: pdfState } = useSelector(
		(state) => state.activities
	);
	const [isToastOpen, setToastOpen] = useState(pdfState);

	useEffect(() => {
		if (pdfState) {
			close();
		}
	}, pdfState);

	const handleEmail = (e) => {
		const { value } = e.target;
		setEmail(value);
	};
	const toggleAlert = () => {
		setAlert(!isAlertOpen);
	};
	const sendItinerary = () => {
		setloading(true);
		if (email.length < 4 && isEmpty(email)) {
			setAlert(true);
			setloading(false);
			setError('Please enter an email address.');
			return;
		} else {
			dispatch(sendItineraryPdf(email, utilityActivities));
		}
	};
	return (
		<div className="print-actions-container">
			<AlertMsg
				content={errorData}
				dismiss={toggleAlert}
				isOpen={isAlertOpen}
			/>
			<p className="print-title">Print your Itinerary!</p>
			<p className="send-itinrerary-text">
				We will send the Itinerary to your e-mail for you to download it
				and print it.
			</p>
			<div className="print-action-controls">
				<Input
					className="email-log-in"
					placeholder="E-mail"
					onChange={handleEmail}
				/>
				<CustomBtn
					className="button-primary outline-red"
					loading={loading}
					text="Send"
					onClick={sendItinerary}
					width={77}
				/>
				<Toast
					position="top-center"
					message="Email Sent"
					isOpen={isToastOpen}
					setIsOpen={setToastOpen}
					time={3000}
				/>
			</div>
		</div>
	);
}
