import React from 'react';
import UserPhoto from '../utils/UserPhoto';

import defaultChatPic from '../../assets/brand/chat-default.jpg';

const ChatMessage = ({ message, participant, isResponse }) => {
	const { text, timestamp } = message;
	const chatImg = participant ? participant.photo : defaultChatPic;

	return (
		<div
			className={isResponse ? 'message received' : 'message sent'}
			style={{
				display: 'flex',
				flexDirection: isResponse ? 'row-reverse' : 'row',
			}}
		>
			<div className="message-text">
				<p>{text}</p>
				<span
					style={{
						fontSize: '10px',
						float: 'right',
					}}
				>
					{timestamp}
				</span>
			</div>
			<UserPhoto size={65} image={chatImg}></UserPhoto>
		</div>
	);
};

export default ChatMessage;
