export const AVAILABILITY_DB_FORMAT = 'MM-DD-YYYY';
export const BOOKING_DB_FORMAT = 'YYYY-MM-DD';
export const ITINERARY_DB_FORMAT = 'MM-DD-YYYY';

export const DOB_DISPLAY_FORMAT = 'DD MMMM YYYY';
export const DOB_DISPLAY_EDIT_FORMAT = 'YYYY-MM-DD';
export const AVAILABILITY_EMAIL_FORMAT = 'DD MMMM YYYY';
export const DATEPICKER_FORMAT = 'DD MMMM YYYY';
export const BOOKING_DISPLAY_FORMAT = 'ddd, D MMM Y';
export const ITINERARY_DISPLAY_FORMAT = 'D. MMM YYYY';
export const ITINERARY_EMAIL_FORMAT = 'dddd Do MMMM';
export const CHAT_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm';
export const EXAMPLE_DAY_FORMAT = 'H:mm';
