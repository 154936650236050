import React, { useState, useEffect } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Search from 'react-leaflet-search';
import CalculateDriveTime from './CalculateDriveTime';
import {
	getTimePerActivities,
	getNewActivityTime,
} from '../../../../store/actions/activitiesActions';
import { useSelector, useDispatch } from 'react-redux';

import './map.scss';

export default function LeafletMap({
	getCoordinates,
	defaultLocation,
	marker,
}) {
	const { lat, lng } = defaultLocation;
	const { lat: latitude, lng: longitude } = marker;
	const rideCoordinates = useSelector(
		(state) => state.activities.activitiesCoordinates
	);
	const dispatch = useDispatch();

	const [coordinates, setCoordinates] = useState({
		lat: latitude || lat,
		lng: longitude || lng,
	});
	const [popUpMessage, setPopUpMessage] = useState('');

	const [isMapInit, setIsMapInit] = useState(false);
	const [currentMap, setCurrentMap] = useState(null);

	useEffect(() => {
		isMapInit ? setIsMapInit(false) : setIsMapInit(true);
	}, [coordinates]);

	const saveMap = (map) => {
		setCurrentMap(map);
		setIsMapInit(true);
	};

	const handleClick = (e) => {
		setCoordinates(e.latlng);
		getCoordinates(e.latlng);
	};

	const saveTime = (time) => {
		dispatch(getTimePerActivities(time));
	};

	const saveNewActivityTime = (time) => {
		dispatch(getNewActivityTime(time));
	};

	return (
		<Map
			center={[coordinates.lat, coordinates.lng]}
			zoom={20}
			onClick={handleClick}
			ref={saveMap}
			id="leaflet-map"
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>
			<Marker position={[coordinates.lat, coordinates.lng]}>
				<Popup>{popUpMessage}</Popup>
			</Marker>
			<Search
				zoom={20}
				position="topleft"
				inputPlaceholder="Search for a place"
				onChange={(info) => {
					setCoordinates(info.latLng);
					getCoordinates(info.latLng);
					setPopUpMessage(info.info);
				}}
				showMarker={false}
				closeResultsOnClick={true}
				openSearchOnLoad={false}
			/>
			{isMapInit && (
				<CalculateDriveTime
					map={currentMap}
					coordinates={rideCoordinates}
					getTimePerActivities={saveTime}
					saveNewActivityTime={saveNewActivityTime}
					compareCoordinates={coordinates}
				/>
			)}
		</Map>
	);
}
