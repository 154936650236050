// TODO: Add all
// General APP messages
export const APP_ERROR =
	'Whoops! Something went wrong at our end, please try again';
export const COVID_SUSPENSION =
	'To keep our community safe we have decided to suspend all RedKnot bookings until August.';
export const MAIL_FOR_PROD_ONLY =
	'Mailing service enabled only for production environment';

// Itinerary Builder
export const SUGGESTED_ACTIVITIES = 'Suggested Activities';
export const CREATE_ACTIVITY = 'Create Activity';
export const DOWNLOAD_ACTIVITY = 'Download Itinerary';
export const MY_ITINERARY = 'My Itinerary';
export const NO_SUGGESTED_ACTIVITIES_FOUND =
	'Sorry, we have not found activities according to your preferences. You can chat with your ambassador to build your personalized experience!';
export const EXCEEDED_TIME =
	'Adding this activity exceeds the 8 hours limit per day! Consider an activity that takes less time.';
export const ITINERARY_BUILDER_CTA =
	'You can reorder your activities by drag and dropping them! No idea what to do? Check our suggestions!';

// Itinerary Utility
export const ITINERARY_UTILITY_WELCOME =
	'Welcome to the RedKnot Itinerary Builder!';
export const ITINERARY_UTILITY_CTA =
	'You can browse activities curated by our HQ Team and add them to your day plans!';
export const ITINERARY_WITH_AMBASSADOR = 'My Itinerary with:';
export const ITINERARY_BUILDER = 'Itinerary Builder';
export const SAVE_ITINERARY = 'Save Itinerary';
export const SHARE_ITINERARY = 'Share Itinerary';
export const HOURS_REMAINING = 'of 8 hours remaining';
export const TIME_FILL_INDICATION =
	"You don't need to fill the whole time - just pick the activities you really want to do and your Ambassador will plan a perfect day for you";
export const NO_ACTIVITIES_ADDED_1 =
	"You haven't selected any activities. You can add them by clicking on the";
export const NO_ACTIVITIES_ADDED_2 =
	'button or check out our RedKnot Ambassadors below and they will plan a day just for you.';
export const INTERESTS = 'Interests';
export const INTERESTS_POPUP_TITLE = 'Choose your interests!';
export const INTERESTS_POPUP_INTRO =
	'We will curate our activities suggestions based on your interests! You can choose multiple in each category!';
export const NO_MATCHING_ACTIVITIES =
	'There are no activities in matching such preferences yet!';

// Group size
export const EXCEEDED_TRAVELERS =
	'We are sorry but the number of travelers has exceeded the ones available for this ambassadors car. We encourage you to try a lower number or start a chat with us for a customized trip';
export const EXCEEDED_TRAVELERS_2 =
	"We are sorry but this ambassador's car cannot fit that many travelers. Please chat with us and we can make alternative arrangements.";

// Bookings
export const BOOKING_INCLUDES =
	'Includes: 6-8 hours of guided travel, a flexible and personalized itinerary, invaluable local knowledge and all transfers';
export const INVALID_PROMO_CODE = 'Not a valid promo code.';
export const SINGLE_PROMO_CODE =
	'Only one promo code can be used on this booking.';

export const EMAIL_INPUT_REQUIRED = 'Please input the traveler email.';
