import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';

import GoogleTranslate from './components/utils/GoogleTranslate';
import Navigation from './components/layout/Navigation';
import BrandHeader from './components/utils/BrandHeader';

import WizardBackground from './assets/WizardForm/wizard-background.jpg';

const PublicRoute = ({ component: Component, title, path }) => {
	const { auth } = useSelector((state) => state.firebase);
	const { isLoaded, isEmpty } = auth;
	const isAuthed = isLoaded && !isEmpty;

	const client = useApolloClient();

	useEffect(() => {
		window.scrollTo(0, 0);
		// window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [path]);

	return (
		<Route
			// key={path}
			path={path}
			render={(props) => {
				if (isAuthed && (path === '/login' || path === '/signup')) {
					// HACK: auth -> booking redirect
					// when router comes with bookingId set, redirect the user to the booking
					// TODO: replicate not only for booking
					if (props.history.location.state) {
						const bookingId =
							props.history.location.state.bookingId;
						if (bookingId) {
							const url = `/booking/${bookingId}`;
							return <Redirect to={url} />;
						}
					}
					return <Redirect to="/profile" />;
				}

				if (path === '/login' || path === '/signup') {
					return (
						<>
							<BrandHeader></BrandHeader>
							<div className="page-title full-width">{title}</div>
							<Component {...props} />
						</>
					);
				}
				return (
					<div>
						<Navigation {...props}></Navigation>
						{/* HACK: new trip specs: background image, older apollo */}
						{path === '/new' ? (
							<Container
								fluid={true}
								style={{
									backgroundImage: `url('${WizardBackground}')`,
								}}
							>
								{title && (
									<div className="page-title">{title}</div>
								)}
								<GoogleTranslate />
								{/* HACK: pass apollo client to non-hooks react component. Later to remove when NewTrip is a functional component */}
								<Component {...props} client={client} />
							</Container>
						) : (
							<Container>
								{title && (
									<div className="page-title">{title}</div>
								)}
								<GoogleTranslate />
								<Component {...props} />
							</Container>
						)}
					</div>
				);
			}}
		/>
	);
};

export default PublicRoute;
