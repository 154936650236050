import React, { useState } from 'react';
import { Progress, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { INTERESTS } from '../../consts/Interests';
import './interestBar.scss';

const InterestBar = ({ interestId, value, size = 'lg' }) => {
	const interest = INTERESTS[interestId];
	const { name, icon } = interest;

	const [tooltipOpen, setTooltip] = useState(false);

	const toggleTooltip = () => {
		setTooltip(!tooltipOpen);
	};

	return (
		<div className="interest-bar" id={interestId}>
			<FontAwesomeIcon icon={icon} size={size} />
			<Progress color="danger" value={value} max="100" />

			<Tooltip
				placement="top"
				isOpen={tooltipOpen}
				target={interestId}
				toggle={toggleTooltip}
			>
				{name}
			</Tooltip>
		</div>
	);
};

export default InterestBar;
