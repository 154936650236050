import React from 'react';
import { useSelector } from 'react-redux';
import TripItem from './TripItem';

const TripsList = ({ trips, title, history }) => {
	const { profile } = useSelector((state) => state.firebase);
	const { isAmbassador, isAgent } = profile;

	return (
		<div className="trip-list">
			<div className="trip-list-title">{title}</div>
			{trips.map(({ id, dates, ambassador, traveler, city }) => {
				if (
					(isAmbassador && !traveler) ||
					(!isAmbassador && !ambassador)
				) {
					return null;
				}

				const photo =
					isAmbassador || isAgent ? traveler.photo : ambassador.photo;

				return (
					<TripItem
						key={id}
						id={id}
						dates={dates}
						photo={photo}
						city={city}
						history={history}
					></TripItem>
				);
			})}
		</div>
	);
};

export default TripsList;
