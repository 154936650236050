import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { isEmpty, includes } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../utils/Loader';
import { INTERESTS } from '../../consts/Interests';
import { filterInterests } from '../../utils/Interests';

import './interests.scss';

const InterestsGrid = ({ handleChange, interests, selectedInterests }) => {
	const [interestsWithStatus, setInterests] = useState({});
	const [ordered, setOrdered] = useState([]);

	useEffect(() => {
		if (isEmpty(interests)) {
			return;
		}

		// HACK: remove old interests
		let filteredInterests = filterInterests(interests);
		const interestKeys = Object.keys(filteredInterests);
		interestKeys.forEach((key) => {
			// make a deep copy since interests object from firestore is not extensible
			filteredInterests[key] = Object.assign({}, interests[key], {
				selected: includes(selectedInterests, key),
			});
		});

		const sortedByOrder = interestKeys.sort((a, b) => {
			return filteredInterests[a].order - filteredInterests[b].order;
		});

		setInterests(filteredInterests);
		setOrdered(sortedByOrder);
	}, [interests]);

	if (isEmpty(interestsWithStatus)) {
		return <Loader />;
	}

	const onInterestClick = (e) => {
		const id = e.currentTarget.value;
		let interestsCopy = { ...interestsWithStatus };

		interestsCopy[id].selected = !interestsCopy[id].selected;
		setInterests(interestsCopy);

		handleChange({
			name: 'interests',
			value: interestsCopy,
		});
	};

	return (
		<div id="interests-grid">
			{ordered.map((key) => {
				const interest = interestsWithStatus[key];
				const { name, selected } = interest;
				const icon = INTERESTS[key].icon;
				return (
					<Button
						key={key}
						className="interest-button"
						onClick={onInterestClick}
						value={key}
						active={selected}
					>
						<div value={key}>
							<FontAwesomeIcon
								value={key}
								icon={icon}
								size="3x"
							/>
						</div>
						{name}
					</Button>
				);
			})}
		</div>
	);
};

export default InterestsGrid;
